import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { Typography, Grid, Box, Table } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import WidthContainer from '../common/WidthContainer'
import { themeStylesTip, tipCoreTheme } from '../theme'
import { usePageContent, getSection, renderMarkdown } from '../utils/pageContentHelper'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center'
  },
  rootPrevious: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
    paddingTop: 84,
    flexGrow: 1,
    background: '#F6F6F6'
  },
  pageTitle: {
    marginBottom: 26,
    fontSize: 58,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  hero: {
    marginBottom: 20
  },
  margBot: {
    marginBottom: 24
  },
  participate: {
    marginBottom: 16,
    fontWeight: 600,
    textAlign: 'center'
  },
  image: {
    height: 'auto',
    maxHeight: 150,
    display: 'inline-block'
  },
  imageFull: {
    maxWidth: '100%'
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  greentext: {
    color: '#29818C'
  },
  tcell: {
    paddingBottom: 0,
    '& p a': {
      borderBottom: 0,
      textDecoration: 'underline'
    }
  }
}))

const BlueprintsPage = () => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()

  const { data: pageContent = [], error: pageContentError } = usePageContent('Blueprint Page')
  const sections = {
    pageTitle: getSection(
      pageContent,
      'Page Title',
      <Typography variant="h1" className={classes.pageTitle} />
    ),
    pageDescription: getSection(pageContent, 'Page Description'),
    mainImageUrl: getSection(pageContent, 'Main Image URL'),
    mainText: getSection(pageContent, 'Main Text'),
    col1Title: getSection(pageContent, 'Col 1 Title', <Typography variant="h3" />),
    col1ImageUrl: getSection(pageContent, 'Col 1 Image URL'),
    col1List: getSection(pageContent, 'Col 1 List'),
    col2Title: getSection(pageContent, 'Col 2 Title', <Typography variant="h3" />),
    col2ImageUrl: getSection(pageContent, 'Col 2 Image URL'),
    col2List: getSection(pageContent, 'Col 2 List'),
    tableHeading: getSection(pageContent, 'Table Heading', <Typography variant="h2" />),
    tableHead: getSection(pageContent, 'Table Head'),
    tableContent: getSection(pageContent, 'Table Content'),
    lowerListTitle: getSection(pageContent, 'Lower List Title', <Typography variant="h2" />),
    lowerListDescription: getSection(pageContent, 'Lower List Description'),
    lowerList: getSection(pageContent, 'Lower List')
  }

  if (pageContentError) {
    console.error('Error loading page content Org Page:', pageContentError)
  }

  const tableCols = sections.tableHead && sections.tableHead.cols ? sections.tableHead.cols : []

  const tableRows =
    sections.tableContent && sections.tableContent.rows ? sections.tableContent.rows : []

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <div className={clsx(classes.root, themeClasses.pageContentMargin)}>
        <WidthContainer className={classes.columnWrapper}>
          <Grid container spacing={1} className={classes.hero}>
            <Grid item xs={12}>
              {sections.pageTitle}
              {sections.pageDescription}
              <Box mt={5} mb={4}>
                <img
                  src={sections.mainImageUrl}
                  alt="tip blueprint diagram"
                  className={classes.imageFull}
                />
              </Box>
              <Box mb={5}>{sections.mainText}</Box>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  {sections.col1Title}
                  <Box mt={1} mb={1}>
                    <img src={sections.col1ImageUrl} alt="tip Product Blueprint" />
                  </Box>
                  {sections.col1List}
                </Grid>
                <Grid item md={6} xs={12}>
                  {sections.col2Title}
                  <Box mt={1} mb={1}>
                    <img src={sections.col2ImageUrl} alt="tip Solutions Blueprint" />
                  </Box>
                  {sections.col2List}
                </Grid>
              </Grid>
              <Box mt={3} mb={2}>
                {sections.tableHeading}
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCols.map((col, cdx) => (
                        <TableCell key={`tcc-${cdx}`} style={{ width: col.width }}>
                          {col.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((row, rdx) => (
                      <TableRow key={`trri-${rdx}`}>
                        {row.columns
                          ? row.columns.map((rcol, rcdx) => (
                              <TableCell key={`trcir-${rdx}-${rcdx}`} className={classes.tcell}>
                                {renderMarkdown(rcol)}
                              </TableCell>
                            ))
                          : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Box mt={4} mb={2}>
                {sections.lowerListTitle}
              </Box>
              <Box mb={3}>{sections.lowerListDescription}</Box>
              <Box mb={3}>{sections.lowerList}</Box>
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
    </MuiThemeProvider>
  )
}

export default BlueprintsPage
