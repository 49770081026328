import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const getUrlFromLocation = location => {
  return `${location.pathname}${location.search}${location.hash}`
}

export const pushVirtualPageView = (location, currentUser) => {
  if (window.dataLayer && window.dataLayer.push) {
    let obj = {
      event: 'virtualPageview',
      virtualPageURL: location.pathname ? '/vpv' + getUrlFromLocation(location) : location
    }

    if (currentUser && currentUser.email && currentUser.id) {
      obj.userEmail = currentUser.email
      obj.userId = currentUser.id
    }

    window.dataLayer.push(obj)
  }
}

export const useGoogleAnalytics = currentUser => {
  const location = useSelector(state => state.router.location)

  useEffect(() => {
    pushVirtualPageView(location, currentUser)
  }, [location, currentUser])
}

export const logRequestInfo = (name, currentUser) => {
  if (window.dataLayer && window.dataLayer.push) {
    let obj = {
      event: 'sendEvent',
      event_category: 'marketplace',
      event_action: 'request-info-click',
      event_label: name
    }
    if (currentUser && currentUser.email && currentUser.id) {
      obj.userEmail = currentUser.email
      obj.userId = currentUser.id
    }

    window.dataLayer.push(obj)
  }
}

export const logSendRequest = (name, currentUser) => {
  if (window.dataLayer && window.dataLayer.push) {
    let obj = {
      event: 'sendEvent',
      event_category: 'marketplace',
      event_action: 'send-request',
      event_label: name
    }
    if (currentUser && currentUser.email && currentUser.id) {
      obj.userEmail = currentUser.email
      obj.userId = currentUser.id
    }

    window.dataLayer.push(obj)
  }
}

export const logAction = (action, category, label, currentUser = {}) => {
  if (window.dataLayer && window.dataLayer.push) {
    let obj = {
      event: 'sendEvent',
      event_category: category,
      event_action: action,
      event_label: label
    }
    if (currentUser && currentUser.email && currentUser.id) {
      obj.userEmail = currentUser.email
      obj.userId = currentUser.id
    }

    window.dataLayer.push(obj)
  }
}

export const logEmailAssetAction = ({
  actionSuccess,
  actionFail,
  category = 'Get Asset',
  label,
  currentUser = {}
}) => {
  let action = ''
  if (actionSuccess) {
    action = 'Confirm Send Asset Email'
  } else if (actionFail) {
    action = 'Fail Send Asset Email'
  }
  logAction(action, category, label, currentUser)
}
