import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
//import Box from '@material-ui/core/Box'

import WidthContainer from '../common/WidthContainer'
import Hero from './Hero'
import QuestionsAnswers from './QuestionsAnswers'
// import CtaCard from './CtaCard'
import ContactUs from './ContactUs'
import Badges from './Badges'
import { usePageContent, getSection } from '../utils/pageContentHelper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  questions: {
    marginTop: 50
  },
  containerHeading: {
    marginBottom: 25
  },
  heroTitle: {
    color: '#88bec2',
    '&:first-line': {
      color: '#414141'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      maxWidth: '50%',
      margin: '0 auto',
      marginRight: 0
    }
  },
  subTitle: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      marginTop: 10
    }
  },
  rootP: {
    fontSize: 18,
    fontWeight: 400,
    margin: '12px 0',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '85%'
    }
  },
  rfiStrong: {
    fontSize: 18,
    fontWeight: 500
  },
  contactTitle: {
    fontWeight: 600,
    marginBottom: 12
  },
  heroSubTitle: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'bold'
    }
  },
  heroBody: {
    marginTop: 10,
    marginBottom: 'auto'
  }
}))

const Home = () => {
  const classes = useStyles()
  const { data: pageContent = [], error: pageContentError } = usePageContent('Exchange Home')
  const sections = {
    heroTitle: getSection(
      pageContent,
      'Hero Title',
      <Typography variant="h2" paragraph className={classes.heroTitle} />
    ),
    heroTopText: getSection(
      pageContent,
      'Hero Top Text',
      <Typography variant="h6" className={classes.subTitle} />
    ),
    heroCard1Title: getSection(
      pageContent,
      'Hero Card 1 Title',
      <Typography variant="h6" className={classes.heroSubTitle} />
    ),
    heroCard1Text: getSection(
      pageContent,
      'Hero Card 1 Text',
      <Typography variant="body1" className={classes.heroBody} />
    ),
    heroCard1ButtonLabel: getSection(pageContent, 'Hero Card 1 Button Label'),
    heroCard1ButtonLink: getSection(pageContent, 'Hero Card 1 Button Link'),
    heroCard2Title: getSection(
      pageContent,
      'Hero Card 2 Title',
      <Typography variant="h6" className={classes.heroSubTitle} />
    ),
    heroCard2Text: getSection(
      pageContent,
      'Hero Card 2 Text',
      <Typography variant="body1" className={classes.heroBody} />
    ),
    heroCard2ButtonLabel: getSection(pageContent, 'Hero Card 2 Button Label'),
    heroCard2ButtonLink: getSection(pageContent, 'Hero Card 2 Button Link'),
    bodyTitle: getSection(
      pageContent,
      'Body Title',
      <Typography variant="h5" className={classes.containerHeading} />
    ),
    bodyText: getSection(pageContent, 'Body Text'),
    rightBoxTitle: getSection(
      pageContent,
      'Right Box Title',
      <Typography variant="h6" className={classes.contactTitle} />
    ),
    rightBoxText: getSection(pageContent, 'Right Box Text'),
    rightBoxButton: getSection(pageContent, 'Right Box Button'),
    //ctaChip: getSection(pageContent, 'CTA Chip'),
    //ctaTitle: getSection(pageContent, 'CTA Title', <strong className={classes.rfiStrong} />),
    //ctaText: getSection(pageContent, 'CTA Text', <Typography className={classes.rootP} />),
    //ctaButtonLabel: getSection(pageContent, 'CTA Button Label'),
    //ctaButtonLink: getSection(pageContent, 'CTA Button Link')
  }

  if (pageContentError) {
    console.error('Error loading page content:', pageContentError)
  }

  // Where's my text/content?!?!? Most all of it is now DB driven and is stored in the MongoDB, accessed via GraphQL
  // In the DB Collection: pagecontents -> the document with name: 'Exchange Home'
  //its not though because you are collating text with template so i cant just remove content from pageContents and have it removed on page. 
  // 

  return (
    <div className={classes.root}>
      <Hero sections={sections} />
      <WidthContainer className={classes.columnWrapper}>
        <Grid container spacing={0} justify="space-between">
          <Grid item md={8} xs={12} className={classes.questions}>
            <QuestionsAnswers sections={sections} />
          </Grid>
          <Grid item md={4} xs={12} className={classes.questions}>
            <ContactUs
              title={sections.rightBoxTitle}
              body={sections.rightBoxText}
              buttonText={sections.rightBoxButton}
            />
          </Grid>

          
        </Grid>
      </WidthContainer>
      <Badges />
    </div>
  )
}

export default Home
