import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Search from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    cursor: 'pointer'
  }
}))

const MarketplaceSearch = ({ value, onChange, onClickClear }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TextField
        label="Search"
        variant="outlined"
        value={value}
        onChange={e => onChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment position="end">
              <CloseIcon className={classes.icon} onClick={onClickClear} />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

export default MarketplaceSearch
