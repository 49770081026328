import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, InputAdornment, IconButton } from '@material-ui/core'
import { useFormState } from 'react-use-form-state'
import { makeStyles } from '@material-ui/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import InputTextField from '../common/InputTextField'
import Progress from '../common/Progress'
import WidthContainer from '../common/WidthContainer'
import LinkField from '../common/LinkField'
import ErrorSection from '../common/ErrorSection'


import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes, errorMessages } from '../utils'
import { GET_SESSION } from './queries'
import { LOGIN_USER, EXCHANGE_FAKE_STAY_LOGGED_IN } from './mutations'

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 36,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  formContainerCompact: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    width: '100%',
  },
  signInButton: {
    width: '100%',
    marginBottom: 24,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      textDecoration: 'none',
      fontWeight: 500,
    },
  },
  signUpLink: {
    fontWeight: 500,
    textDecoration: 'none',
    cursor: 'pointer',
    textAlign: 'right',
    fontSize: 14,
  },
}))

function LoginForm({
  onLoginSuccess = () => {},
  onLoginFail = () => {},
  token = '',
  autoFocus = true,
  onClickSignUp = () => {},
  compact = false,
  hideSignUpLink = false,
}) {
  const classes = useStyles()
  const [formState, { email, text, password }] = useFormState()
  const { loading: stayLoggedInLoading, execute: stayLoggedInExecute } = useMutation(
    EXCHANGE_FAKE_STAY_LOGGED_IN,
    {
      refetchQueries: GET_SESSION,
    }
  )
  const { loading, execute: loginUser, error } = useMutation(LOGIN_USER, {
    onCompleted: ({ login: { result = false } = {} }) => {
      if (result) {
        stayLoggedInExecute()
      }
    },
  })
  const formClasses = useFormStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState()

  function handleSubmit(e) {
    e.preventDefault()
    loginUser({
      values: {
        ...formState.values,
        token,
      },
    })
      .then((response) => {
        onLoginSuccess(response)
      })
      .catch((e) => {
        onLoginFail(e)
        console.error('Sign in errro:', e)
      })
  }

  const acceptingInvite = !!token

  const inputs = {
    email: {
      gridWidth: { xs: 12 },
      name: 'email',
      label: 'Email',
      inputType: email,
      autoFocus: autoFocus,
      validator: {
        required: !acceptingInvite,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email',
      },
      variant: 'outlined',
      disabled: acceptingInvite,
    },
    password: {
      gridWidth: { xs: 12 },
      name: 'password',
      label: 'Password',
      inputType: showPassword ? text : password,
      validator: {
        required: true,
      },
      touchOnChange: true,
      variant: 'outlined',
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
  }

  useEffect(() => {
    // if error during log in, show error
    if (error) {
      if (error.graphQLErrors[0].message === 'Error: Wrong email or password') {
        // Incorrect email/password
        setErrorMessage('Wrong email or password')
      } else {
        // Other errors
        console.error(error.graphQLErrors[0].message)
        setErrorMessage(errorMessages.TIPSupport)
      }
    }
  }, [error])

  const disableSubmit = isFormSubmitDisabled(inputs, formState)
  const isSessionExpiredState = window.location.search.includes('session_expired')

  return (
    <form onSubmit={handleSubmit}>
      <WidthContainer className={compact ? classes.formContainerCompact : classes.formContainer}>
        <div>
          {errorMessage && <ErrorSection includeSupport>{errorMessage}</ErrorSection>}
          {isSessionExpiredState && (
            <ErrorSection>
              You have been logged out due to inactivity, please login again.
            </ErrorSection>
          )}
        </div>
        <Grid className={formClasses.spacingBottom} container spacing={2}>
          {Object.entries(inputs).map(([name, args]) => (
            <Grid key={name} item {...args.gridWidth}>
              <InputTextField
                key={name}
                {...args}
                error={formState.errors[name] ? true : false}
                helperText={formState.errors[name]}
              />
            </Grid>
          ))}
        </Grid>
        <Button
          className={classes.signInButton}
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={loading || stayLoggedInLoading || disableSubmit}
          data-testid="LoginBtn" // for tests only
          type="submit"
        >
          {loading || stayLoggedInLoading ? <Progress size={30} delay={0} /> : 'Sign In'}
        </Button>
        <div className={classes.buttonContainer}>
          <LinkField
            to={'http://member.telecominfraproject.com/forgot-password'}
            label="Forgot password?"
            hideBorder={true}
            hideIcon={true}
          />
        </div>
      </WidthContainer>
    </form>
  )
}

export default LoginForm
