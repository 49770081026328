import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import LinkField from '../common/LinkField'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  filterListTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24
  },
  filterLabel: {
    fontSize: 13,
    fontWeight: 600
  },
  list: {
    paddingLeft: 18,
    marginTop: 6
  },
  divider: {
    marginTop: 16,
    marginBottom: 32
  },
  searchTipsTitle: {
    fontSize: 14,
    fontWeight: 600
  }
}))

const MarketplaceEmptyListState = ({ filters = {} }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h5" className={classes.filterListTitle}>
        Display Marketplace offerings by the following categories:
      </Typography>
      <Grid container spacing={2} justify="space-between" className={classes.root}>
        {Object.keys(filters).map((filterKey, index) => {
          const filter = filters[filterKey]
          return (
            <Grid item key={`${filterKey}_${index}`}>
              <Typography variant="h6" className={classes.filterLabel}>
                {filter.label}
              </Typography>
              <ul className={classes.list}>
                {filter.options.slice(0, 4).map((option, index) => (
                  <li key={index}>
                    <LinkField
                      label={option.label}
                      to={`/marketplace?${filterKey}=${option.label}`}
                    />
                  </li>
                ))}
              </ul>
            </Grid>
          )
        })}
      </Grid>
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.searchTips}>
        <Typography variant="h5" className={classes.searchTipsTitle}>
          Search Tips:
        </Typography>
        <ul className={classes.list}>
          <li>Try searching by technology, solution provider or resource name</li>
          <li>Check your spelling</li>
          <li>Broaden your search by using fewer or more general words</li>
        </ul>
      </div>
    </div>
  )
}

export default MarketplaceEmptyListState
