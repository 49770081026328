import gql from 'graphql-tag'

export const GET_FOOTER_ITEMS = gql`
  query {
    footer {
      name
      label
      subItems {
        name
        label
        to
      }
    }
  }
`
