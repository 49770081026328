import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Search from '@material-ui/icons/Search'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'

import WidthContainer from '../common/WidthContainer'
import config from '../config'

export const toolbarHeight = 66

export const useStyles = makeStyles(theme => ({
  subHeader: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'fixed',
    top: 84,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
  subHeaderFill: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    top: 66,
    width: '100%',
    height: 100,
    backgroundColor: '#fff'
  },
  grow: {
    flexGrow: 1
  },
  desktop: {
    paddingLeft: 20,
    paddingRight: 20
  },
  ddownButton: {
    borderRadius: 0,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  dropNav: {
    position: 'absolute',
    top: 64,
    left: -130,
    backgroundColor: 'white',
    border: '1px solid #efefef',
    textTransform: 'none',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-start',
    color: theme.palette.darkGray.main
  },
  dropColumn1: {
    flexBasis: '42%',
    textAlign: 'left',
    padding: '4px 2%'
  },
  dropColumn2: {
    flexBasis: '58%',
    textAlign: 'left',
    padding: '4px 2%'
  },
  parentLink: {
    textDecoration: 'none',
    color: theme.palette.darkGray.main,
    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  },
  dropNavLink: {
    padding: '0px 0px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: theme.palette.darkGray.main,
    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  },
  dropNavCategory: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: 'uppercase'
  },
  logo: {
    height: 52,
    width: 'auto',
    marginTop: 8
  },
  logoLink: {}
}))

function ExchangeNav({ setIsShown, isShown, exchangeNavItems, siteName }) {
  const classes = useStyles()

  return (
    <Hidden className={classes.desktop} only={['xs', 'sm']}>
      <Box className={classes.subHeaderFill} />
      <WidthContainer container fullWidth={true}>
        <Box className={classes.subHeader}>
          <Toolbar classes={{ root: classes.subNav }} onMouseLeave={() => setIsShown(0)}>
            <WidthContainer container>
              <Link to="/" className={classes.logoLink}>
                <img
                  src={`${config.MEDIA_CDN_DOMAIN}/media/Exchange-Logo.svg`}
                  alt="TIP Exchange"
                  className={classes.logo}
                />
              </Link>
              <div className={classes.grow} />
              {exchangeNavItems.map((nav, ndx) => {
                if (nav.submenuColumns && nav.submenuColumns.length > 0) {
                  const colNum = nav.submenuColumns.length
                  return (
                    <Button
                      key={`ddown-${ndx}`}
                      onMouseEnter={() => setIsShown(ndx)}
                      onMouseLeave={() => setIsShown(0)}
                      className={classes.ddownButton}
                    >
                      <Link to={nav.link} className={classes.parentLink}>
                        {nav.title}
                        {nav.icon === 'Search' ? <Search /> : null}
                      </Link>{' '}
                      <Icon fontSize="small">keyboard_arrow_down</Icon>
                      {isShown === ndx && (
                        <div className={classes.dropNav} style={{ width: colNum * 230 }}>
                          {nav.submenuColumns.map((sub, sdx) => (
                            <div
                              style={{
                                flexBasis: `${96 / colNum}%`,
                                textAlign: 'left',
                                padding: '4px 2%'
                              }}
                            >
                              {sub.columnHeader ? (
                                <Link
                                  className={clsx(classes.dropNavLink, classes.dropNavCategory)}
                                  to={sub.columnHeaderLink}
                                >
                                  {sub.columnHeader}
                                </Link>
                              ) : null}
                              <Box mt={2}>
                                {sub.childLinks.map((child, cdx) => (
                                  <>
                                    <Link
                                      key={`childlink-${sdx}-${cdx}`}
                                      className={classes.dropNavLink}
                                      to={child.link}
                                    >
                                      {child.label}
                                    </Link>
                                    <br />
                                  </>
                                ))}
                              </Box>
                            </div>
                          ))}
                        </div>
                      )}
                    </Button>
                  )
                }
                return (
                  <Button key={`nitembtn-${ndx}`} component={Link} to={nav.link}>
                    {nav.title}
                    {nav.icon === 'Search' ? <Search /> : null}
                  </Button>
                )
              })}
            </WidthContainer>
          </Toolbar>
        </Box>
      </WidthContainer>
    </Hidden>
  )
}

export default ExchangeNav
