import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { themeStyles, tipCoreTheme } from '../theme'
import WidthContainer from '../common/WidthContainer'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
    flexGrow: 1
  }
}))

const StoryGeneral = ({ match }) => {
  const classes = useStyles()
  const themeClasses = themeStyles()

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <Grid container className={clsx(classes.root, themeClasses.pageContentMargin)}>
        <Typography variant="h1">Storybook Mock H1</Typography>
        <Typography variant="h2">Storybook Mock H2</Typography>
        <Typography variant="h3">Storybook Mock H3</Typography>
        <Typography variant="h4">Storybook Mock H4</Typography>
        <Typography variant="h5">Storybook Mock H5</Typography>
        <Typography variant="h6">Storybook Mock H6 outside WidthContainer...</Typography>

        <WidthContainer className={themeClasses.pageContentMargin}>
          <Typography variant="subtitle1">Subtitle1 Inside WidthContainer</Typography>
          <Typography variant="subtitle2">
            Subtitle2 - Only use this Storybook to show things in the style guide
          </Typography>
          <Typography variant="body1" paragraph>
            Test outside WidthGrid body1 with paragraph
          </Typography>
          <Typography variant="body2">
            body2 is used for "Intro body" from the Style Guide
          </Typography>
          <Typography variant="caption">caption</Typography>
          <Typography variant="button">button</Typography>
          <Typography variant="overline">overline</Typography>
          <Typography variant="srOnly">srOnly</Typography>
          <Typography variant="inherit">inherit</Typography>

          <Typography variant="h4">
            The MUI Grid system and TIP Style Guide are aligned with each other, with only a
            difference to the <strong>md</strong> breakpoint size
          </Typography>
        </WidthContainer>
      </Grid>
    </MuiThemeProvider>
  )
}

export default StoryGeneral
