import React, { useState } from 'react'
import { usePopper } from 'react-popper'

const Tooltip = ({ children }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const [isVisible, setVisibility] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  })

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}
      >
        Use Case
      </button>
      {isVisible && (
        <div
          className={`${isVisible ? 'showtooltip' : ''} tooltip`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
          <div className="arrow" ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  )
}

export default Tooltip

// <li>
//     <a
//       href="#"
//       className="_wirygwpr9gb_tooltip"
//       title="  Cell site gateway in a production network serving and aggregating 2G, 3G, 4G and 5G mobile, fixed access and enterprise traffic."
//     >
//       Use Case
//     </a>
//   </li>
