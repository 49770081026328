import React from 'react'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import InputIcon from '@material-ui/icons/Input'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { useStyles as useHeaderStyles } from './Header'
import logoImage from './tip-header-logo.svg'
import { getMyAccountLinks } from './'
import { useQuery } from '../hooks'
import { useUserOrganization } from '../auth/queries'
import { GET_ORGANIZATION_DATA } from './queries'
import LinkField from '../common/LinkField'
import SubMenu from './SubMenu'

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: 15
  },
  nestedListItemText: {
    fontSize: 15
  },
  doubleNested: {
    paddingLeft: 80
  },
  doubleNestedListItemText: {
    fontSize: 14.5
  },
  icon: {
    margin: 'auto',
    top: 6,
    marginRight: 10,
    position: 'relative'
  },
  logoutIcon: {
    marginRight: 5
  },
  menuIcon: {
    color: 'white',
    fontSize: '2.5rem !important'
  },
  menuIconOpen: {
    padding: 0
  },
  list: {
    width: '100%'
  },
  fullList: {
    width: 'auto'
  },
  navLogoLink: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  paper: {
    backgroundColor: theme.palette.darkGray.main,
    color: theme.palette.gray2.main
  },
  listItemContainer: {
    paddingLeft: 0
  },
  opened: {
    backgroundColor: `${theme.palette.navBorderGray.main} !important`
  },
  subMenuOpened: {
    backgroundColor: `${theme.palette.navHoverGray.main} !important`,
    boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.3)',
    '& li': {
      borderBottom: 'none !important'
    },
    '& div': {
      '& li': {
        paddingLeft: 30,
        borderBottom: 'none !important'
      },
      '& >a': {
        paddingLeft: 30,
        borderBottom: 'none !important'
      }
    }
  },
  mobileNavList: {
    '& li': {
      borderBottom: `solid 1px ${theme.palette.navHoverGray.main}`
    },
    '& >div': {
      borderBottom: `solid 1px ${theme.palette.navHoverGray.main}`
    },
    '& >a': {
      borderBottom: `solid 1px ${theme.palette.navHoverGray.main}`
    },
    '& a:last-child': {
      borderBottom: 'none !important'
    }
  },
  noBorder: {
    borderBottom: 'none !important'
  },
  openedContainer: {
    borderBottom: 'none !important'
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'none !important'
  },
  listHeaderLogo: {
    textAlign: 'center',
    flexGrow: 1
  },
  labelText: {
    '& span': {
      fontSize: '14px !important',
      color: theme.palette.gray2.main
    }
  }
  // navLabel: {
  //   color: theme.palette.gray2.main
  // }
}))

export default function MobileNav({ isOrgAdmin, session, headerNavItems }) {
  const classes = useStyles()
  const headerClasses = useHeaderStyles()
  const [drawerOpenState, setDrawerOpenState] = React.useState(false)
  const [open, setOpen] = React.useState(new Set())

  const userId = session ? session.user.id : null
  const userRoles = session ? session.user.roles : []
  const signUpStep = session ? session.user.signUpStep : null

  const { loading: userOrgLoading, organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { orgId, membershipStatus } = organizationUser

  const { data: { organization = {} } = {}, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_DATA,
    { variables: { id: orgId }, skip: !isOrgAdmin || !orgId }
  )

  function toggleOpen(name) {
    const isOpen = open.has(name)
    let newSet = open

    if (isOpen) {
      newSet.delete(name)
    } else {
      newSet.add(name)
    }

    setOpen(new Set(newSet))
  }

  if (userOrgLoading || organizationLoading) {
    return null
  }

  const myAccountItems = getMyAccountLinks(
    userId,
    orgId,
    userRoles,
    membershipStatus,
    organization,
    signUpStep
  )

  return (
    <>
      <Button
        aria-label="Navigation Menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={event => setDrawerOpenState(true)}
      >
        <MenuIcon className={classes.menuIcon} />
      </Button>
      <SwipeableDrawer
        data-testid="MobileNavDrawer"
        anchor="top"
        open={drawerOpenState}
        onClose={() => setDrawerOpenState(false)}
        onOpen={() => setDrawerOpenState(true)}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={() => setDrawerOpenState(false)}
          onKeyDown={() => setDrawerOpenState(true)}
        >
          <List id="MobileNavItems" className={classes.mobileNavList}>
            <ListItem className={classes.listHeader}>
              <Button
                aria-label="Navigation Menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={event => setDrawerOpenState(false)}
              >
                <MenuIcon className={classes.menuIcon} />
              </Button>
              <div className={classes.listHeaderLogo}>
                <Link to="/">
                  <img src={logoImage} alt="TIP logo" style={{ width: 220 }} />
                </Link>
              </div>
            </ListItem>
            {/* main nav items */}
            {headerNavItems.map(({ name, label, subItems, hidden, desktopOnly, to }, index) => {
              if (hidden || desktopOnly || name === 'home') {
                return false
              }

              const isOpen = open.has(name)

              return subItems ? (
                <div key={name} className={clsx({ [classes.openedContainer]: isOpen })}>
                  <ListItem
                    button
                    onClick={e => {
                      e.stopPropagation() // to prevent nav from closing
                      toggleOpen(name)
                    }}
                    className={clsx({ [classes.opened]: isOpen })}
                  >
                    <ListItemText
                      primary={label}
                      className={clsx(classes.labelText, { [classes.opened]: isOpen })}
                      primaryTypographyProps={{
                        classes: classes.labelText
                      }}
                    />
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={isOpen}
                    timeout="auto"
                    unmountOnExit
                    className={classes.subMenuOpened}
                  >
                    <SubMenu
                      type="mobile"
                      items={subItems}
                      nestedLevel={1}
                      onClose={() => {
                        toggleOpen(name)
                        setDrawerOpenState(false)
                      }}
                    />
                  </Collapse>
                </div>
              ) : (
                <ListItem
                  key={index}
                  button
                  component={LinkField}
                  hideIcon={true}
                  to={to}
                  label={<ListItemText className={classes.labelText} primary={label} />}
                  onClick={event => {
                    toggleOpen(name)
                  }}
                />
              )
            })}
            {/* Logged in my account dropdown */}
            {userId && (
              <>
                <ListItem
                  button
                  onClick={e => {
                    toggleOpen('myAccount')
                    e.stopPropagation() // to prevent nav from closing
                  }}
                  className={clsx(classes.noBorder, { [classes.opened]: open.has('myAccount') })}
                >
                  <ListItemText
                    data-testid="MyAccountNavItem"
                    className={classes.labelText}
                    primary={
                      <>
                        <AccountCircleIcon className={classes.icon} />
                        My Account
                      </>
                    }
                  />
                  {open.has('myAccount') ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open.has('myAccount')}
                  timeout="auto"
                  unmountOnExit
                  className={classes.subMenuOpened}
                >
                  <SubMenu
                    type="mobile"
                    items={myAccountItems}
                    nestedLevel={1}
                    onClose={() => {
                      toggleOpen('myAccount')
                      setDrawerOpenState(false)
                    }}
                  />
                  <MenuItem
                    component={Link}
                    to="/logout"
                    className={clsx(headerClasses.item, classes.labelText)}
                  >
                    <InputIcon className={classes.logoutIcon} />
                    Logout
                  </MenuItem>
                </Collapse>
              </>
            )}
          </List>
        </div>
      </SwipeableDrawer>
      <div className={headerClasses.grow} />
      <Link to="/" className={classes.navLogoLink}>
        <img src={logoImage} className={headerClasses.navLogoImage} alt="TIP logo" />
      </Link>
    </>
  )
}
