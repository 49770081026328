import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { Typography, Grid, Box } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import WidthContainer from '../common/WidthContainer'
import Variants from './VariantsStick'
import MockImage from './TSS-site-configuration.png'
import DiagBG from '../TSS/Solutions/images/ultraRural/TSS-Page-HeaderBG.png'
import { themeStylesTip, tipCoreTheme } from '../theme'
import { renderMarkdown } from '../utils/pageContentHelper'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center'
  },
  rootPrevious: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
    paddingTop: 84,
    flexGrow: 1,
    background: '#F6F6F6'
  },
  headerPart: {
    marginTop: -50,
    backgroundColor: '#DDEEf1',
    backgroundImage: `url(${DiagBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(50% + 790px) center',
    backgroundSize: '1310px 243px',
    padding: '42px 0 32px 0',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'calc(50% + 890px) center'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '1350px 263px',
      backgroundPosition: 'calc(50% + 800px) bottom'
    }
  },
  pageTitle: {
    marginBottom: 26,
    fontSize: 58,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  hero: {
    marginBottom: 20
  },
  margBot: {
    marginBottom: 24
  },
  participate: {
    marginBottom: 16,
    fontWeight: 600,
    textAlign: 'center'
  },
  image: {
    height: 'auto',
    maxHeight: 150,
    display: 'inline-block'
  },
  imageFull: {
    maxWidth: '100%'
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  greentext: {
    color: '#29818C'
  },
  tcell: {
    paddingBottom: 0,
    '& p a': {
      borderBottom: 0,
      textDecoration: 'underline'
    }
  }
}))

const BlueprintsChildMock2 = () => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <div className={clsx(classes.root, themeClasses.pageContentMargin)}>
        <Grid container className={classes.headerPart}>
          <WidthContainer className={classes.columnWrapper}>
            <Typography variant="h1">Ultra-packed variant TaBlE</Typography>
            <br />
            <Typography variant="body2">
              Economically spaced table configuration for advanced combos that are <br />
              off-the-hook and main-lined through scaling
            </Typography>
          </WidthContainer>
        </Grid>
        <WidthContainer className={classes.columnWrapper}>
          <Grid container spacing={1} className={classes.hero}>
            <Grid item xs={12}>
              <Box mt={3} mb={4}>
                {renderMarkdown(
                  'The rural site configuration for ultra-rural is using Commercial-off-the-Shelf (COTS) hardware and services to be deployed in small villages, typically with population of less than 3000 inhabitant.  The deployment model follows a green-field, town center model, for LTE, both data and VoTLE.  Such deployment model allows reduced cost, and is intended to area where electrical power is provided from Solar Panel.\n\n [Additional information](/solutions/tss)'
                )}
              </Box>
              <Variants data={null} />
              <br />
              <br />
              <Typography variant="h2">Requirements</Typography>
              <br />
              {renderMarkdown(
                '[TSS](/solutions/tss)\n\n [TSS Rural Site Configuration Plugfest](https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/182124550/TSS+Rural+Site+Configuration+PlugFest)'
              )}
              <br />
              <br />
              <Typography variant="h2">Architecture</Typography>
              <Typography variant="body1">
                <img src={MockImage} alt="mock" />
                <br />
                <br />
                The rural site configuration blueprint includes LTE Radio access network (RAN),
                satellite modem (vSAT), both powered through a power controller, itself connected to
                both solar panel and battery back-up, per the following block diagram.
                <br />
                <br />
                As part of the Rural Site configuration PlugFest, only the power controller was
                tested, not batteries and solar panel, thus these are not included in the list of
                equipment in the variants listed below. Each tested variant represent a set
                equipment that has been tested and validated by TIP T&V according to the test plan
                defined below
                <br />
                <br />
              </Typography>
              <Typography variant="h2">Test Plan</Typography>
              <Typography variant="body1">
                <br />
                N/A
                <br />
                <br />
              </Typography>
              <Typography variant="h2">Additional Documents</Typography>
              <Typography variant="body1">
                <br />
                N/A
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
    </MuiThemeProvider>
  )
}

export default BlueprintsChildMock2
