import React from 'react'
import ReactDOM from 'react-dom'
import 'core-js/stable' // needed for .flatMap

import configureStore, { history } from './store'
import Root from './Root'
import * as serviceWorker from './serviceWorker'
import config from './config'

const { store, persistor } = configureStore()

ReactDOM.render(
  <Root store={store} persistor={persistor} history={history} />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

console.log(`Build version: ${config.VERSION}`)
