import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  pageStyle: {
    margin: 'auto'
  },
  header: {},
  form: {
    border: '1px solid #c5c5c5',
    padding: '10px 20px 20px 20px'
  },
  container: {
    border: '1px solid #c5c5c5',
    padding: '50px 100px',
    maxWidth: 800,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    },
    marginBottom: 50
  },
  paragraphBold: {
    fontWeight: 600
  },
  marginBotom: {
    marginBottom: 50
  },
  roundedFullWidthButton: {
    height: 50,
    borderRadius: '50px !important'
  },
  description: {
    fontSize: '1.15rem'
  },
  heading: {
    marginBottom: 30,
    fontSize: '2rem',
    fontWeight: 500
  },
  spacingBottom: {
    marginBottom: 20
  },
  buttonContainer: {
    margin: '15px 0px 10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonRightContainer: {
    margin: '15px 0px 10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  alert: {
    height: 10,
    fontSize: 10
  },
  multiInputRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bold: {
    fontWeight: 600,
    margin: '0px 3px'
  },
  footer: {
    textAlign: 'right',
    borderTop: '1px dashed #c5c5c5',
    marginTop: '30px',
    paddingTop: '20px'
  },
  progress: {
    margin: 'auto'
  },
  divider: {
    margin: '40px 0px'
  }
}))
