import gql from 'graphql-tag'
// import { useQuery } from '@apollo/react-hooks'

export const GET_ASSET_PREVIEWS = gql`
  query($tags: [String]) {
    assetPreviewsByTag(tags: $tags) {
      id
      title
      excerpt
      description
      isFeatured
      slug
      analyticsLabel
      authRequired
      tags
      thumb
    }
  }
`
