import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { CONTACT_SUPPORT_URL } from '../utils/constants'

import { useFormStyles } from '../hooks'

export default function EmailResent({ resetState }) {
  const formClasses = useFormStyles()
  const classes = useStyles()

  return (
    <>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Your verification link has been resent
        </Typography>
        <Typography paragraph className={formClasses.description}>
          Please check your email and click on the activation link.
        </Typography>
        <Typography paragraph className={formClasses.paragraphBold}>
          Note: Please be sure to check your spam or junk folder.
        </Typography>
      </div>
      <div className={formClasses.divider}>
        <Divider variant="middle" />
      </div>
      <Typography paragraph className={formClasses.paragraphBold}>
        Haven't received your activation link?
      </Typography>
      <Button
        color="primary"
        className={classes.button}
        href={CONTACT_SUPPORT_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact Support
      </Button>
      <Button color="primary" className={classes.button} onClick={resetState}>
        Use a different email address
      </Button>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'unset',
    display: 'block',
    margin: 'auto'
  }
}))
