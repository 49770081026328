import React from 'react'
import { makeStyles } from '@material-ui/styles'

import LinkField from '../common/LinkField'
import { getOfferingMedia } from '../utils/storageHelper'

import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',

    '& ul, & h2, & h3, & h2, & p': {
      margin: 0
    },
    '& ul': {
      marginBottom: 32
    },
    '& h2': {
      fontSize: 32,
      fontWeight: 400,
      marginBottom: 32
    },
    '& h3': {
      fontSize: 22,
      fontWeight: 400,
      marginBottom: 16,
      lineHeight: 1.04,
      letterSpacing: 0
    },
    '& p': {
      marginBottom: 16,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em'
    },
    '& img': {
      maxWidth: '100%',
      marginBottom: 32
    },
    '& .container': {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    '& .column': {},
    '& .content': {
      paddingRight: 64,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 16
      }
    },
    '& .aside': {
      paddingRight: 64,
      padding: 24,
      backgroundColor: theme.palette.lightGreen.main,
      flex: '0 0 340px',
      '& h5': {
        marginBottom: 26,
        fontSize: 20,
        fontWeight: 500
      },
      [theme.breakpoints.down('sm')]: {
        flex: '0 0 100%'
      }
    }
  },
  ocp: {
    width: 75,
    position: 'absolute',
    top: 10,
    right: 10,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      right: 0,
      top: 0
    }
  }
}))

function OfferingSpecifications({
  offeringId,
  techSpecName,
  content,
  ocpCertified,
  githubLink,
  hideTitle
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {hideTitle ? null : <h2>Technology Specifications</h2>}
      {techSpecName ? (
        <LinkField
          to={getOfferingMedia(techSpecName)}
          label="View Specification Document"
          hideIcon={false}
          target="_blank"
          rel="noopener noreferrer"
        />
      ) : null}
      {githubLink ? (
        <React.Fragment>
          {techSpecName ? <br /> : null}
          <p style={{ marginTop: 15 }}>
            <LinkField
              to={githubLink}
              label="See the code on GitHub"
              hideIcon={true}
              target="_blank"
              rel="noopener noreferrer"
            />
          </p>
        </React.Fragment>
      ) : null}
      {/* )} */}
      {/* EXAMPLE CONTENT, DON'T DELETE */}
      {/* <div className='container'>
        <div className='column content'>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
          <img src='http://actelis.com/wp-content/uploads/2013/12/Symmetric-WiFI-Backhaul-Solution.png' alt='' />
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate
          </p>
          <h3>
            Features
          </h3>
          <ul>
            <li>consectetur adipiscing elit sed do</li>
            <li>quis nostrud exercitation ullamco</li>
            <li>Duis aute irure dolor in reprehenderit</li>
            <li>Duis aute irure dolor in reprehenderit</li>
          </ul>
          <h3>
            Lorem ipsum dolar sit amet
          </h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </div>
        <div className='aside'>
          <h5>Lorem ipsum dolar sit amet</h5>
          <p>
            <strong>Lorem ipsum dolor sit amet</strong>, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate
          </p>
          <p>
            <strong>Lorem ipsum dolor sit amet</strong>, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore
          </p>
        </div>
      </div> */}
      {ocpCertified ? (
        <img
          className={classes.ocp}
          src={`${config.MEDIA_CDN_DOMAIN}/media/offeringImages/ocp-accepted.svg`}
          alt="OCP"
        />
      ) : null}
    </div>
  )
}

export default OfferingSpecifications
