import React from 'react'
import './DCSG/dcsg.css'

import Tooltip from './DCSG/Tooltip'
import config from '../config'

/*
2022 10 06 - Nik Butler
Static Banner page created from content delivered by WeAreImpulse
Note the img files have been uploaded direct into the Storage blobs url example below
   https://tipcontent301.blob.core.windows.net/media/Marketplace/MarketplaceBanner/img/ufispace.png
   so yeah we want to remove hard codes to links there 

   e.g. 
   src=`url(${config.MEDIA_CDN_DOMAIN}/media/Page-Bottom.svg) 
*/

function MarketplaceBanner() {
  const ImageCdn = `${config.MEDIA_CDN_DOMAIN}/media/Marketplace/MarketplaceBanner/img/`
    //  'https://cdn2022.telecominfraproject.com/media/Marketplace/MarketplaceBanner/img/'
  return (
    <section id="_wirygwpr9gb_dcsg">
      <div className="_wirygwpr9gb_container">
        <div className="_wirygwpr9gb_title">
          <h2>
            <strong>OOPT (Open Optical Packet Transport)</strong>
            <span>
              <br />
            </span>
            DCSG (DIsaggreated Cell Site Gateway)
          </h2>
        </div>
        <div className="_wirygwpr9gb_clear" />
        <div className="_wirygwpr9gb_header_hide">
          <img
            src={`${ImageCdn}header.png`}
            className="_wirygwpr9gb_header"
            alt="OOPT"
            title="OOPT"
          />
        </div>
        <div className="_wirygwpr9gb_clear" />
        <ul className="_wirygwpr9gb_table">
          <li>
            <div className="_wirygwpr9gb_head _wirygwpr9gb_first _wirygwpr9gb_light_green_bg">
              <div className="_wirygwpr9gb_col_1 _wirygwpr9gb_col _wirygwpr9gb_dark_green_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                <span>AWARD</span>
              </div>
              <div className="_wirygwpr9gb_col_2 _wirygwpr9gb_col">
                <span>USE CASE DESCRIPTION</span>
              </div>
              <div className="_wirygwpr9gb_col_3 _wirygwpr9gb_col">
                <span className="_wirygwpr9gb_sticker">HW</span>
              </div>
              <div className="_wirygwpr9gb_col_4 _wirygwpr9gb_col">
                <span className="_wirygwpr9gb_sticker _wirygwpr9gb_lime">SW</span>
              </div>
              <div className="_wirygwpr9gb_col_5 _wirygwpr9gb_col">&nbsp;</div>
              <div className="_wirygwpr9gb_col_6 _wirygwpr9gb_col _wirygwpr9gb_blue_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                <span>RESOURCE LINKS</span>
              </div>
            </div>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_middle _wirygwpr9gb_first">
              <label className="_wirygwpr9gb_label_1 _wirygwpr9gb_dark_green_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                AWARD
              </label>
              <label className="_wirygwpr9gb_label_2 _wirygwpr9gb_light_green_bg">
                USE CASE DESCRIPTION
              </label>
              <div className="_wirygwpr9gb_col_1 _wirygwpr9gb_col _wirygwpr9gb_gold_bg _wirygwpr9gb_txt-center">
                <img
                  src={`${ImageCdn}badge.png`}
                  className="_wirygwpr9gb_badge"
                  alt="Gold"
                  title="Gold"
                />

                <p>
                  November
                  <br />
                  2021
                </p>
              </div>
              <div className="_wirygwpr9gb_col_2 _wirygwpr9gb_col">
                <p>
                  Cell site gateway in a production network serving and aggregating 2G, 3G, 4G and
                  5G mobile, fixed access and enterprise traffic.
                </p>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker">HW</span>
              </label>
              <div className="_wirygwpr9gb_col_3 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/61ba24af70b87c0018bde8dc"
                >
                  <img
                    src={`${ImageCdn}delta.png`}
                    className="_wirygwpr9gb_logo"
                    alt="Delta Electronics"
                    title="Delta Electronics"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    Cell Site Gateway (HW)
                    <br />
                    <strong>Model: </strong>
                    AGCV208SAGCV208S
                    <br />
                    <strong>Version: </strong>AX
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/60be93372d09144ab189c196/offerings/61ba24af70b87c0018bde8dc/techspec/l7ZxgkPt7.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker _wirygwpr9gb_lime">SW</span>
              </label>
              <div className="_wirygwpr9gb_col_4 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href={`${config.MEDIA_CDN_DOMAIN}/hub/6232f3a1b509100019763991/offerings/5feb5eb88760a100118e1d11/techspec/puStY8r9e.pdf`} >
                  <img
                    src={`${ImageCdn}capgemini.png`}
                    className="_wirygwpr9gb_logo"
                    alt="Capgemini Engineering"
                    title="Capgemini Engineering"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    NOS for Access Aggregation and Backhaul
                    <br />
                    <strong>Model: </strong>N/A
                    <br />
                    <strong>Version: </strong>10.4.2.14
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/6232f3a1b509100019763991/offerings/5feb5eb88760a100118e1d11/techspec/puStY8r9e.pdf`}>
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <div className="_wirygwpr9gb_col_5 _wirygwpr9gb_col">&nbsp;</div>
              <label className="_wirygwpr9gb_label_6 _wirygwpr9gb_blue_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                RESOURCE LINKS
              </label>
              <div className="_wirygwpr9gb_col_6 _wirygwpr9gb_col _wirygwpr9gb_light_blue_bg">
                <ul className="_wirygwpr9gb_links _wirygwpr9gb_is_blue">
                  <li>
                    <Tooltip>
                      Cell site gateway in a production network serving and aggregating 2G, 3G, 4G
                      and 5G mobile, fixed access and enterprise traffic.
                    </Tooltip>
                  </li>
                  <li>
                    <a href="https://cdn.mediavalet.com/usva/telecominfraproject/fSlhAvf4P0iflU8O-5Z8Cw/kMI43F8h10SP9DANVm8x8A/Original/DCSG_Technical_Specification_-_Telecom_Infra_Project.pdf">
                      Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Detailed Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Test Plan
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Exit Report
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_foot _wirygwpr9gb_green_bg _wirygwpr9gb_txt-white">
              <p>
                <strong>LAB: </strong>TIP Community Lab @ Meta, California
                <strong>
                  <span>|</span>Validated by:
                </strong>
                TIP Participants
              </p>
            </div>
          </li>
          <li>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_middle">
              <label className="_wirygwpr9gb_label_1 _wirygwpr9gb_dark_green_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                AWARD
              </label>
              <label className="_wirygwpr9gb_label_2 _wirygwpr9gb_light_green_bg">
                USE CASE DESCRIPTION
              </label>
              <div className="_wirygwpr9gb_col_1 _wirygwpr9gb_col _wirygwpr9gb_gold_bg _wirygwpr9gb_txt-center">
                <img
                  src={`${ImageCdn}badge.png`}
                  className="_wirygwpr9gb_badge"
                  alt="Gold"
                  title="Gold"
                />
                <p>
                  November
                  <br />
                  2021
                </p>
              </div>
              <div className="_wirygwpr9gb_col_2 _wirygwpr9gb_col">
                <p>
                  Cell site gateway in a production network serving and aggregating 2G, 3G, 4G and
                  5G mobile, fixed access and enterprise traffic.
                </p>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker">HW</span>
              </label>
              <div className="_wirygwpr9gb_col_3 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/61ba2434d9855a0019473aa3"
                >
                  <img
                    src={`${ImageCdn}delta.png`}
                    className="_wirygwpr9gb_logo"
                    alt="Delta Electronics"
                    title="Delta Electronics"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    Cell SIte Gateway (HW)
                    <br />
                    <strong>Model:</strong>AGC7008S
                    <br />
                    <strong>Version: </strong>AX
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/60be93372d09144ab189c196/offerings/61ba2434d9855a0019473aa3/techspec/xKj5TEd4d.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker lime">SW</span>
              </label>
              <div className="_wirygwpr9gb_col_4 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/5feb5eb88760a100118e1d11"
                >
                  <img
                    src={`${ImageCdn}capgemini.png`}
                    className="_wirygwpr9gb_logo"
                    alt="Capgemini EngineeringCapgemini Engineering"
                    title="Capgemini Engineering"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    NOS for Access Aggregation and Backhaul
                    <br />
                    <strong>Model: </strong>N/A
                    <br />
                    <strong>Version: </strong>10.4.2.14
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/6232f3a1b509100019763991/offerings/5feb5eb88760a100118e1d11/techspec/puStY8r9e.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <div className="_wirygwpr9gb_col_5 _wirygwpr9gb_col">&nbsp;</div>
              <label className="_wirygwpr9gb_label_6 _wirygwpr9gb_blue_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                RESOURCE LINKS
              </label>
              <div className="_wirygwpr9gb_col_6 _wirygwpr9gb_col _wirygwpr9gb_light_blue_bg">
                <ul className="_wirygwpr9gb_links _wirygwpr9gb_is_blue">
                  <li>
                    <Tooltip>
                      Cell site gateway in a production network serving and aggregating 2G, 3G, 4G
                      and 5G mobile, fixed access and enterprise traffic.
                    </Tooltip>
                  </li>
                  <li>
                    <a href="https://cdn.mediavalet.com/usva/telecominfraproject/fSlhAvf4P0iflU8O-5Z8Cw/kMI43F8h10SP9DANVm8x8A/Original/DCSG_Technical_Specification_-_Telecom_Infra_Project.pdf">
                      Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Detailed Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Test Plan
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562771482/Final+Test+Materials+-+Capgemini+Delta+E2E+DCSG+Transport+PlugFest+ORANGE">
                      Exit Report
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_foot _wirygwpr9gb_green_bg _wirygwpr9gb_txt-white">
              <p>
                <strong>LAB: </strong>TIP Community Lab @ Meta, California
                <strong>
                  <span>|</span>Validated by:
                </strong>
                TIP Participants
              </p>
            </div>
          </li>
          <li>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_middle">
              <label className="_wirygwpr9gb_label_1 _wirygwpr9gb_dark_green_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                AWARD
              </label>
              <label className="_wirygwpr9gb_label_2 _wirygwpr9gb_light_green_bg">
                USE CASE DESCRIPTION
              </label>
              <div className="_wirygwpr9gb_col_1 _wirygwpr9gb_col _wirygwpr9gb_gold_bg _wirygwpr9gb_txt-center">
                <img
                  src={`${ImageCdn}badge.png`}
                  className="_wirygwpr9gb_badge"
                  alt="Gold"
                  title="Gold"
                />
                <p>
                  June
                  <br />
                  2022
                </p>
              </div>
              <div className="_wirygwpr9gb_col_2 _wirygwpr9gb_col">
                <p>
                  Cell site gateway in a production network serving and aggregating 2G, 3G, 4G and
                  5G mobile, fixed access and enterprise traffic.
                </p>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker">HW</span>
              </label>
              <div className="_wirygwpr9gb_col_3 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/5dc5ba5e89edf900113e7fb2"
                >
                  <img
                    src={`${ImageCdn}ufispace.png`}
                    className="_wirygwpr9gb_logo"
                    alt="UfiSpace"
                    title="UfiSpace"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    Cell SIte Gateway (HW)
                    <br />
                    <strong>Model:</strong>S9500-30XS
                    <br />
                    <strong>Version: </strong>30XS
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/4fe2be47af46d049ad18486c/offerings/5dc5ba5e89edf900113e7fb2/techspec/c61wAfFFMU.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker lime">SW</span>
              </label>
              <div className="_wirygwpr9gb_col_4 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/5da102fbe2109da5a5bb6729"
                >
                  <img
                    src={`${ImageCdn}ipinfusion.png`}
                    className="_wirygwpr9gb_logo"
                    alt="IP Infusion"
                    title="IP Infusion"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    OcNOS
                    <br />
                    <strong>Model: </strong>5.0 MR
                    <br />
                    <strong>Version: </strong>187
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/9624f89c1ad983489f6bc135/offerings/5da102fbe2109da5a5bb6729/techspec/Fu2DSg9Qt.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <div className="_wirygwpr9gb_col_5 _wirygwpr9gb_col">&nbsp;</div>
              <label className="_wirygwpr9gb_label_6 _wirygwpr9gb_blue_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                RESOURCE LINKS
              </label>
              <div className="_wirygwpr9gb_col_6 _wirygwpr9gb_col _wirygwpr9gb_light_blue_bg">
                <ul className="_wirygwpr9gb_links _wirygwpr9gb_is_blue">
                  <li>
                    <Tooltip>
                      Cell site gateway in a production network serving and aggregating 2G, 3G, 4G
                      and 5G mobile, fixed access and enterprise traffic.
                    </Tooltip>
                  </li>
                  <li>
                  <a href="https://cdn.mediavalet.com/usva/telecominfraproject/fSlhAvf4P0iflU8O-5Z8Cw/kMI43F8h10SP9DANVm8x8A/Original/DCSG_Technical_Specification_-_Telecom_Infra_Project.pdf">
                      Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/OOPT/pages/1658060828/TIP+OOPT+DCSG+dTRD+Final">
                      Detailed Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562050630/NOW+AVAILABLE+Transport+Test+Plan+-+v.+November+2021">
                      Test Plan
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1555660815/IP+Infusion+UfiSpace+E2E+Transport+Testing">
                      Exit Report
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_foot _wirygwpr9gb_green_bg _wirygwpr9gb_txt-white">
              <p>
                <strong>LAB: </strong>TIP CL ITRI lab in Taiwan
                <strong>
                  <span>|</span>Validated by:
                </strong>
                Test &amp; Vaidation Committee/ITRI/Test &amp; Integration and OOPT Technical Leads
              </p>
            </div>
          </li>
          <li>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_middle">
              <label className="_wirygwpr9gb_label_1 _wirygwpr9gb_dark_green_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                AWARD
              </label>
              <label className="_wirygwpr9gb_label_2 _wirygwpr9gb_light_green_bg">
                USE CASE DESCRIPTION
              </label>
              <div className="_wirygwpr9gb_col_1 _wirygwpr9gb_col _wirygwpr9gb_gold_bg _wirygwpr9gb_txt-center">
                <img
                  src={`${ImageCdn}badge.png`}
                  className="_wirygwpr9gb_badge"
                  alt="Gold"
                  title="Gold"
                />
                <p>
                  June
                  <br />
                  2022
                </p>
              </div>
              <div className="_wirygwpr9gb_col_2 _wirygwpr9gb_col">
                <p>
                  Cell site gateway in a production network serving and aggregating 2G, 3G, 4G and
                  5G mobile, fixed access and enterprise traffic.
                </p>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker">HW</span>
              </label>
              <div className="_wirygwpr9gb_col_3 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/60070be5cb115c001b9cfbbe"
                >
                  <img
                    src={`${ImageCdn}ufispace.png`}
                    className="_wirygwpr9gb_logo"
                    alt="UfiSpace"
                    title="UfiSpace"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    Cell SIte Gateway (HW)
                    <br />
                    <strong>Model:</strong>22XST
                    <br />
                    <strong>Version: </strong>AX
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/4fe2be47af46d049ad18486c/offerings/60070be5cb115c001b9cfbbe/techspec/i5ms7Pxlse.pdf`} >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <label className="_wirygwpr9gb_light_green_bg">
                <span className="_wirygwpr9gb_sticker lime">SW</span>
              </label>
              <div className="_wirygwpr9gb_col_4 _wirygwpr9gb_col">
                <a
                  className="_wirygwpr9gb_pop"
                  href="https://exchange.telecominfraproject.com/marketplace/offerings/5da102fbe2109da5a5bb6729"
                >
                  <img
                    src={`${ImageCdn}ipinfusion.png`}
                    className="_wirygwpr9gb_logo"
                    alt="IP Infusion"
                    title="IP Infusion"
                  />
                  <p>
                    <strong>Product Name: </strong>
                    <br />
                    OcNOS
                    <br />
                    <strong>Model: </strong>5.0 MR
                    <br />
                    <strong>Version: </strong>187
                  </p>
                </a>
                <ul className="_wirygwpr9gb_links">
                  <li>
                    <a href={`${config.MEDIA_CDN_DOMAIN}/hub/9624f89c1ad983489f6bc135/offerings/5da102fbe2109da5a5bb6729/techspec/Fu2DSg9Qt.pdf` } >
                      Data Sheet
                    </a>
                  </li>
                </ul>
              </div>
              <div className="_wirygwpr9gb_col_5 _wirygwpr9gb_col">&nbsp;</div>
              <label className="_wirygwpr9gb_label_6 _wirygwpr9gb_blue_bg _wirygwpr9gb_txt-center _wirygwpr9gb_txt-white">
                RESOURCE LINKS
              </label>
              <div className="_wirygwpr9gb_col_6 _wirygwpr9gb_col _wirygwpr9gb_light_blue_bg">
                <ul className="_wirygwpr9gb_links _wirygwpr9gb_is_blue">
                  <li>
                    <Tooltip>
                      Cell site gateway in a production network serving and aggregating 2G, 3G, 4G
                      and 5G mobile, fixed access and enterprise traffic.
                    </Tooltip>
                  </li>
                  <li>
                  <a href="https://cdn.mediavalet.com/usva/telecominfraproject/fSlhAvf4P0iflU8O-5Z8Cw/kMI43F8h10SP9DANVm8x8A/Original/DCSG_Technical_Specification_-_Telecom_Infra_Project.pdf">
                      Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/OOPT/pages/1658060828/TIP+OOPT+DCSG+dTRD+Final">
                      Detailed Technical Requirements
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1562050630/NOW+AVAILABLE+Transport+Test+Plan+-+v.+November+2021">
                      Test Plan
                    </a>
                  </li>
                  <li>
                    <a href="https://telecominfraproject.atlassian.net/wiki/spaces/PFB/pages/1555660815/IP+Infusion+UfiSpace+E2E+Transport+Testing">
                      Exit Report
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="_wirygwpr9gb_clear" />
            <div className="_wirygwpr9gb_foot _wirygwpr9gb_green_bg _wirygwpr9gb_txt-white">
              <p>
                <strong>LAB: </strong>TIP CL ITRI lab in Taiwan
                <strong>
                  <span>|</span>Validated by:
                </strong>
                Test &amp; Vaidation Committee/ITRI/Test &amp; Integration and OOPT Technical Leads
              </p>
            </div>
          </li>
        </ul>
        <div className="_wirygwpr9gb_clear" />
      </div>
    </section>
  )
}

export default MarketplaceBanner
