export const SUPPORT_EMAIL = `support@telecominfraproject.com`
export const CONTACT_SUPPORT_URL = `https://telecominfraproject.com/contact`

export const COMPANY_TYPE_OPTIONS = [
  {
    label: 'Academic',
    value: 'Academic'
  },
  {
    label: 'Academic Institution',
    value: 'Academic Institution'
  },
  {
    label: 'Analyst',
    value: 'Analyst'
  },
  {
    label: 'Consultant',
    value: 'Consultant'
  },
  {
    label: 'Government Agency',
    value: 'Government Agency'
  },
  {
    label: 'Hardware OEM',
    value: 'Hardware OEM'
  },
  {
    label: 'Individual',
    value: 'Individual'
  },
  {
    label: 'Investor',
    value: 'Investor'
  },
  {
    label: 'Non-Government Agency',
    value: 'Non-Government Agency'
  },
  {
    label: 'Press',
    value: 'Press'
  },
  {
    label: 'Reseller',
    value: 'Reseller'
  },
  {
    label: 'Software Development',
    value: 'Software Development'
  },
  {
    label: 'Startup',
    value: 'Startup'
  },
  {
    label: 'System Integrator',
    value: 'System Integrator'
  },
  {
    label: 'Telecom Service Provider',
    value: 'Telecom Service Provider'
  },
  {
    label: 'Webscale',
    value: 'Webscale'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

export const REGION_OPTIONS = [
  {
    label: 'Africa',
    value: 'Africa'
  },
  {
    label: 'Arab States',
    value: 'Arab States'
  },
  {
    label: 'Asia & Pacific',
    value: 'Asia & Pacific'
  },
  {
    label: 'Europe',
    value: 'Europe'
  },
  {
    label: 'North America',
    value: 'North America'
  },
  {
    label: 'South/Latin America',
    value: 'South/Latin America'
  },
  {
    label: 'Unknown',
    value: 'Unknown'
  }
]
