import React from 'react'
import clsx from 'clsx'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PdfIcon from '../assets/images/picture_as_pdf_outline_teal_24dp.svg'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  externalIconLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  externalIcon: {
    fontSize: 15,
    marginLeft: 5
  },
  borderBottom: {
    borderBottom: `1px solid #d2d2d2`
  },
  pdfLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  pdfLinkIcon: {
    maxWidth: '100%',
    marginBottom: '-4px !important',
    marginLeft: 4,
    borderBottom: `1px solid #FFFFFF`
  }
}))

const isExternal = url => {
  var host = window.location.hostname

  var linkHost = (function(url) {
    if (/^mailto?/.test(url)) {
      return true
    }

    if (/^https?:\/\//.test(url)) {
      var parser = document.createElement('a')
      parser.href = url

      return parser.hostname
    } else {
      return window.location.hostname
    }
  })(url)

  return host !== linkHost
}

const urlParseLinkInternal = url => {
  if (!url.startsWith('http') || url.includes('telecominfraproject.com')) {
    return true
  }
  return false
}

const LinkField = React.forwardRef(
  (
    {
      icon,
      to,
      hideIcon = urlParseLinkInternal(to),
      children,
      className,
      label,
      linkClasses,
      hideBorder,
      forceExternal = false,
      forceInternal = false,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()

    let url = to

    if (forceExternal) {
      // if we want to force the link to be external we must ensure it starts with http
      var prefix = 'http'
      if (url.substr(0, prefix.length) !== prefix) {
        url = 'http://' + url
      }
    }

    const iconPostLink = to.includes('.pdf') ? (
      <img className={classes.pdfLinkIcon} src={PdfIcon} alt="pdf open in new tab" />
    ) : (
      <OpenInNewIcon className={classes.externalIcon} />
    )

    return (isExternal(url) || forceExternal) && forceInternal === false ? (
      hideIcon ? (
        <a
          href={to}
          {...rest}
          className={clsx(classes.link, className, { [classes.borderBottom]: !hideBorder })}
          onClick={e => e.stopPropagation()}
        >
          {children}
          {label}
        </a>
      ) : (
        <>
          <a
            href={to}
            {...rest}
            rel="noopener noreferrer"
            className={clsx([{ [classes.borderBottom]: !hideBorder }, classes.pdfLink, className])}
            target="_blank"
          >
            {children}
            {label}
          </a>
          {!hideIcon && (icon || iconPostLink)}
        </>
      )
    ) : (
      <Link
        to={to}
        innerRef={ref}
        className={
          className || clsx([{ [classes.borderBottom]: !hideBorder }, classes.link, linkClasses])
        }
        onClick={e => e.stopPropagation()}
        {...rest}
      >
        {children}
        {label}
      </Link>
    )
  }
)

export default LinkField

export { isExternal }
