import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

import ContactUsModal from './ContactUsModal'
import { logRequestInfo } from '../utils/googleAnalytics'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.gray5.main,
    padding: '24px 45px',
    marginLeft: 40,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  copy: {
    flexGrow: '1'
  },
  body: {
    marginTop: 10
  },
  button: {
    width: 'fit-content',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}))

function ContactUs(props) {
  const classes = useStyles()
  const { title, body, buttonText } = props
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false)

  return (
    <>
      <div className={classes.container}>
        <div className={classes.copy}>
          {title}
          {body}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            logRequestInfo('HOMEPAGE-CONTACT-FORM')
            setContactUsModalOpen(true)
          }}
        >
          {buttonText}
        </Button>
      </div>
      <ContactUsModal handleClose={() => setContactUsModalOpen(false)} open={contactUsModalOpen} />
    </>
  )
}

export default ContactUs
