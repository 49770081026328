import React, { useEffect, useState, useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'
import clsx from 'clsx'
import RoundedButton from '../common/RoundedButton'

import InputTextField from '../common/InputTextField'
import Progress from '../common/Progress'
import LinkField from '../common/LinkField'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'

export const REGISTER_USER = gql`
  mutation($input: UserRegisterInput!) {
    register(input: $input) {
      data
      result
    }
  }
`

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 40,
  },
  tipMemberMessage: {
    marginTop: 20,
  },
  loginLinkContainer: {
    justifyContent: 'flex-end',
  },
}))

export default function GetStarted({ onClickSignIn, setEmail }) {
  const [formState, { email }] = useFormState()
  const { loading, execute: handleContinue, data: registerUserResult } = useMutation(REGISTER_USER)
  const [blacklistError, setBlacklistError] = useState(null)
  const formClasses = useFormStyles()
  const classes = useStyles()

  const getEmailBlacklist = import('../utils/emailBlacklist')

  const useEmailBlacklist = useCallback(getEmailBlacklist, [])

  useEffect(() => {
    if (formState.values.email) {
      useEmailBlacklist
        .then((checkBlacklist) => checkBlacklist.default(formState.values.email))
        .then((isBlacklisted) => {
          if (isBlacklisted) {
            setBlacklistError("Please use your organization's email domain.")
          } else {
            setBlacklistError(null)
          }
        })
    }
  }, [formState.values.email, useEmailBlacklist])

  useEffect(() => {
    if (
      !loading &&
      registerUserResult &&
      registerUserResult.register &&
      registerUserResult.register.result
    ) {
      setEmail(registerUserResult.register.data)
    }
  }, [registerUserResult, loading, setEmail])

  function handleSubmit(e) {
    e.preventDefault()
    handleContinue({ values: { email: formState.values.email } })
  }

  const inputs = {
    email: {
      gridWidth: { xs: 12 },
      name: 'email',
      label: 'Organization Email',
      inputType: email,
      autoFocus: true,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email',
      },
      variant: 'outlined',
    },
    confirmEmail: {
      id: 'ConfirmEmail',
      gridWidth: { xs: 12 },
      name: 'confirmEmail',
      label: 'Re-enter Organization Email',
      inputType: email,
      autoFocus: true,
      validator: {
        required: true,
        requiredMatch: true,
        mustMatchKey: 'email',
        mustMatchMessage: 'Emails must match',
        caseInsensitive: true,
      },
      variant: 'outlined',
      onPaste: (e) => {
        e.preventDefault()
      },
    },
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  // if error invalid show it, otherwise show if blacklisted
  const formError = blacklistError || formState.errors.email
  const confirmEmailError = formState.errors.confirmEmail
  const errorMessage = formError ? (
    blacklistError ? (
      <>
        <span>{blacklistError}</span>{' '}
        <a href="mailto:support@telecominfraproject.com">Click here to Contact Support</a>
      </>
    ) : (
      formState.errors.email
    )
  ) : null

  const confirmEmailErrorMessage = confirmEmailError ? formState.errors.confirmEmail : null

  return (
    <form onSubmit={handleSubmit}>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Let's get started
        </Typography>
        <Typography paragraph className={formClasses.description}>
          Join Telecom Infra Project (TIP)
        </Typography>
      </div>
      <Typography className={clsx(formClasses.spacingBottom, formClasses.paragraphBold)}>
        Register with your organization email
      </Typography>
      <Grid className={formClasses.spacingBottom} container spacing={2}>
        {Object.entries(inputs).map(([name, args]) => (
          <Grid key={name} item {...args.gridWidth}>
            <InputTextField
              key={name}
              {...args}
              error={name === 'email' ? !!formError : !!confirmEmailError}
              helperText={name === 'email' ? errorMessage : confirmEmailErrorMessage}
            />
          </Grid>
        ))}
      </Grid>
      <div className={formClasses.buttonContainer}>
        {loading ? (
          <div className={formClasses.progress}>
            <Progress size={30} />
          </div>
        ) : (
          <RoundedButton
            size="large"
            onClick={handleSubmit}
            disabled={disableSubmit || blacklistError}
            data-testid="SignUpSubmitBtn"
            fullWidth
          >
            Continue
          </RoundedButton>
        )}
      </div>
      <div className={clsx(classes.loginLinkContainer, formClasses.buttonContainer)}>
        <LinkField to={'/login'} label="Already a TIP participant? Sign In" />
      </div>
    </form>
  )
}
