import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'

import OrganizationAutocomplete from './OrganizationAutocomplete'
import CheckboxField from '../common/CheckboxField'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled } from '../utils'
import { useSession, getSessionQueries } from '../auth/queries'

export default function SelectOrganization(props) {
  const [formState, { text, checkbox }] = useFormState()
  const formClasses = useFormStyles()
  const classes = useStyles()
  const { loading: loadingSession, session } = useSession()

  const userId = session && session.user ? session.user.id : ''

  const options = {
    refetchQueries: getSessionQueries(userId),
    awaitRefetchQueries: true,
  }

  const { loading: joinOrgLoading, execute: requestToJoin, error: joinOrgError } = useMutation(
    REQUEST_TO_JOIN_ORGANIZATION,
    options
  )

  const { loading: createOrgLoading, execute: createOrg, error: createOrgError } = useMutation(
    CREATE_ORGANIZATION,
    options
  )

  if (loadingSession) {
    return <Progress />
  }

  const { organizationId, organization } = formState.values
  const newOrg = organization && !organizationId

  function chooseOrganization() {
    if (newOrg) {
      createOrg({
        values: {
          organization,
        },
      })
    } else {
      requestToJoin({
        values: {
          orgId: organizationId,
        },
      })
    }
  }

  const inputs = {
    organization: {
      visible: true,
      InputComponent: OrganizationAutocomplete,
      gridWidth: { xs: 12 },
      name: 'organization',
      inputType: text,
      validator: {
        required: true,
      },
      setFormState: (key, value) => {
        formState.setField(key, value)
      },
    },
    createNewOrgCheckbox: {
      visible: newOrg,
      InputComponent: CheckboxField,
      gridWidth: { xs: 12 },
      name: 'createNewOrgCheckbox',
      testid: 'createNewOrgCheckbox',
      label: <CreateNewOrgCheckbox />,
      inputType: checkbox,
      labelPlacement: 'end',
      validator: {
        required: newOrg,
      },
    },
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  return (
    <div data-testid="SelectOrganizationContainer" className={classes.container}>
      <div className={clsx(formClasses.header, classes.header)}>
        <Typography role="heading" variant="h3" className={formClasses.heading}>
          Select Organization
        </Typography>
        <Typography paragraph>
          Search for existing TIP Participant organizations or register a new organization
        </Typography>
      </div>
      <div className={classes.alert}>
        {joinOrgError && (
          <Typography role="alert" color="error">
            {joinOrgError.graphQLErrors[0].message}
          </Typography>
        )}
        {createOrgError && (
          <Typography role="alert" color="error">
            {createOrgError.graphQLErrors[0].message}
          </Typography>
        )}
      </div>
      <form>
        <Grid className={formClasses.spacingBottom} container spacing={2}>
          {Object.entries(inputs).map(
            ([name, { InputComponent, visible, ...args }]) =>
              visible && (
                <Grid key={name} item {...args.gridWidth}>
                  <InputComponent
                    key={name}
                    {...args}
                    error={formState.errors[name] ? true : false}
                    helperText={formState.errors[name]}
                  />
                </Grid>
              )
          )}
        </Grid>
      </form>
      <div className={formClasses.buttonContainer}>
        {joinOrgLoading || createOrgLoading ? (
          <div className={formClasses.progress}>
            <Progress size={30} />
          </div>
        ) : (
          <RoundedButton
            onClick={chooseOrganization}
            disabled={disableSubmit}
            data-testid="JoinOrganizationBtn"
            fullWidth={false}
            className={clsx(classes.button, formClasses.roundedFullWidthButton)}
          >
            Next
          </RoundedButton>
        )}
      </div>
    </div>
  )
}

export const REQUEST_TO_JOIN_ORGANIZATION = gql`
  mutation($input: OrganizationUserInput!) {
    requestToJoinOrganization(input: $input) {
      data
      result
    }
  }
`
export const CREATE_ORGANIZATION = gql`
  mutation($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      result
      data
    }
  }
`

function CreateNewOrgCheckbox() {
  return <div>Create New Organization</div>
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px',
    },
  },
  button: {
    width: 500,
    margin: 'auto',
    borderRadius: 50,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))
