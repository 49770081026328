import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useSession } from './queries'

function AnonymousRoute({ component: Component, ...rest }) {
  const { loading, isAuthed } = useSession()
  if (loading) {
    return null
  }

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default AnonymousRoute
