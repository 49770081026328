import React from 'react'

import Button from '@material-ui/core/Button'

const BecomeAMemberButton = ({ color, variant, text }) => {
  return (
    <Button
      color={color || 'secondary'}
      variant={variant || 'contained'}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()

        window.open('https://telecominfraproject.com/apply-for-membership/')
      }}
    >
      {text || 'Contact Membership'}
    </Button>
  )
}

export default BecomeAMemberButton
