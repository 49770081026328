import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/styles'
import { MobileNav, DesktopNav, ExchangeNav, ExchangeMobileNav } from '.'
import { useSession } from '../auth/queries'
import { GET_NAV_ITEMS } from './queries'
import { useQuery } from '../hooks'
import { usePageContent, getSection } from '../utils/pageContentHelper'

export const toolbarHeight = 66

export const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  desktop: {
    paddingLeft: 20,
    paddingRight: 20
  },
  navContainerRoot: {
    backgroundColor: theme.palette.navDarkGray.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    height: toolbarHeight,
    zIndex: theme.zIndex.drawer + 1
  },
  navContainerRootMobile: {
    backgroundColor: theme.palette.navDarkGray.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    height: toolbarHeight
  },
  mobileItem: {
    fontSize: 16,
    padding: '16px 16px',
    borderTop: '1px solid #dfdfdf',
    borderBottom: '1px solid #dfdfdf',
    width: '100%',
    opacity: 1,
    transition: '0.25s opacity, 0.45s padding, 0.65s height'
  },
  navLogoImage: {
    height: 30,
    width: 'auto'
  },
  icon: {
    marginRight: 5
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff'
  },
  item: {
    textDecoration: 'none !important',
    textTransform: 'initial',
    fontSize: 14,
    color: 'white',
    padding: 0
  }
}))

function Header() {
  const classes = useStyles()
  const { data: pageContent = [], error: pageContentError } = usePageContent('Exchange Nav')
  const sections = {
    siteName: getSection(pageContent, 'Site Name'),
    exchangeMenuItems: getSection(pageContent, 'Exchange Menu Items')
  }

  if (pageContentError) {
    console.error('Error loading page content Exchange Menu Items:', pageContentError)
  }

  const exchangeNavItems =
    sections.exchangeMenuItems && sections.exchangeMenuItems.navItems
      ? sections.exchangeMenuItems.navItems
      : []

  const { loading, session, isAuthed, isOrgAdmin } = useSession()

  const [isShown, setIsShown] = useState(0)

  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const [isMobileSolutionOpen, setIsMobileSolutionsOpen] = useState(false)

  const { data: { nav = [] } = [] } = useQuery(GET_NAV_ITEMS)

  if (loading) {
    return null
  }

  return (
    <div onMouseLeave={() => setIsShown(0)}>
      <AppBar className={classes.appBar} position="fixed">
        <Hidden className={classes.desktop} only={['xs', 'sm']}>
          <Toolbar classes={{ root: classes.navContainerRoot }}>
            <DesktopNav
              isAuthed={isAuthed}
              session={session}
              headerNavItems={nav || []}
              isOrgAdmin={isOrgAdmin}
            />
          </Toolbar>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <Toolbar classes={{ root: classes.navContainerRootMobile }}>
            <MobileNav headerNavItems={nav || []} session={session} isOrgAdmin={isOrgAdmin} />
          </Toolbar>
          <ExchangeMobileNav
            isMobileSolutionOpen={isMobileSolutionOpen}
            isMobileOpen={isMobileOpen}
            setIsMobileOpen={setIsMobileOpen}
            setIsMobileSolutionsOpen={setIsMobileSolutionsOpen}
            exchangeNavItems={exchangeNavItems}
            siteName={sections.siteName}
          />
        </Hidden>
      </AppBar>
      <ExchangeNav
        setIsShown={setIsShown}
        isShown={isShown}
        exchangeNavItems={exchangeNavItems}
        siteName={sections.siteName}
      />
    </div>
  )
}

export default Header
