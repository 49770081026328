/*
  subMenu hover solution adapted from here https://stackoverflow.com/a/61486098
*/

import React, { useState } from 'react'
import { Divider, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Link } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import InputIcon from '@material-ui/icons/Input'
import clsx from 'clsx'

import logoImage from './tip-header-logo.svg'
import { useStyles } from './Header'
import SubMenu from './SubMenu'
import LinkField from '../common/LinkField'

import { getMyAccountLinks } from './'
import { useQuery } from '../hooks'
import { useUserOrganization } from '../auth/queries'
import { GET_ORGANIZATION_DATA } from './queries'

const navStyles = makeStyles(theme => ({
  subMenu: {
    display: 'none'
  },
  subMenuShown: {
    display: 'block',
    position: 'absolute'
  },
  subMenuContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  subMenuLabel: {
    color: 'white',
    fontWeight: 500,
    marginRight: 7,
    textDecoration: 'none',
    textTransform: 'initial',
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  menuButton: {
    padding: '20px 15px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.navHoverGray.main,
      boxShadow: `inset 0 -4px 0 ${theme.palette.navHoverLightGray.main} !important`
    }
  },
  icon: {
    color: 'white'
  },
  highlightItem: {
    backgroundColor: theme.palette.navHoverGray.main,
    boxShadow: `inset 0 -4px 0 ${theme.palette.navHoverLightGray.main} !important`
  },
  popover: {
    pointerEvents: 'none'
  },
  popoverContent: {
    pointerEvents: 'auto'
  },
  popoverClasses: {
    marginTop: 1,
    top: '65px !important',
    width: 'auto',
    backgroundColor: theme.palette.navDarkGray.main,
    border: `1px solid ${theme.palette.navDarkGray.main}`,
    color: theme.palette.borderGray.main,
    maxHeight: 'none !important',
    overflow: 'visible !important',
    borderRadius: 0
  },
  logoutIcon: {
    marginLeft: 15,
    marginRight: 5
  },
  noHover: {
    cursor: 'pointer',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'pointer'
    },
    borderBottom: `1px solid #fff`
  }
}))

export default function DesktopNav({ isOrgAdmin, session, isAuthed, headerNavItems }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)

  const userId = session ? session.user.id : null

  const headerClasses = useStyles()
  const classes = navStyles()

  const { organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const userEmail = session ? (session.email ? session.email : session.user.email) : null
  const userRoles = session ? session.user.roles : []
  const signUpStep = session ? session.user.signUpStep : null
  const { orgId, membershipStatus } = organizationUser

  const { data: { organization = {} } = {} } = useQuery(GET_ORGANIZATION_DATA, {
    variables: { id: orgId },
    skip: !orgId
  })

  const myAccountItems = getMyAccountLinks(
    userId,
    orgId,
    userRoles,
    membershipStatus,
    organization,
    signUpStep
  )

  const megaMenuLinkPadding = 8

  return (
    <>
      <a href="https://telecominfraproject.com/" target="_blank" rel="noopener noreferrer">
        <img src={logoImage} className={headerClasses.navLogoImage} alt="TIP logo" />
      </a>
      <div className={headerClasses.grow} />
      {headerNavItems.map(({ name, label, to, subItems, mobileOnly, hidden }) => {
        const isOpen = open === name
        if (hidden || mobileOnly) {
          return null
        }

        // if section contains megamenu, calc height of megamenu based on sumMenu with most items
        let menuMinHeight = megaMenuLinkPadding * 2 + 20 + 50 // 20 offset
        if (subItems) {
          const megaMenu = subItems.filter(item => item.megaMenu)
          if (megaMenu.length >= 1) {
            let megaMenuSubItemsLength = 0
            megaMenu.forEach(item => {
              item.subItems.forEach(item => {
                if (item.subItems) {
                  if (item.subItems.length > megaMenuSubItemsLength) {
                    megaMenuSubItemsLength = item.subItems.length
                  }
                }
              })
            })
            menuMinHeight = menuMinHeight + megaMenuSubItemsLength * (megaMenuLinkPadding * 2 + 20)
          }
        }

        return subItems ? (
          <div key={name}>
            <div
              aria-haspopup="true"
              className={clsx(classes.menuButton, isOpen ? classes.highlightItem : null)}
              onMouseEnter={e => {
                setAnchorEl(e.currentTarget)
                setOpen(name)
              }}
              onMouseLeave={e => setOpen(null)}
            >
              <div className={clsx(headerClasses.item, classes.subMenuContainer)}>
                {to ? (
                  <LinkField
                    forceExternal
                    hideBorder
                    hideIcon
                    className={classes.subMenuLabel}
                    label={label}
                    to={to}
                  />
                ) : (
                  <span className={classes.subMenuLabel}>{label}</span>
                )}
                <Icon fontSize="small">keyboard_arrow_down</Icon>
              </div>
            </div>
            <Menu
              PopoverClasses={{
                paper: clsx(classes.popoverClasses, classes.popoverContent),
                root: classes.popover
              }}
              MenuListProps={{
                style: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  minHeight: menuMinHeight
                }
              }}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={isOpen}
              onClose={() => {
                setOpen(null)
              }}
              PaperProps={{
                onMouseEnter: () => setOpen(name),
                onMouseLeave: () => setOpen(null)
              }}
            >
              <SubMenu
                items={subItems}
                nestedLevel={1}
                megaMenuLinkPadding={megaMenuLinkPadding}
                onClose={() => {
                  setOpen(null)
                }}
              />
            </Menu>
          </div>
        ) : name === 'account' ? (
          <IconButton component={Link} key={name} to="/login">
            <AccountCircleIcon className={classes.icon} />
          </IconButton>
        ) : (
          <div className={clsx(headerClasses.item, classes.menuButton)} key={name}>
            <LinkField hideBorder hideIcon className={classes.subMenuLabel} label={label} to={to} />
          </div>
        )
      })}
      {isAuthed && (
        <>
          <div
            aria-controls="menu-appbar"
            aria-haspopup="true"
            aria-label="User Account Menu"
            className={clsx(
              classes.menuButton,
              open === 'myAccount' ? classes.highlightItem : null
            )}
            onMouseEnter={e => {
              setAnchorEl(e.currentTarget)
              setOpen('myAccount')
            }}
            onMouseLeave={e => setOpen(null)}
          >
            <div className={clsx(headerClasses.item, classes.subMenuContainer)}>
              <AccountCircleIcon className={headerClasses.icon} />
              <span className={classes.subMenuLabel}>My Account</span>
            </div>
          </div>
          <Menu
            PopoverClasses={{
              paper: clsx(classes.popoverClasses, classes.popoverContent),
              root: classes.popover
            }}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            id="menu-appbar"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={open === 'myAccount'}
            onClose={() => {
              setOpen(null)
            }}
            PaperProps={{
              onMouseEnter: () => setOpen('myAccount'),
              onMouseLeave: () => setOpen(null)
            }}
          >
            <MenuItem className={classes.noHover}>{userEmail}</MenuItem>
            <Divider />
            <SubMenu
              openPopUpLeft
              items={myAccountItems}
              nestedLevel={1}
              megaMenuLinkPadding={megaMenuLinkPadding}
              onClose={() => setOpen(null)}
            />
            <MenuItem component={Link} to="/logout" className={headerClasses.item}>
              <InputIcon className={classes.logoutIcon} />
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  )
}
