import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

// import { imageZoom } from '../utils/imageHelper'
import MediaViewerModal from './MediaViewerModal'
import { getOfferingMedia } from '../utils/storageHelper'

const useStyles = isShorter =>
  makeStyles(theme => ({
    root: {
      position: 'relative',
      width: '100%',
      background: 'white',
      border: isShorter ? `thin solid ${theme.palette.gray4.main}` : 'none',
      [theme.breakpoints.down('sm')]: {
        minHeight: 200
      },
      '& .image-gallery-slide': {
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      },
      '& .image-gallery-thumbnail': {
        width: 80,
        height: 80,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `thin solid ${theme.palette.gray4.main}`
      },
      '& .image-gallery-thumbnails .image-gallery-thumbnails-container': {
        textAlign: 'left'
      },
      '& .image-gallery-swipe': {
        width: '100%'
      },
      '& .image-gallery-image': {
        width: isShorter ? '100%' : 450,
        height: isShorter ? 270 : 400,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          margin: '0 auto',
          width: 700,
          height: 395
        },
        [theme.breakpoints.down('xs')]: {
          width: 'inherit',
          height: 'inherit'
        }
      },

      '& .image-gallery-left-nav': {
        display: isShorter ? 'none' : 'block'
      },
      '& .image-gallery-right-nav': {
        display: isShorter ? 'none' : 'block'
      },
      '& .image-gallery-thumbnail-inner img': {
        width: 'auto',
        height: 'auto',
        maxWidth: 78,
        maxHeight: 78
      },
      '& .image-gallery-slide-wrapper': {
        minHeight: isShorter ? 270 : 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `thin solid ${theme.palette.gray4.main}`,
        '& button': {
          display: 'none'
        }
      },

      '& .image-gallery-image img': {
        maxWidth: isShorter ? '100%' : 450,
        maxHeight: isShorter ? 270 : 400,
        width: 'auto',
        margin: 'auto',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
          maxWidth: 'inherit',
          maxHeight: 'inherit',
          width: 'inherit',
          margin: 'inherit',
          height: 'inherit'
        }
      }

      // '& .img-zoom-lens': {
      //   position: 'absolute',
      //   border: '1px solid #d4d4d4',
      //   /*set the size of the lens:*/
      //   width: 40,
      //   height: 40,
      //   borderRadius: '50%'
      // }
    },
    imageViewer: {
      height: 400,
      width: 'calc(100% + 160px)',
      position: 'absolute',
      left: 'calc(100% + 24px)',
      top: 0,
      zIndex: 100,
      boxShadow: '0 2px 10px #d7d7d7'
    },
    vimeoWrapper: {
      paddingTop: 70,
      backgroundColor: 'black',
      [theme.breakpoints.down('md')]: {
        paddingTop: 0
      }
    },
    vimeoIframe: {
      width: 450,
      height: 253,
      [theme.breakpoints.down('sm')]: {
        width: 700,
        height: 395
      },
      [theme.breakpoints.down(724)]: {
        width: 580,
        height: 345
      },
      [theme.breakpoints.down('xs')]: {
        width: 500,
        height: 282
      },
      [theme.breakpoints.down(450)]: {
        width: 400,
        height: 252
      },
      [theme.breakpoints.down(400)]: {
        width: 300,
        height: 182
      }
    }
  }))

const renderVideo = item => () => {
  return (
    <div className="image-gallery-image">
      <div className="video-wrapper">
        <video controls>
          <source src={getOfferingMedia(item.original)} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

// let mediaZoomListeners

function MediaGallery({ media, isShorter = false }) {
  const classes = useStyles(isShorter)()
  const [imgElement, setImageElement] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [currentMedia, setCurrentMedia] = useState(media[0])
  const viewerRef = useRef(null)

  const renderVimeo = item => () => {
    return (
      <div className="image-gallery-image">
        <div className={classes.vimeoWrapper}>
          <iframe
            src={item.original}
            className={classes.vimeoIframe}
            title={item.original}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      </div>
    )
  }

  const renderYouTube = item => () => {
    return (
      <div className="image-gallery-image">
        <div className={classes.vimeoWrapper}>
          <iframe
            src={item.original}
            className={classes.vimeoIframe}
            title={item.original}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      </div>
    )
  }
  // useEffect(() => {
  //   if (imgElement) {
  //     // this may be video, in which case we don't want to zoom
  //     mediaZoomListeners = imageZoom(imgElement, viewerRef.current)
  //   } else if (mediaZoomListeners) {
  //     mediaZoomListeners.destroy()
  //   }

  //   return () => {
  //     if (mediaZoomListeners) mediaZoomListeners.destroy()
  //   }
  // }, [imgElement])

  const nextMedia = media.map(item => {
    switch (item.type) {
      case 'video':
        item.renderItem = renderVideo(item)
        break
      case 'vimeo':
        item.renderItem = renderVimeo(item)
        break
      case 'youtube':
        item.renderItem = renderYouTube(item)
        break
      default:
        break
    }
    return item
  })

  return (
    <div className={classes.root}>
      <ImageGallery
        items={nextMedia}
        showPlayButton={false}
        // onMouseLeave={() => setImageElement(null)}
        // onMouseOver={e => {
        //   // Need to make sure that only img elements are triggering the image viewer
        //   if (e.target.tagName === 'IMG') {
        //     setImageElement(e.target)
        //   }
        // }}
        onSlide={index => {
          setImageElement(null)
          setCurrentMedia(media[index])
        }}
        onClick={() => setModalVisible(true)}
        onScreenChange={() => setImageElement(null)}
        showFullscreenButton={false}
        thumbnailClass={classes.thumbNail}
      />
      {imgElement && <div className={classes.imageViewer} ref={viewerRef} />}
      <MediaViewerModal
        {...currentMedia}
        open={!!modalVisible}
        handleClose={() => setModalVisible(null)}
      />
    </div>
  )
}

export default MediaGallery
