import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useQuery } from '../hooks'
import { Avatar } from '@material-ui/core'

import WidthContainer from '../common/WidthContainer'
import { GET_ORGANIZATION } from './queries'
import OrganizationSummary from '../Organization/OrganizationSummary'
import { getImageUrl } from '../utils'
import { themeStyles } from '../theme'
import CompanyImg from '../assets/images/organization-logo-ph.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  pagePanel: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  h1: {
    fontSize: 34,
    marginBottom: 16
  },
  label: {
    marginRight: 6
  },
  description: {
    marginBottom: 16
  },
  profileBody: {},
  profileSummary: {
    padding: '0 0 62px 0',
    maxWidth: theme.sizes.desktop.pageWidth,
    margin: '0 auto'
  },
  orgLogo: {
    width: '100%',
    marginBottom: 32
  },
  summaryContainer: {
    paddingLeft: 32
  },
  vendorTechHeading: {
    fontSize: 30,
    marginBottom: 28
  },
  avatar: {
    borderRadius: 0,
    width: 250,
    height: 250
  },
  bigAvatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bigAvatar: {
    borderRadius: 0,
    height: 350,
    width: 350,
    [theme.breakpoints.down('xs')]: {
      width: 280,
      height: 280,
      marginBottom: 20
    }
  },
  avatarImg: {
    height: 'auto',
    width: '100%'
  }
}))

const OrganizationProfilePage = ({ match }) => {
  const variables = { variables: { id: match.params.organizationId } }
  const classes = useStyles()
  const themeClasses = themeStyles()
  const { data } = useQuery(GET_ORGANIZATION, variables)
  const organization = (data && data.organization) || {}

  const { logo } = organization
  return (
    <WidthContainer className={themeClasses.pageContentMargin}>
      <Grid container className={classes.profileSummary}>
        <Grid item md={5} xs={12}>
          {logo ? (
            <div className={classes.bigAvatarContainer}>
              <Avatar
                alt="Solution Provider Logo"
                src={getImageUrl(`hub/${logo}`)}
                className={classes.bigAvatar}
                imgProps={{ className: classes.avatarImg }}
              />
            </div>
          ) : (
            <div className={classes.bigAvatarContainer}>
              <Avatar alt="Solution Provider Logo" src={CompanyImg} className={classes.avatar} />
            </div>
          )}
        </Grid>
        <Grid item md={7} xs={12} className={classes.summaryContainer}>
          <OrganizationSummary {...organization} />
        </Grid>
      </Grid>
    </WidthContainer>
  )
}

export default OrganizationProfilePage
