export const isFormSubmitDisabled = (inputs, formState, isEditing) => {
  const requiredFields = Object.entries(inputs).flatMap(([name, args]) =>
    args.validator.required ? [name] : []
  )

  const touchedRequiredFields = requiredFields.every(item => {
    return formState.touched[item]
  })

  const errors = Object.keys(formState.errors).some(e => {
    return formState.errors[e] // note: both true and undefined represent a valid input
  })

  const disableSubmit = isEditing ? errors > 0 : !touchedRequiredFields || errors > 0

  return disableSubmit
}

/*
******* International phone validation requirements *******
(i) allows for valid international prefixes
(ii) followed by 9 or 10 digits, with any type or placing of delimeters (except between the last two digits)

This will match:
+1-234-567-8901
+61-234-567-89-01
+46-234 5678901
+1 (234) 56 89 901
+1 (234) 56-89 901
+46.234.567.8901
+1/234/567/8901
*/

/* also 
4254780111
*/

export const inputRegexes = {
  address: /^\d+\w*\s*(?:[\\/]?\s*)?\d*\s*\d+\/?\s*\d*\s*/,
  phone: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}|^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  email: /[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[A-Za-z]{2,3}/,
  url: /https?:\/\/.+/,
  password: /(?=.*[A-Za-z])(?=.*\d)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}/
}
