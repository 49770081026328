// The Lx -> Chromatic Magnetic Systematic

import React from 'react'
import Progress from '../common/Progress'
import ReactMarkdown from 'react-markdown'
import { Typography } from '@material-ui/core'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import LinkField from '../common/LinkField'

const GET_PAGE_CONTENT = gql`
  query($name: String!) {
    pageContentByName(name: $name) {
      name
      tags
      sections {
        type
        name
        content
        jsonContent
      }
    }
  }
`

const markdownRenderers = {
  // add as needed here: https://www.npmjs.com/package/react-markdown#appendix-b-node-types
  // we are on v5 so... https://www.npmjs.com/package/react-markdown/v/5.0.3
  paragraph: ({ children }) => <Typography paragraph>{children}</Typography>,
  link: props => (
    <LinkField
      to={props.href}
      label={props.children}
      hideBorder={false}
      style={{ display: 'inline-block', lineHeight: 'normal' }}
    />
  ),
  emphasis: ({ node, ...props }) => <span style={{ fontSize: 12 }} {...props} />,
  heading: ({ node, ...props }) => {
    switch (props.level) {
      case 2:
        return <Typography style={{ fontWeight: 300, marginBottom: 22, fontSize: 36 }} {...props} />
      case 5:
        return (
          <Typography style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 14 }} {...props} />
        )
      case 6:
        return <span style={{ fontSize: 12 }} {...props} />
      default:
        return <Typography style={{ marginBottom: 0, fontWeight: 'bold' }} {...props} />
    }
  },
  list: ({ children, ...props }) => {
    if (props.ordered === true) {
      return <ol style={{ paddingInlineStart: 28 }} {...props} />
    } else {
      return <ul style={{ paddingInlineStart: 28 }}>{children}</ul>
    }
  },
  listItem: ({ children, ...props }) => (
    <li style={{ marginBottom: 12, paddingLeft: 8 }}>{children}</li>
  )
}

/*
 *  usePageContent() and getSection() are used primarily with GET_PAGE_CONTENT to retrieve a page's content
 *  See 'src/TSS/Solutions/UltraRural.js' for an example
 */

function usePageContent(name) {
  const { loading, data, error } = useQuery(GET_PAGE_CONTENT, { variables: { name: name } })
  return {
    loading,
    error,
    data: data && data.pageContentByName.sections ? data.pageContentByName.sections : data
  }
}

const getSection = (sections = [], name, component) => {
  const section = sections.find(sections => sections.name === name)
  let content = null

  // set content based on type (string, json, markdown)
  if (section) {
    if (section.type !== 'json' && section.jsonContent) {
      content = `Please set section.type to 'json' in db.`
    } else if (section.type === 'json' && section.jsonContent) {
      content = JSON.parse(section.jsonContent)
    } else if (section.type === 'markdown') {
      // new lines are declared with \n
      content = (
        <ReactMarkdown renderers={markdownRenderers} style={{ whiteSpace: 'pre-wrap' }}>
          {section.content}
        </ReactMarkdown>
      )
    } else {
      content = section.content
    }
  } else {
    content = <Progress />
  }

  // return content with/or without custom parent component
  if (section && component) {
    return <component.type {...component.props} children={content} />
  } else {
    return content
  }
}

/*
 *   renderMarkdown() can be used anywhere to render markdown with TIP components and styling
 *   see 'src/Rfi/DetailView.js' for an example
 */

const renderMarkdown = content => {
  return (
    <ReactMarkdown renderers={markdownRenderers} style={{ whiteSpace: 'pre-wrap' }}>
      {content}
    </ReactMarkdown>
  )
}

export { usePageContent, getSection, renderMarkdown }
