import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_BADGES = gql`
  query {
    badges {
      id
      name
      description
      iconFile
      badgeFile
      legacy
    }
  }
`

function useBadges(parameters) {
  const { loading, error, data } = useQuery(GET_BADGES, parameters)
  return {
    loading,
    error,
    badges: data && data.badges
  }
}

export { GET_BADGES, useBadges }
