import gql from 'graphql-tag'

const SEND_ASSET_EMAIL = gql`
  mutation($input: SendAssetEmailInput!) {
    sendAssetEmail(input: $input) {
      result
    }
  }
`

export { SEND_ASSET_EMAIL }
