import gql from 'graphql-tag'

export const GET_MARKETPLACE_FILTERS = gql`
  query {
    marketplaceFilters {
      types {
        id
        name
      }
      categories {
        id
        name
      }
      projectGroups {
        id
        name
        status
      }
      usedProjectGroups {
        id
        name
        status
      }
      badges {
        id
        name
        order
        legacy
      }
      tags {
        id
        name
      }
    }
  }
`
