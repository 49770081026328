import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

import WidthContainer from '../common/WidthContainer'
import HeroCard from './HeroCard'
import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    background: `url(${
      config.MEDIA_CDN_DOMAIN
    }/media/Home-Bg.svg) center left no-repeat rgb(217, 217, 217)`,
    display: 'flex',
    backgroundSize: 'cover',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '45px 0 0 0'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      background: `url(${
        config.MEDIA_CDN_DOMAIN
      }/media/Home-Bg-mobile.svg) bottom left no-repeat #e9e9e9`,
      backgroundSize: '1500px',
      backgroundPosition: '-470px 200px',
      minHeight: 600,
      width: '100%',
      overflow: 'hidden'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '1200px',
      width: '100%',
      backgroundPosition: '-500px 200px',
      minHeight: 550
    }
  },
  container: {
    padding: '84px 0 48px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxHeight: '80vh',
      padding: 0,
      width: '100%'
    }
  },
  heroOverlay: {
    textAlign: 'left',
    padding: 30,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
      padding: 0,
      margin: '0 auto',
      marginTop: 0,
      marginRight: 30,
      fontSize: '80px',
      width: '75%'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 25,
      marginTop: 0,
      width: '95%'
    }
  },
  heroCards: {
    padding: '30px 0',
    marginTop: -78,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      padding: 0
    }
  },
  gridItem: {
    padding: '20px 30px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
      marginBottom: 2
    }
  },
  gridItemLeft: {
    paddingLeft: '20px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important'
    }
  },
  gridItemRight: {
    paddingRight: '20px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important'
    }
  },
  mapGrid: {
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      margin: '0 auto',
      marginTop: 10,
      width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '230px',
      margin: '0 auto',
      marginTop: 10,
      width: '85%'
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -80
    }
  }
}))

function Hero(props) {
  const classes = useStyles()
  const { sections } = props

  return (
    <>
      <div className={classes.root}>
        <WidthContainer fullWidth={true} className={classes.columnWrapper}>
          <Grid container spacing={0} className={classes.container}>
            <Grid item md={7} sm={12} className={classes.mapGrid}>
              <img src={`${config.MEDIA_CDN_DOMAIN}/media/home-map.svg`} alt="city" />
            </Grid>
            <Grid item md={5} sm={12} className={classes.heroOverlay}>
              {sections.heroTitle}
              {sections.heroTopText}
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
      <div className={classes.heroCards}>
        <WidthContainer>
          <Grid container spacing={5}>
            <Grid item md={6} xs={12} className={clsx(classes.gridItem, classes.gridItemLeft)}>
              <HeroCard
                subTitle={sections.heroCard1Title}
                body={sections.heroCard1Text}
                buttonText={sections.heroCard1ButtonLabel}
                buttonLink={sections.heroCard1ButtonLink}
                iteration={1}
              />
            </Grid>
            <Grid item md={6} xs={12} className={clsx(classes.gridItem, classes.gridItemRight)}>
              <HeroCard
                subTitle={sections.heroCard2Title}
                body={sections.heroCard2Text}
                buttonText={sections.heroCard2ButtonLabel}
                buttonLink={sections.heroCard2ButtonLink}
                iteration={2}
              />
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
    </>
  )
}

export default Hero
