import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import LinkField from './LinkField'

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    fontWeight: 'bold'
  }
}))

function TIPPolicyCheckboxLabel() {
  const classes = makeStyles(useStyles)
  return (
    <>
      <Typography className={classes.checkboxLabel} variant="subtitle2">
        I understand and agree to the TIP{' '}
        <LinkField
          className={classes.linkField}
          label="Privacy Policy"
          hideIcon={true}
          to="https://telecominfraproject.com/legal-and-privacy-policies/"
          target="_blank"
          rel="noopener noreferrer"
        />
        .
      </Typography>
    </>
  )
}

export default TIPPolicyCheckboxLabel
