import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import config from '../config'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
    paddingTop: 60
  },
  title: {
    fontSize: 60,
    marginBottom: 15,
    color: theme.palette.gray11.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40
    }
  },
  subTitle: {
    fontSize: 21,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17
    }
  },
  message: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  tipLogo: {
    marginBottom: 45,
    width: 220
  },
  cornerGraphic: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 400,
    zIndex: '-1'
  },
  divider: {
    width: 275,
    marginBottom: 50
  }
}))

const MaintenancePage = () => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <img
        alt="TIP Logo"
        className={classes.tipLogo}
        src={`${config.MEDIA_CDN_DOMAIN}/media/TIP-Logo-Stack@2x.png`}
      />
      <Typography className={classes.title}>Maintenance</Typography>
      <Typography className={classes.subTitle}>
        Our website is undergoing scheduled maintenance
      </Typography>
      <hr color="#D2D2D2" className={classes.divider} />
      <Typography className={classes.message}>Please check back soon</Typography>
      <img
        alt="TIP Logo"
        className={classes.cornerGraphic}
        src={`${config.MEDIA_CDN_DOMAIN}/media/Corner-BG-graphic@2x.png`}
      />
    </div>
  )
}

export default MaintenancePage
