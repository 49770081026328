import gql from 'graphql-tag'

export const GET_OFFERING = gql`
  query Offering($id: ID!, $isAuthenticated: Boolean!) {
    offering(id: $id) {
      id
      name
      shortDescription
      longDescription
      ocpCertified
      githubLink
      techSpecName
      searchTags
      videoUrls
      provider {
        id
        logo
        name
      }
      projectGroups {
        name
        detailLink
        status
      }
      badges {
        name
        description
        iconFile
        badgeFile
        legacy
      }
      badgeRecords {
        id
        badge {
          name
          badgeFile
        }
        pgName
        pgLink
        dateAwardedMonth
        dateAwardedYear
        testingEvent
        documents {
          docType
          title
          link
          accessTier
        }
      }
      categories {
        name
      }
      tags {
        id
        name
        iconFile
      }
      media {
        imageName
        thumbName
        type
        defaultImage
      }
      specifications
      faqs @include(if: $isAuthenticated)
      relatedOfferings {
        id
        name
        media {
          imageName
          defaultImage
        }
        badges {
          id
          name
          description
          iconFile
          badgeFile
        }
        provider {
          id
          name
        }
      }
    }
  }
`

export const GET_OFFERINGS_WITH_PREVIEWS = gql`
  query OfferingsWithPreviews {
    offerings(includePreviews: true) {
      id
      name
      shortDescription
      type
      previewOnMarketplace
      showOnMarketplace
      searchTags
      provider {
        id
        name
        logo
      }
      media {
        imageName
        thumbName
        type
        defaultImage
      }
      projectGroups {
        id
        name
        detailLink
      }
      badges {
        id
        name
        description
        iconFile
        badgeFile
      }
      tags {
        id
        name
        iconFile
      }
      categories {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_OFFERINGS = gql`
  query Offerings {
    offerings {
      id
      name
      shortDescription
      type
      searchTags
      provider {
        id
        name
        logo
      }
      media {
        imageName
        thumbName
        type
        defaultImage
      }
      projectGroups {
        id
        name
        detailLink
      }
      badges {
        id
        name
        description
        iconFile
        badgeFile
      }
      tags {
        id
        name
        iconFile
      }
      categories {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_FEATURE_OFFERINGS = gql`
  query FeatureOfferings {
    featureOfferings {
      id
      name
      type
      featured
      searchTags
      provider {
        id
        name
        logo
      }
      media {
        imageName
        thumbName
        type
        defaultImage
      }
      badges {
        id
        name
        description
        iconFile
        badgeFile
        order
        iconOrder
      }
      createdAt
      updatedAt
    }
  }
`

export const OFFERING_REQUEST_REASONS = gql`
  query OfferingRequestReasons {
    offeringRequestReasons {
      id
      title
    }
  }
`
