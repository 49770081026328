import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import { Link } from 'react-router-dom'

import LinkField from '../common/LinkField'
import { getImageUrl } from '../utils/imageHelper'

const useStyles = makeStyles(theme => ({
  root: {
    '& .link': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  h1: {
    fontSize: 34,
    marginBottom: 16
  },
  label: {
    marginRight: 6
  },
  profile: {
    marginBottom: 32
  },
  organizationOfferingsTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 24
  },
  primaryAction: {
    marginBottom: 42,
    textDecoration: 'none'
  },
  badges: {
    display: 'flex',
    marginBottom: 28
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    marginBottom: 24
  },
  badgeName: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  badgeIcon: {
    height: 40,
    width: 40,
    marginRight: 6
  },
  projectGroups: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  group: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 6,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 6,
    backgroundColor: theme.palette.gray3.main
  },
  classifications: {
    display: 'flex',
    marginTop: 14
  },
  categories: {
    display: 'flex',
    padding: '0 12px 0 0'
  },
  category: {},
  tags: {
    display: 'flex',
    borderLeft: `1px solid ${theme.palette.gray4.main}`,
    padding: '0 12px 0 12px'
  },
  tag: {}
}))

function OrganizationSummary({
  id = '',
  name = '',
  profile = '',
  badges = [],
  projectGroups = [],
  categories = [],
  tags = [],
  description = ''
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.h1} role="heading" variant="h1">
        {name}
      </Typography>
      <Typography className={classes.profile}>{profile}</Typography>
      <Typography paragraph>{description}</Typography>
      <Typography className={classes.organizationOfferingsTitle}>
        View offerings by this solution provider in the Marketplace.
      </Typography>
      <Link to={`/marketplace?solutionProviders=${name}`} className={classes.primaryAction}>
        <Button color="primary" variant="contained">
          Solution Provider Offerings
        </Button>
      </Link>
      <div className={classes.badges}>
        {badges.map((badge, index) => (
          <div className={classes.badge} key={`${badge.name}_${index}`}>
            <img className={classes.badgeIcon} src={getImageUrl('media/badges/badge.png')} alt="" />
            <Typography className={classes.badgeName}>{badge.name} </Typography>
          </div>
        ))}
      </div>
      {!!projectGroups.length && (
        <div className={classes.projectGroups}>
          <Typography className={classes.label} variant="body2">
            Project Group:
          </Typography>
          {projectGroups.map((group, index) => (
            <React.Fragment key={`${group.name}_${index}`}>
              <LinkField
                className={`${classes.group} link`}
                to={`/marketplace?projectGroups=${group.name}`}
                label={group.name}
              />
            </React.Fragment>
          ))}
        </div>
      )}
      <Divider />
      <div className={classes.classifications}>
        {!!categories.length && (
          <div className={classes.categories}>
            <Typography className={classes.label} variant="body2">
              Category:
            </Typography>
            {categories.map((category, index) => (
              <React.Fragment key={`${category.name}_${index}`}>
                <LinkField
                  className={`${classes.category} link`}
                  to={`/marketplace?categories=${category.name}`}
                  label={category.name}
                />
                {categories.length > index + 1 ? ',\u00A0' : ''}
              </React.Fragment>
            ))}
          </div>
        )}
        {!!tags.length && (
          <div className={classes.tags}>
            <Typography className={classes.label} variant="body2">
              Tags:
            </Typography>
            {tags.map((tag, index) => (
              <React.Fragment key={`${tag.name}_${index}`}>
                <LinkField
                  key={`${tag.name}_${index}`}
                  className={`${classes.tag} link`}
                  to={`/marketplace?tags=${tag.name}`}
                  label={`${tag.name} `}
                />
                {tags.length > index + 1 ? ',\u00A0' : ''}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrganizationSummary
