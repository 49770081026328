import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& .MuiSelect-icon': {
      right: 8
    },
    width: '100%',
    padding: '13px 0px'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '18.5px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.placeholderGray.main
  }
}))

const SelectField = ({
  inputType,
  name,
  touchOnChange,
  validateOnBlur,
  label,
  placeholder,
  validator,
  options,
  required,
  ...rest
}) => {
  const classes = useStyles()

  function validate(value, values, event) {
    if (validator) {
      const { required } = validator

      if (required && !value) {
        return `${name} is required`
      }
    }
  }

  return (
    <Select
      {...inputType({
        name: name,
        validate: validate,
        validateOnBlur: validateOnBlur === false ? false : true, // defaults to true
        touchOnChange: touchOnChange === false ? false : true // defaults to true
      })}
      placeholder={label}
      variant="filled"
      displayEmpty
      input={<CustomInput name={name} />}
      {...rest}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          <span className={classes.disabledText}>{placeholder}</span>
        </MenuItem>
      )}
      {options &&
        options.map((option, index) => (
          <MenuItem key={`${option.value}_${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )
}

export default SelectField
