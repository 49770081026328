import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { ReCaptcha } from 'react-recaptcha-v3'

import { useFormStyles } from '../hooks'
import WidthContainer from '../common/WidthContainer'
import Progress from '../common/Progress'
import GetStarted from './GetStarted'
import CheckYourInbox from './CheckYourInbox'
import EmailResent from './EmailResent'
import { themeStyles } from '../theme'
import config from '../config'

export default function SignUp({ onClickSignIn }) {
  const [verifyCaptcha, setVerifyCaptcha] = useState(false)
  const [email, setEmail] = useState()
  const [emailResent, setEmailResent] = useState()
  const formClasses = useFormStyles()
  const themeClasses = themeStyles()
  const classes = useStyles()

  let renderSection = 'get-started'

  if (email) {
    renderSection = 'check-your-inbox'
  }

  if (emailResent) {
    renderSection = 'email-resent'
  }

  function resetState() {
    setEmailResent(false)
    setEmail()
  }

  function verifyCallback(recaptchaToken) {
    setVerifyCaptcha(true)
  }

  if (!verifyCaptcha) {
    return (
      <div>
        <ReCaptcha
          sitekey={config.RECAPTCHA_PUBKEY}
          action="action_name"
          verifyCallback={verifyCallback}
        />
        <Progress size={30} delay={2} />
      </div>
    )
  } else {
    return (
      <WidthContainer className={themeClasses.pageContentMargin}>
        <Grid justify="center" container>
          <Grid center item xs={12} sm={12} md={8} lg={8} className={formClasses.container}>
            {renderSection === 'get-started' && (
              <GetStarted setEmail={email => setEmail(email)} onClickSignIn={onClickSignIn} />
            )}
            {renderSection === 'check-your-inbox' && (
              <CheckYourInbox
                email={email}
                setEmail={() => setEmail()}
                setEmailResent={() => setEmailResent(true)}
              />
            )}
            {renderSection === 'email-resent' && <EmailResent resetState={resetState} />}
            <br />
            <Typography className={classes.recaptchaRemark}>
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy" className={classes.recaptchaLink}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms" className={classes.recaptchaLink}>
                Terms of Service
              </a>{' '}
              apply.
            </Typography>
          </Grid>{' '}
        </Grid>
      </WidthContainer>
    )
  }
}

const useStyles = makeStyles(theme => ({
  recaptchaRemark: {
    fontSize: '12px',
    alignItems: 'center'
  },
  recaptchaLink: {
    color: '#737373'
  }
}))
