import React from 'react'
import { makeStyles } from '@material-ui/styles'

import PageTitleHead from '../common/PageTitleHead'

const useStyles = makeStyles(theme => ({
  reWrap: {
    width: 1080,
    margin: '0 auto',
    marginTop: 48,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '0 8px'
    }
  }
}))

const ListPageView = props => {
  const classes = useStyles()
  const { sections } = props
  return (
    <>
      <PageTitleHead
        title={sections.pageTitle}
        description={`TIP's RFI templates and resources are available to all TIP participants`}
      />
      <div className={classes.reWrap}>{sections.pageDescription}</div>
    </>
  )
}

export default ListPageView
