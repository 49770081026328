import React, { useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import GraduatedIcon from '../assets/images/graduated_85x32.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      width: '100%'
    }
  },
  inputLabel: {
    fontSize: 13
  },
  select: {
    width: 125,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  formControlContainer: {
    flex: 1
  },
  graduatedIcon: {
    marginLeft: 10
  }
}))

const CheckGraduationIcon = (option, filter, classes) => {
  if (
    filter.label === 'Project Groups' &&
    option.status.toLowerCase() === 'inactive' &&
    filter.value !== option.value
  ) {
    return <img className={classes.graduatedIcon} src={GraduatedIcon} alt="" />
  } else return ''
}

const menuStateInit = count => {
  const arr = []

  for (let i = 0; i < count; i++) {
    arr.push(false)
  }

  return arr
}

const MarketplaceFilters = ({ filters, onChange }) => {
  const classes = useStyles()
  const [menuState, menuDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'UPDATE':
          const newState = [...state]

          newState[action.index] = action.isOpen

          return newState
        case 'RESET':
          return menuStateInit(action.count)
        default:
          throw new Error('Dispatch action not recognized')
      }
    },
    0,
    menuStateInit
  )

  useEffect(() => {
    if (filters.length !== menuState.length) {
      menuDispatch({ count: filters.length, type: 'RESET' })
    }
  }, [filters, menuState])

  return (
    <div className={classes.root}>
      {filters.map(
        (filter, index) =>
          !filter.hidden && (
            <div className={classes.formControlContainer} key={`${filter.label}_${index}`}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor={filter.type} className={classes.inputLabel}>
                  {filter.label}
                </InputLabel>
                <Select
                  className={classes.select}
                  multiple={Array.isArray(filter.value) ? true : false}
                  open={menuState[index] || false}
                  renderValue={value => {
                    if (Array.isArray(value)) {
                      return value.reduce((prev, curr) => {
                        let newValue = prev

                        if (newValue !== '') {
                          newValue += ', '
                        }

                        return newValue + curr
                      }, '')
                    } else {
                      return filter.options.find(o => o.value === value).label
                    }
                  }}
                  value={filter.value}
                  onChange={e => {
                    if (
                      Array.isArray(filter.value) &&
                      (e.target.value.length === 0 ||
                        e.target.value.length === filter.options.length)
                    ) {
                      menuDispatch({ index, isOpen: false, type: 'UPDATE' })
                    }

                    onChange(filter.type, e.target.value)
                  }}
                  onClose={() => menuDispatch({ index, isOpen: false, type: 'UPDATE' })}
                  onOpen={() => menuDispatch({ index, isOpen: true, type: 'UPDATE' })}
                >
                  {filter.options &&
                    filter.options.map((option, index) => (
                      <MenuItem key={`${option.label}_${index}`} value={option.value}>
                        {option.label}
                        {CheckGraduationIcon(option, filter, classes)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )
      )}
    </div>
  )
}

export default MarketplaceFilters
