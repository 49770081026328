import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F1F8F9',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '2px 2px 6px 1px rgba(0, 0, 0, 0.1)'
  },
  headBar: {
    background: '#a4cf4b',
    color: 'white',
    padding: '8px 30px'
  },
  headBarBlue: {
    background: '#46a1ae',
    color: 'white',
    padding: '8px 30px'
  },
  copy: {
    flexGrow: '1',
    padding: '24px 30px 42px',
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    width: 'fit-content',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  buttonGreen: {
    width: 'fit-content',
    marginTop: 'auto',
    background: '#358d2a',
    [theme.breakpoints.down('sm')]: {
      marginTop: 16
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}))

function HeroCard(props) {
  const classes = useStyles()
  const {
    title,
    subTitle,
    body,
    buttonText,
    buttonLink,
    iteration,
    children,
    buttonComponent,
    buttonClasses,
    buttonOnClick,
    ...otherProps
  } = props
  let content = null

  const linkString = `${buttonLink}`

  const internalLink =
    !linkString.startsWith('http') || linkString.includes('telecominfraproject.com') ? true : false

  if (children) {
    content = children
  } else {
    content = (
      <Fragment>
        <div className={iteration === 1 ? classes.headBar : classes.headBarBlue}>
          <Typography variant="body2">{title}</Typography>
          {subTitle}
        </div>
        <div className={classes.copy}>
          {body}
          <Button
            variant="contained"
            color="primary"
            className={clsx(iteration === 1 ? classes.buttonGreen : classes.button, buttonClasses)}
            onClick={e => {
              e.preventDefault()
              if (internalLink) {
                window.location = buttonLink
              } else {
                window.open(buttonLink)
              }
            }}
            {...otherProps}
          >
            {buttonText}
          </Button>
        </div>
      </Fragment>
    )
  }
  return <div className={classes.root}>{content}</div>
}

export default HeroCard
