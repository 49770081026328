import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  textField: {
    minHeight: 73,
    height: 'auto'
  }
})

const InputTextField = ({
  inputType,
  name,
  touchOnChange,
  validateOnBlur,
  label,
  validator,
  gridWidth,
  icon,
  ...rest
}) => {
  const classes = useStyles()

  function validate(value, values, event) {
    if (validator) {
      const {
        required,
        minLength,
        maxLength,
        regex,
        regexMessage,
        mustMatchKey,
        mustMatchMessage,
        caseInsensitive
      } = validator

      if ((required && !value) || (!value && values[mustMatchKey])) {
        return `${label} is required`
      } else if (value === '') {
        return true // if not required and is empty don't do rest of validation
      }
      if (value.length < minLength) {
        return `${label} must be at least ${minLength} characters`
      }
      if (value.length > maxLength) {
        return `${label} must be shorter than ${maxLength} characters`
      }
      if (regex && !regex.test(value)) {
        return regexMessage
      }
      if (value !== values[mustMatchKey]) {
        if (caseInsensitive) {
          if (
            value &&
            values[mustMatchKey] &&
            value.toUpperCase() !== values[mustMatchKey].toUpperCase()
          ) {
            return mustMatchMessage
          }
        } else {
          return mustMatchMessage
        }
      }
    }
  }

  return (
    <div className={classes.textField}>
      <TextField
        {...inputType({
          name: name,
          validate: validate,
          validateOnBlur: validateOnBlur === false ? false : true, // defaults to true
          touchOnChange: touchOnChange === false ? false : true // defaults to true
        })}
        label={label}
        placeholder={label}
        name={name}
        margin="normal"
        fullWidth
        color="secondary"
        {...rest}
      />
    </div>
  )
}

export default InputTextField
