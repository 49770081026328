import React from 'react'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Interweave from 'interweave'

import LinkField from '../common/LinkField'
import { getOfferingMedia } from '../utils/storageHelper'
import { getImageUrl } from '../utils/imageHelper'
import CompanyImg from '../assets/images/organization-logo-ph.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 22,
    background: 'white',
    border: `1px solid ${theme.palette.borderGray.main}`,
    borderBottom: 'none',
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.borderGray.main}`
    },
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  offeringImgContainer: {
    display: 'flex',
    width: 150,
    height: 150,
    border: `1px solid ${theme.palette.borderGray.main}`,
    alignItems: 'center',
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 20
    }
  },
  offeringImg: {
    display: 'block',
    maxWidth: 148,
    maxHeight: 148,
    width: 'auto',
    height: 'auto',
    margin: 'auto'
  },
  innerImgContainer: {
    display: 'flex',
    width: 148,
    height: 148
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginBottom: 12
  },
  preview: {
    border: `thin dashed #d2cbcb`,
    backgroundColor: 'whitesmoke'
  }
}))

const MarketplaceCard = ({
  id,
  name,
  showOnMarketplace,
  previewOnMarketplace = false,
  shortDescription,
  media,
  provider,
  history
}) => {
  const classes = useStyles()

  const trueAdminPreview = !showOnMarketplace && previewOnMarketplace

  const imgSrc =
    media && media.length > 0 && media[0].imageName
      ? getOfferingMedia(media[0].imageName)
      : provider.logo
      ? getImageUrl(`hub/${provider.logo}`)
      : CompanyImg
  return (
    <div
      className={clsx(classes.root, { [classes.preview]: trueAdminPreview })}
      onClick={e => history.push(`/marketplace/offerings/${id}`)}
    >
      <div className={classes.offeringImgContainer}>
        <div className={classes.innerImgContainer}>
          <img src={imgSrc} className={classes.offeringImg} alt="" />
        </div>
      </div>
      <div>
        <Typography variant="h2" className={classes.title}>
          {name}
        </Typography>
        <Typography paragraph component="div">
          <Interweave content={shortDescription} />
        </Typography>
        {provider && (
          <Typography>
            Provided by{' '}
            <LinkField to={`/solution-providers/${provider.id}`} label={provider.name} />
          </Typography>
        )}
      </div>
    </div>
  )
}

export default withRouter(MarketplaceCard)
