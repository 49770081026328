import React from 'react'
import Typography from '@material-ui/core/Typography'
import LinkField from './LinkField'
import { CONTACT_SUPPORT_URL } from '../utils/constants'

const ErrorSection = ({ includeSupport, children }) => {
  return (
    <div>
      <Typography role="alert" color="error">
        {children}
      </Typography>
      {includeSupport && (
        <>
          Questions?{' '}
          <LinkField
            color="primary"
            label="Contact TIP Support"
            to={CONTACT_SUPPORT_URL}
            hideIcon={true}
            target="_blank"
            rel="noopener noreferrer"
          />
        </>
      )}
    </div>
  )
}

export default ErrorSection
