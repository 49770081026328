import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import WidthContainer from '../common/WidthContainer'
import LinkField from '../common/LinkField'
import { useBadges } from '../utils/badgeHelper'
import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    background: `url(${config.MEDIA_CDN_DOMAIN}/media/Page-Bottom.svg) bottom left no-repeat ${
      theme.palette.lightBlue.main
    }`,
    backgroundSize: '100%',
    marginTop: 50,
    padding: '50px 0'
  },
  containerHeading: {
    textAlign: 'center',
    marginBottom: 35,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 65
    }
  },
  badges: {
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px'
    }
  },
  badge: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px !important'
    }
  },
  badgeIcon: {
    width: 75,
    height: 'auto',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    }
  },
  badgeTitle: {
    fontSize: '.8rem',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 5
  },
  badgesubTitle: {
    fontSize: '0.7rem'
  },
  badgesJoin: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonTitle: {
    fontSize: '.8rem',
    fontWeight: 800,
    marginBottom: 35
  }
}))

function Badges() {
  const classes = useStyles()
  const { badges = [] } = useBadges()

  return (
    <div className={classes.root}>
      <WidthContainer>
        <Typography variant="h5" gutterBottom className={classes.containerHeading}>
          TIP Exchange Badges &amp; Ribbons
        </Typography>
        <Grid container spacing={10} className={classes.badges}>
          {Object.entries(badges).map(([index, badge]) => {
            if (!badge.legacy) {
              return (
                <Grid key={index} item md sm={4} xs={4} className={classes.badge}>
                  <a href="/about-exchange/badges">
                    <img
                      className={classes.badgeIcon}
                      src={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.badgeFile}`}
                      alt="PlugFest badge"
                    />
                  </a>
                  <div>
                    <LinkField to={`/about-exchange/badges`} label={badge.name} hideIcon={true} />
                  </div>
                </Grid>
              )
            } else {
              return null
            }
          })}
        </Grid>
        <div className={classes.badgesJoin}>
          <Button
            component={Link}
            to="/about-exchange/badges"
            color="secondary"
            variant="contained"
          >
            Learn More
          </Button>
        </div>
      </WidthContainer>
    </div>
  )
}

export default Badges
