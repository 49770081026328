import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

import { history } from '../store'
import { useMutation } from '../hooks'
import { REFRESH_SESSION } from './mutations'
import { GET_SESSION } from '../auth/queries'
import { getMilisecondsFromNow } from '../utils/dateTime'

export default function AuthExpirationModal({
  open,
  sessionExpiresAt,
  handleConfirm,
  handleClose
}) {
  const classes = useStyles()
  const { loading, execute } = useMutation(REFRESH_SESSION, {
    refetchQueries: GET_SESSION
  })
  const [time, setTime] = useState(0)

  useEffect(() => {
    setTime(getMilisecondsFromNow(sessionExpiresAt))
    const timer = setInterval(() => {
      const miliseconds = getMilisecondsFromNow(sessionExpiresAt)
      setTime(miliseconds)
      if (miliseconds <= 0) {
        history.push('/logout')
      }
    }, 1000)
    return () => {
      window.clearInterval(timer)
    }
  }, [sessionExpiresAt])

  const minutes = Math.floor(time / 1000 / 60)
  const seconds = Math.floor(time / 1000) - minutes * 60

  return (
    <div className={classes.leaveButton}>
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.header} id="responsive-dialog-title">
          Session Expiring
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentText}>
            <WarningIcon className={classes.warningIcon} />
            <div>
              <Typography className={classes.bold}>
                Your session will expire in
                {minutes > 0
                  ? ` ${minutes} minute${minutes !== 1 ? 's' : ''} and ${seconds} second${
                      seconds !== 1 ? 's' : ''
                    }`
                  : ` ${seconds} second${seconds !== 1 ? 's' : ''}.`}
              </Typography>
              <Typography>Do you want to stay logged in?</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={async () => {
              await execute()
              handleConfirm()
              handleClose()
            }}
            disabled={loading}
            variant="outlined"
            className={classes.button}
            classes={{ outlined: classes.outlined }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              history.push('/logout')
            }}
            variant="outlined"
            className={classes.button}
            classes={{ outlined: classes.outlined }}
          >
            No, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  leaveButton: {
    flex: 1,
    textAlign: 'right',
    textTransform: 'none'
  },
  bold: {
    fontWeight: 600
  },
  button: {
    borderRadius: 5,
    padding: '5px 30px',
    margin: 10
  },
  root: {
    color: 'white',
    backgroundColor: theme.palette.red.main
  },
  outlined: {
    color: theme.palette.red.main
  },
  header: {
    backgroundColor: theme.palette.red.main,
    color: 'white'
  },
  dialogContent: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30
  },
  dialogContentText: {
    display: 'flex'
  },
  actions: {
    marginBottom: 20,
    marginRight: 20
  },
  warningIcon: {
    color: theme.palette.red.main,
    fontSize: '2.5rem',
    marginRight: 20
  }
}))
