import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import WidthContainer from '../common/WidthContainer'
import LinkField from '../common/LinkField'

export const toolbarHeight = 66

export const useStyles = makeStyles(theme => ({
  subNavMobile: {
    padding: 0,
    minHeight: 'auto',
    height: 'auto'
  },
  mobileItemContainer: {
    alignItems: 'start',
    boxShadow: '0 3px 10px #dfdfdf'
  },
  mobileItem: {
    fontSize: 16,
    padding: '16px 16px',
    borderTop: '1px solid #dfdfdf',
    borderBottom: '1px solid #dfdfdf',
    width: '100%',
    opacity: 1,
    transition: '0.25s opacity, 0.45s padding, 0.65s height'
  },
  mobileLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.navDarkGray.main
  },
  subsubnavnav: {
    display: 'block',
    paddingTop: 16,
    paddingLeft: 32,
    width: '100%',
    textDecoration: 'none',
    textTransform: 'none',
    color: theme.palette.navDarkGray.main
  },
  logoTextArea: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  logoText: {
    color: theme.palette.darkGray.main,
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: 20,
    fontWeight: 500
  },
  roundButton: {
    backgroundColor: '#efefef'
  },
  flipButton: {
    transform: 'scaleY(-1)'
  },
  spacer39: {
    width: 39
  },
  displayOff: {
    opacity: 0,
    height: 0,
    paddingTop: 0,
    paddingBottom: 0
  }
}))

function ExchangeMobileNav({
  isMobileSolutionOpen,
  isMobileOpen,
  setIsMobileOpen,
  setIsMobileSolutionsOpen,
  exchangeNavItems,
  siteName
}) {
  const classes = useStyles()

  const toggleMobileOpen = () => {
    setIsMobileOpen(!isMobileOpen)
  }

  return (
    <Toolbar classes={{ root: classes.subNavMobile }}>
      <WidthContainer container fullWidth={true} className={classes.mobileItemContainer}>
        <div className={clsx(classes.logoTextArea, classes.mobileLink)}>
          <IconButton
            className={clsx(classes.roundButton, isMobileOpen ? classes.flipButton : null)}
            onClick={toggleMobileOpen}
            aria-label="open/close menu mobile"
          >
            <Icon fontSize="small">keyboard_arrow_down</Icon>
          </IconButton>
          <LinkField
            to="/"
            label={siteName}
            className={classes.logoText}
            onClick={toggleMobileOpen}
          />
          <div className={classes.spacer39} />
        </div>
        <LinkField
          to="/marketplace"
          label="Marketplace"
          onClick={toggleMobileOpen}
          className={clsx(
            classes.mobileItem,
            classes.mobileLink,
            isMobileOpen ? null : classes.displayOff
          )}
        />
        <LinkField
          to="/about-exchange/badges"
          onClick={toggleMobileOpen}
          label="TIP Badges"
          className={clsx(
            classes.mobileItem,
            classes.mobileLink,
            isMobileOpen ? null : classes.displayOff
          )}
        />
        <LinkField
          className={clsx(
            classes.mobileItem,
            classes.mobileLink,
            isMobileOpen ? null : classes.displayOff
          )}
          label="Solutions"
          to="/solutions/tss"
          onClick={toggleMobileOpen}
        />
        <LinkField
          className={clsx(
            classes.mobileItem,
            classes.mobileLink,
            isMobileOpen ? null : classes.displayOff
          )}
          label="RFI Templates"
          to="/rfi"
          onClick={toggleMobileOpen}
        />
      </WidthContainer>
    </Toolbar>
  )
}

export default ExchangeMobileNav
