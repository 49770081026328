import React from 'react'
import { withStyles } from '@material-ui/styles'
import InputBase from '@material-ui/core/InputBase'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const TextAreaField = ({
  inputType,
  name,
  touchOnChange,
  validateOnBlur,
  label,
  validator,
  rows,
  placeholder,
  ...rest
}) => {
  function validate(value, values, event) {
    if (validator) {
      const {
        required,
        minLength,
        maxLength,
        regex,
        regexMessage,
        mustMatchKey,
        mustMatchMessage
      } = validator

      if ((required && !value) || (!value && values[mustMatchKey])) {
        return `${label} is required`
      } else if (value === '') {
        return true // if not required and is empty don't do rest of validation
      }
      if (value.length < minLength) {
        return `${label} must be at least ${minLength} characters`
      }
      if (value.length > maxLength) {
        return `${label} must be shorter than ${maxLength} characters`
      }
      if (regex && !regex.test(value)) {
        return regexMessage
      }
      if (value !== values[mustMatchKey]) {
        return mustMatchMessage
      }
    }
  }

  return (
    <CustomInput
      {...inputType({
        name: name,
        validate: validate,
        validateOnBlur: validateOnBlur === false ? false : true, // defaults to true
        touchOnChange: touchOnChange === false ? false : true // defaults to true
      })}
      placeholder={placeholder}
      name={name}
      rows={rows}
      multiline
      {...rest}
    />
  )
}

export default TextAreaField
