import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

import WidthContainer from '../common/WidthContainer'
import tipLogo from '../Navigation/tip-header-logo.svg'
import linkedIn from './linkedin.svg'
import facebook from './facebook.svg'
import twitter from './twitter.svg'
import icon_youtube from './youtube.svg'
import { useQuery } from '../hooks'
import { GET_FOOTER_ITEMS } from './queries'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.darkGray.main
  },
  link: {
    color: theme.palette.linkGrey.main,
    lineHeight: '2.5',
    textDecoration: 'none',
    fontSize: 13,
    display: 'block',
    '&:last-child': {
      marginRight: 0
    },
    '&:hover': {
      color: 'unset'
    }
  },
  subLink: {
    color: theme.palette.common.white,
    lineHeight: '2.5',
    textDecoration: 'none',
    fontSize: 12,
    marginRight: 12,
    textTransform: 'uppercase',
    '&:last-child': {
      marginRight: 0
    },
    '&:hover': {
      color: 'unset'
    }
  },
  linksBar: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 13,
    alignItems: 'center',
    color: 'white',
    padding: '50px 0',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  columnHeader: {
    marginBottom: 13,
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  linkContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  copyrightBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    borderTop: `1px solid ${theme.palette.navHoverGray.main}`,
    width: '90%',
    color: 'white',
    margin: 'auto'
  },
  termsLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
      justifyContent: 'start'
    }
  },
  copyrightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 10,
    color: theme.palette.gray6.main,
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
      justifyContent: 'start'
    }
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
      textAlign: 'center'
    }
  },
  column: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
      textAlign: 'center'
    }
  },
  tipLogo: {
    width: 170,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 330
    }
  },
  socialHeader: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30
    }
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    marginRight: 8,
    borderRadius: '25px 25px 25px 25px',
    height: 32,
    width: 32,
    padding: '0px',
    boxSizing: 'content-box',
    backgroundColor: theme.palette.linkGrey.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '50%'
    }
  }
}))

const FooterColumn = ({ label, subItems }) => {
  const classes = useStyles()
  return (
    <Grid item md={2} xs={12} className={classes.column}>
      <Typography variant="body1" className={classes.columnHeader}>
        {label}
      </Typography>
      <Typography className={classes.linkContainer}>
        {subItems.map(s => (
          <a key={s.name} href={s.to} className={classes.link}>
            {s.label}
          </a>
        ))}
      </Typography>
    </Grid>
  )
}

const Footer = () => {
  const classes = useStyles()

  const { data: { footer = [] } = [] } = useQuery(GET_FOOTER_ITEMS)
  const today = new Date()
  const year = today.getFullYear()

  return (
    <footer className={classes.container}>
      <div className={classes.linksBar}>
        <WidthContainer>
          <Grid container>
            <Grid item md={3} xs={12} className={classes.logoContainer}>
              <img src={tipLogo} className={classes.tipLogo} alt="TIP Logo" />
            </Grid>

            {footer.map(f => (
              <FooterColumn key={f.name} label={f.label} subItems={f.subItems} />
            ))}

            <Grid item md={3} xs={6} className={classes.column}>
              <Typography
                variant="body1"
                className={clsx(classes.columnHeader, classes.socialHeader)}
              >
                Follow Us
              </Typography>
              <div className={classes.socialIcons}>
                <a
                  href="https://linkedin.com/company/telecominfraproject"
                  className={clsx(classes.icon)}
                >
                  <img src={linkedIn} alt="LinkedIn" />
                </a>
                <a href="https://facebook.com/telecominfraproject" className={classes.icon}>
                  <img src={facebook} alt="facebook" />
                </a>
                <a href="https://twitter.com/TelecomInfraP" className={classes.icon}>
                  <img src={twitter} alt="twitter" />
                </a>
                <a href="https://www.youtube.com/@telecominfraproject" className={classes.icon}>
                  <img src={icon_youtube} alt="youtube" />
                </a>
              </div>
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
      <div className={classes.copyrightBar}>
        <WidthContainer>
          <Grid container>
            <Grid item md={6} xs={12} className={classes.termsLinksContainer}>
              <a href="https://telecominfraproject.com/terms-of-use/" className={classes.subLink}>
                Terms of Use
              </a>
              <a
                href="https://telecominfraproject.com/legal-and-privacy-policies/"
                className={classes.subLink}
              >
                Privacy Policy
              </a>
              <a href="https://telecominfraproject.com/cookie-policy" className={classes.subLink}>
                Cookie Policy
              </a>
            </Grid>
            <Grid item md={6} xs={12} className={classes.copyrightContainer}>
              Copyright © {year} Telecom Infra Project. All Rights Reserved.
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
    </footer>
  )
}

export default Footer
