import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useFormState } from 'react-use-form-state'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Progress from '../common/Progress'
import SelectField from '../common/SelectField'
import InputTextField from '../common/InputTextField'
import TextAreaField from '../common/TextAreaField'
import CheckboxField from '../common/CheckboxField'
import RoundedButton from '../common/RoundedButton'
import LinkField from '../common/LinkField'
import { useMutation } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'
import { logSendRequest } from '../utils/googleAnalytics'

import { REQUEST_INFO } from './mutations'
import { COMPANY_TYPE_OPTIONS, REGION_OPTIONS } from '../utils/constants'

const useStyles = makeStyles(theme => ({
  root: {},
  currentUserInfo: {
    backgroundColor: theme.palette.borderGray.main,
    padding: 16,
    marginBottom: 16,
    width: '100%',
    borderRadius: 3
  },
  submitButton: {
    width: 150,
    marginBottom: 60
  },
  agreementField: {
    marginBottom: 16
  },
  agreementText: {
    fontSize: 13
  },
  disabledText: {
    color: theme.palette.placeholderGray.main
  }
}))

function ContactUsForm({ onSubmitSuccess }) {
  const classes = useStyles()
  const [formState, inputTypes] = useFormState()

  const { execute, loading } = useMutation(REQUEST_INFO)

  const submitForm = () => {
    logSendRequest('HOMEPAGE-CONTACT-FORM')

    execute({
      values: {
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        companyName: formState.values.companyName,
        jobTitle: formState.values.jobTitle,
        email: formState.values.email,
        companyType: formState.values.companyType,
        region: formState.values.region,
        message: formState.values.message
      }
    }).then(() => onSubmitSuccess && onSubmitSuccess())
  }

  const fieldConfigs = {
    firstName: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 6 },
      name: 'firstName',
      label: 'First Name',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        minLength: 2,
        maxLength: 100
      }
    },
    lastName: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 6 },
      name: 'lastName',
      label: 'Last Name',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        minLength: 2
      }
    },
    companyName: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'companyName',
      label: 'Company Name',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        minLength: 2
      }
    },
    jobTitle: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'jobTitle',
      label: 'Job Title',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        minLength: 2
      }
    },
    email: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'email',
      label: 'Email',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        maxLength: 100,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      }
    },
    companyType: {
      InputComponent: SelectField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'companyType',
      label: 'Company Type',
      inputType: 'select',
      options: COMPANY_TYPE_OPTIONS,
      placeholder: 'Company Type',
      validator: {
        required: true
      },
      variant: 'outlined',
      disabled: false,
      error: formState.errors['companyType'] ? true : false
    },
    region: {
      InputComponent: SelectField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'region',
      label: 'Region',
      inputType: 'select',
      options: REGION_OPTIONS,
      placeholder: 'Region',
      validator: {
        required: true
      },
      variant: 'outlined',
      disabled: false,
      error: formState.errors['region'] ? true : false
    },
    message: {
      InputComponent: TextAreaField,
      gridWidth: { xs: 12, sm: 12 },
      name: 'message',
      inputType: 'select',
      placeholder: "Ask a question, such as 'Tell me more....'",
      rows: '4',
      validator: {
        required: true
      }
    },
    agreement: {
      InputComponent: CheckboxField,
      name: 'agreement',
      label: (
        <Typography className={classes.agreementText}>
          I understand and agree to the TIP{' '}
          <LinkField
            label="Privacy Policy"
            to="https://telecominfraproject.com/legal-and-privacy-policies/"
            target="_blank"
            rel="noopener noreferrer"
            hideIcon={true}
          />
        </Typography>
      ),
      inputType: 'checkbox',
      validator: {
        required: true
      },
      className: classes.agreementField
    }
  }

  const disableSubmit = isFormSubmitDisabled(fieldConfigs, formState)

  return (
    <form className={classes.root}>
      <Grid container spacing={1}>
        {Object.entries(fieldConfigs).map(([name, { InputComponent, inputType, ...args }]) => (
          <Grid key={name} item {...args.gridWidth}>
            <InputComponent
              key={name}
              inputType={inputTypes[inputType]}
              {...args}
              error={formState.errors[name] ? true : false}
              helperText={formState.errors[name]}
            />
          </Grid>
        ))}
      </Grid>
      <RoundedButton
        onClick={submitForm}
        color="primary"
        className={classes.submitButton}
        disabled={loading || disableSubmit}
      >
        {loading ? <Progress size={26} /> : 'Send Request'}
      </RoundedButton>
    </form>
  )
}

export default ContactUsForm
