import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_RFI_TEMPLATE = gql`
  query($id: ID!) {
    publicTemplateDetail(id: $id) {
      id
      title
      description
      type
      icon
      slug
      links {
        title
        label
        url
      }
      images {
        label
        url
      }
      videos {
        label
        url
      }
    }
  }
`

export const GET_RFI_TEMPLATE_BY_SLUG = gql`
  query($slug: String!) {
    publicTemplateDetailBySlug(slug: $slug) {
      id
      title
      description
      type
      icon
      slug
      links {
        title
        label
        url
      }
      images {
        label
        url
      }
      videos {
        label
        url
      }
    }
  }
`

export const GET_RFI_TEMPLATES = gql`
  query {
    publicTemplateInfo {
      id
      title
      excerpt
      type
      icon
      slug
    }
  }
`

export function useTemplate(parameters) {
  const { loading, error, data } = useQuery(GET_RFI_TEMPLATE_BY_SLUG, parameters)
  return {
    loading,
    error,
    publicTemplateDetail: data && data.publicTemplateDetailBySlug
  }
}

// order
// templateUrl
