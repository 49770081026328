import { roles } from '../utils/roleHelper'
import config from '../config'

export default function getMyAccountLinks(
  userId,
  orgId,
  userRoles,
  membershipStatus,
  organization,
  signUpStep
) {
  // if user is not in an organization they must join or create one
  if (!orgId) {
    return []
  }

  const blockedStatuses = ['lead', 'suspended', 'in progress', 'pending', 'declined', 'deactivated']
  const { approvalStatus: orgApprovalStatus, organizationRoles } = organization
  const isSignUp = signUpStep && signUpStep < 11
  const membershipDeclined = membershipStatus === 'declined'
  const canAccessRFI =
    organization &&
    organization.membership &&
    organization.membership.membershipLevel &&
    organization.membership.membershipLevel.canAccessRFI

  return [
    {
      name: 'dashboard',
      label: 'Dashboard',
      to: `${config.HUB_URL}`,
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp
    },
    {
      name: 'profile',
      label: 'My profile',
      to: `${config.HUB_URL}/user/${userId}`,
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp
    },
    {
      name: 'projectGroups',
      label: 'My project groups',
      to: `${config.HUB_URL}/user/${userId}/project-groups/${orgId}`,
      hidden:
        !userRoles.some(r => [roles.orgMember].includes(r.roleId)) ||
        !['approved', 'active', 'suspended'].includes(membershipStatus) ||
        isSignUp,
      disabled: membershipStatus === 'suspended' || blockedStatuses.includes(orgApprovalStatus)
    },
    {
      name: 'resources',
      label: 'Resources',
      hidden: !userRoles.some(r => [roles.registeredUser].includes(r.roleId)) || isSignUp,
      subItems: [
        {
          name: 'resources01',
          label: 'Documents',
          to: `${config.HUB_URL}/documents`
        },
        {
          name: 'resources02',
          hidden: !canAccessRFI,
          label: 'Submit new RFI',
          target: '_blank',
          to: `https://form.asana.com/?k=w60XpFz7lDooZcuJxgxYng&d=26764464567100`
        },
        {
          name: 'resources03',
          label: 'Download RFI',
          to: `/rfi`
        },
        {
          name: 'resources03',
          label: 'Atlassian',
          target: '_blank',
          rel: 'noopener noreferrer',
          to: `${config.ATLASSIAN_URL}`
        },
        {
          label: 'MoU Req. Docs',
          name: 'resources04',
          to: `https://telecominfraproject.com/OpenRAN-MoU-Group/`
        }
      ]
    },
    {
      name: 'organizationAdmin',
      label: 'Account admin',
      hidden:
        !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
        membershipDeclined ||
        isSignUp,
      disabled: blockedStatuses.includes(orgApprovalStatus),
      subItems: [
        {
          name: 'organizationProfile',
          label: 'Organization profile',
          to: `${config.HUB_URL}/organization/${orgId}`,
          hidden:
            !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
            membershipDeclined ||
            isSignUp
        },
        {
          name: 'organizationProjectGroups',
          label: 'Manage project groups',
          to: `${config.HUB_URL}/organization/${orgId}/project-groups`,
          hidden:
            !userRoles.some(r => [roles.orgMemberAdmin].includes(r.roleId)) ||
            membershipStatus === 'declined' ||
            isSignUp,
          disabled: blockedStatuses.includes(orgApprovalStatus)
        },
        {
          name: 'agreement',
          label: 'Agreements',
          to: `${config.HUB_URL}/organization/${orgId}/agreements`,
          hidden:
            !userRoles.some(r =>
              [roles.orgMemberAdmin, roles.orgPrimaryContact].includes(r.roleId)
            ) ||
            membershipDeclined ||
            isSignUp,
          disabled: !organizationRoles
        },
        {
          name: 'manageMembers',
          label: 'Manage participants',
          to: `${config.HUB_URL}/organization/${orgId}/manage-members`
        },
        {
          name: 'manageInvites',
          label: 'Invite participants',
          to: `${config.HUB_URL}/organization/${orgId}/manage-invites`
        },
        {
          name: 'billing',
          label: 'Account',
          to: `${config.HUB_URL}/organization/${orgId}/billing`
        }
      ]
    },
    {
      name: 'tipExchange',
      label: 'TIP Exchange',
      to: userRoles.some(r => [roles.systemAdmin, roles.exchangeListingsAdmin].includes(r.roleId))
        ? `${config.HUB_URL}/tip-exchange`
        : `${config.HUB_URL}/organization/${orgId}/tip-exchange`,
      hidden: !userRoles.some(r =>
        [roles.systemAdmin, roles.exchangeListingsAdmin, roles.orgMemberAdmin].includes(r.roleId)
      ),
      disabled:
        orgApprovalStatus !== 'approved' &&
        !userRoles.some(r => [roles.exchangeListingsAdmin].includes(r.roleId))
    }
  ]
}
