import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles({
  field: {
    height: 45
  }
})

const CheckboxField = ({
  inputType,
  name,
  label,
  validator,
  fieldClass,
  labelPlacement,
  fullWidth,
  gridWidth,
  testid,
  ...rest
}) => {
  const classes = useStyles()
  function validate(value, values, event) {
    if (validator) {
      const { required } = validator

      if (required && !values[name]) {
        return `Checkbox is required`
      }
    }
  }

  return (
    <FormControlLabel
      value="top"
      control={
        <Checkbox
          inputProps={{ 'data-testid': testid }}
          {...inputType({
            name: name,
            validate: validate,
            validateOnBlur: false,
            touchOnChange: true
          })}
          color="primary"
          className={classes.field}
          margin="normal"
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      {...rest}
    />
  )
}

export default CheckboxField
