import React, { useEffect, useContext, useCallback } from 'react'
import {
  Button,
  Chip,
  FormControl,
  Grid,
  Box,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import Fuse from 'fuse.js'
import clsx from 'clsx'

import MarketplaceFilters from './MarketplaceFilters'
import MarketplaceCard from './MarketplaceCard'
import MarketplaceSearch from './MarketplaceSearch'
import MarketplaceEmptyListState from './MarketplaceEmptyListState'

import PageTitleHead from '../common/PageTitleHead'
import MarketplaceBanner from './MarketplaceBanner'

import WidthContainer from '../common/WidthContainer'
import { GET_OFFERINGS, GET_OFFERINGS_WITH_PREVIEWS } from '../Offering/queries'
import { GET_MARKETPLACE_FILTERS } from './queries'
import { GET_PUBLIC_ORGANIZATIONS } from '../Organization/queries'
import { useQuery } from '../hooks'
import { parseQueryString } from '../utils/stringHelper'
import { Context as CriteriaContext } from '../contexts/MarketplaceCriteriaContext'
import { themeStyles } from '../theme'
import { useSession } from '../auth/queries'
import config from '../config'

const searchOptions = {
  threshold: 0.3,
  distance: 1000,
  keys: ['name', 'shortDescription', 'provider.name', 'searchTags']
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 60,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    background: `url(${config.MEDIA_CDN_DOMAIN}/media/Page-Bottom.svg) bottom left no-repeat white`,
    backgroundSize: '100%'
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pageTitle: {
    marginBottom: 24,
    marginTop: 32,
    fontSize: 60,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 40
    }
  },
  pageSubTitle: {
    alignSelf: 'flex-start',
    marginBottom: 22,
    fontSize: 36
  },
  searchInfo: {
    minHeight: 50
  },
  resultsCount: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 60,
    marginTop: -4
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  listInfoContainer: {
    minHeight: 34
  },
  activeFilters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  activeSearchString: {
    color: theme.palette.orange.main,
    fontWeight: 600
  },
  filtersContainer: {
    marginLeft: 0,
    paddingBottom: 16,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: 0
    }
  },
  filterChip: {
    marginRight: 6,
    '&:first-of-type': {
      marginLeft: 6
    }
  },
  filterChipCloseIcon: {},
  cancelSearch: {
    width: 200,
    fontSize: 12,
    margin: 100,
    marginTop: -5,
    marginLeft: -10
  },
  inputLabel: {
    fontSize: 13
  },
  sortSelect: {
    width: 100
  },
  solutionProvidersGrid: {
    margin: '32px 0 16px',
    width: '100%'
  },
  offeringArea: {
    width: '100%'
  },
  buttonGrid: {
    maxWidth: 'none',
    width: 'auto',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '19%',
      marginLeft: 50,
      marginTop: 16
    }
  }
}))

const getDefaultFilterValues = (filters, defaultFilters) => {
  const filterValues = {}
  Object.keys(defaultFilters).forEach(filterKey => {
    if (filters[filterKey]) {
      const filterOption = filters[filterKey].find(
        option => option.name === defaultFilters[filterKey]
      )

      const filterValue = (filterOption && filterOption.id) || ''
      filterValues[filterKey] = filterValue
    }
  })

  return filterValues
}

const formatMarketplaceFilters = (filters, defaultValues) => {
  return {
    types: {
      value:
        ((filters.types || []).filter(option => option.id === defaultValues.types) || {}).map(
          o => o.name
        ) || [],
      label: 'Offering Type',
      type: 'types',
      filterKey: 'type',
      options: (filters.types || []).map(option => ({
        label: option.name || '',
        value: option.name || ''
      }))
    },
    categories: {
      value: defaultValues.categories || '',
      label: 'Categories',
      type: 'categories',
      filterKey: 'categories',
      options: (filters.categories || []).map(option => ({
        label: option.name,
        value: option.id
      }))
    },
    projectGroups: {
      value: defaultValues.projectGroups || '',
      label: 'Project Groups',
      type: 'projectGroups',
      filterKey: 'projectGroups',
      options: (filters.usedProjectGroups || []).map(option => ({
        label: option.name,
        value: option.id,
        status: option.status
      }))
    },
    badges: {
      value: defaultValues.badges || '',
      label: 'TIP Badges',
      type: 'badges',
      filterKey: 'badges',
      options: (filters.badges || [])
        .filter(f => f.legacy === null || f.legacy === 'undefined' || f.legacy === false )
        .map(option => ({
          label: option.name,
          value: option.id,
          order: option.order
        }))
        .sort((a, b) => (a.order > b.order ? 1 : -1))
    },
    solutionProviders: {
      hidden: true,
      value: defaultValues.solutionProviders || '',
      label: 'Solution Provider',
      type: 'solutionProviders',
      filterKey: 'solutionProviders',
      options: (filters.solutionProviders || []).map(option => ({
        label: option.name,
        value: option.id
      }))
    }
  }
}

const filterOfferings = (offerings, activeFilters, searchString) => {
  if (!activeFilters.length && !searchString) return offerings

  let filteredOfferings = offerings

  if (searchString) {
    const fuse = new Fuse(offerings, searchOptions)

    filteredOfferings = fuse.search(searchString.trim()).map(result => result.item)
  }

  if (activeFilters.length) {
    filteredOfferings = filteredOfferings.filter(offering => {
      let showOffering = activeFilters.every(filter => {
        // handle matching selected value/id with ids in corrisponding offering array properties
        if (Array.isArray(offering[filter.type])) {
          showOffering = offering[filter.type].some(
            item => item === filter.value || item.id === filter.value
          )
        } else if (filter.type === 'solutionProviders') {
          showOffering = offering.provider.id === filter.value
        } else {
          if (Array.isArray(filter.value)) {
            const arr = filter.value.map(v => v.toLowerCase())

            if (Array.isArray(offering[filter.filterKey])) {
              showOffering = arr.every(a =>
                offering[filter.filterKey].some(o => o.toLowerCase() === a)
              )
              // showOffering = offering[filter.filterKey].every(o => arr.includes(o.toLowerCase()))
            } else {
              showOffering = arr.includes(offering[filter.filterKey].toLowerCase())
            }
          } else {
            showOffering = offering[filter.filterKey].toLowerCase() === filter.value.toLowerCase()
          }
        }

        return showOffering
      })

      return showOffering
    })
  }

  return filteredOfferings
}

const sortOfferings = (offerings, sort) => {
  const sortRelevence = (a, b) => {
    let result = 0
    if (a.provider.name.toLowerCase() > b.provider.name.toLowerCase()) result = 1
    if (a.provider.name.toLowerCase() < b.provider.name.toLowerCase()) result = -1

    if (result === 0) {
      if (a.name.toLowerCase() > b.name.toLowerCase()) result = 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) result = -1
    }
    return result
  }

  const sortRecent = (a, b) => {
    if (a.createdAt >= b.createdAt) return -1
    if (a.createdAt <= b.createdAt) return 1
    return 0
  }

  switch (sort) {
    case 'relevance':
      const products = offerings
        .slice(0)
        .filter(offering => offering.type === 'product')
        .sort(sortRelevence)
      const resource = offerings
        .slice(0)
        .filter(offering => offering.type === 'resource')
        .sort(sortRelevence)
      return [...products, ...resource]
    case 'recent':
      return offerings.sort(sortRecent)
    default:
      return offerings.sort(sortRelevence)
  }
}

const MarketplacePage = ({ location, history }) => {
  const classes = useStyles()
  const themeClasses = themeStyles()
  const { isSystemAdmin, isReadOnlySystemAdmin, isExchangeAdmin } = useSession()

  const { criteria, setCriteria } = useContext(CriteriaContext)
  const { loading: loadingOfferings, data: offeringsData } = useQuery(
    isSystemAdmin || isReadOnlySystemAdmin || isExchangeAdmin
      ? GET_OFFERINGS_WITH_PREVIEWS
      : GET_OFFERINGS
  )
  const { loading: loadingFilters, data: filtersData } = useQuery(GET_MARKETPLACE_FILTERS)
  const { data: vendorsPayload } = useQuery(GET_PUBLIC_ORGANIZATIONS)

  const filtersPayload = filtersData && filtersData.marketplaceFilters
  const setCriteriaCallback = useCallback(setCriteria, [])
  const filterCount = Object.keys(criteria.filters).length

  useEffect(() => {
    if (filtersPayload && (location.search || !filterCount)) {
      delete filtersPayload.__typename
      const queryStringObject = parseQueryString(location.search)

      let payload = { ...filtersPayload, solutionProviders: vendorsPayload.vendingOrganizations }

      const defaultFilterValues = getDefaultFilterValues(payload, queryStringObject)
      const formattedFilters = formatMarketplaceFilters(payload, defaultFilterValues)

      setCriteriaCallback({
        filters: formattedFilters,
        search: queryStringObject.search || ''
      })
    }
  }, [
    filtersPayload,
    filterCount,
    location.search,
    setCriteriaCallback,
    vendorsPayload.vendingOrganizations
  ])

  const offerings = (offeringsData && offeringsData.offerings) || []
  const activeFilters = Object.values(criteria.filters).filter(
    filter => !!(filter && (Array.isArray(filter.value) ? filter.value.length > 0 : filter.value))
  )
  let nextOfferings = filterOfferings(offerings, activeFilters, criteria.search)

  if (criteria.sort) {
    nextOfferings = sortOfferings(nextOfferings, criteria.sort)
  } else {
    nextOfferings = sortOfferings(nextOfferings, sortOfferings.sortRelevence)
  }

  const isShowingSubsetOfResults = offerings.length !== nextOfferings.length

  return (
    <WidthContainer fullWidth={true} className={clsx(classes.root, themeClasses.pageContentMargin)}>
      <PageTitleHead
        title={'Marketplace'}
        description={`Search for inter-operable solutions and connect with 
                  qualified partners who can help deliver the networks of the future.`}
      />
      <WidthContainer className={classes.columnWrapper}>
        <Grid container>
          <Grid
            container
            item
            alignItems="center"
            className={classes.solutionProvidersGrid}
            xs="auto"
          >
            <Grid item md={12} xs={12}>
              <MarketplaceSearch
                value={criteria.search}
                onChange={value => setCriteria({ search: value })}
                onClickClear={() => setCriteria({ search: '' })}
              />
            </Grid>
          </Grid>
        </Grid>
        {!!(criteria.search || activeFilters.length) && (
          <Grid container alignItems="center" className={classes.searchInfo}>
            <Grid item>
              <Typography className={classes.featuredTitle}>
                {isShowingSubsetOfResults
                  ? `Showing ${nextOfferings.length} of ${offerings.length} results for`
                  : `Showing all ${offerings.length} ${criteria.search ? 'results for ' : ''}`}
                {criteria.search && (
                  <span className={classes.activeSearchString}> "{criteria.search}"</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.activeFilters}>
          {!!activeFilters.length &&
            activeFilters.map((filter, index) =>
              Array.isArray(filter.value) ? (
                filter.value.map((value, i) => (
                  <Chip
                    key={`${filter.type}_${index}_${i}`}
                    label={value}
                    onDelete={() =>
                      setCriteria({
                        filters: {
                          [filter.type]: { value: filter.value.filter(v => v !== value) }
                        }
                      })
                    }
                    className={classes.filterChip}
                  />
                ))
              ) : (
                <Chip
                  key={`${filter.type}_${index}`}
                  label={filter.options.find(option => option.value === filter.value).label}
                  onDelete={() => setCriteria({ filters: { [filter.type]: { value: '' } } })}
                  className={classes.filterChip}
                />
              )
            )}
        </Grid>
        <Grid container>
          <Grid item lg={8} md={12} xs={12} className={classes.filtersContainer}>
            <MarketplaceFilters
              filters={Object.values(criteria.filters)}
              onChange={(type, value) => setCriteria({ filters: { [type]: { value } } })}
            />
          </Grid>
          <Grid item lg={1} md={2} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={criteria.sort} className={classes.inputLabel}>
                Sort
              </InputLabel>
              <Select
                className={classes.sortSelect}
                value={criteria.sort}
                onChange={e => setCriteria({ sort: e.target.value })}
              >
                <MenuItem value={'recent'}>Most Recent</MenuItem>
                <MenuItem value={'relevance'}>Relevance</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={1} md={6} xs={12} className={classes.buttonGrid}>
            <Button
              color="primary"
              onClick={() => (window.location = '/solution-providers')}
              variant="contained"
            >
              Solution Providers
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container>{ <MarketplaceBanner />}</Grid> */}
        <Box className={classes.offeringArea}>
          {(loadingOfferings || loadingFilters) && <LinearProgress />}

          {nextOfferings.map((offering, index) => (
            <MarketplaceCard key={offering.id} {...offering} />
          ))}
        </Box>
        {!nextOfferings.length && <MarketplaceEmptyListState filters={criteria.filters} />}
        <Grid container>
          <MarketplaceBanner />
        </Grid>
      </WidthContainer>
    </WidthContainer>
  )
}

export default MarketplacePage
