import { useQuery as query } from '@apollo/react-hooks'

export default function useQuery(q, parameters) {
  const { data, error, loading, updateQuery, refetch } = query(q, parameters)

  return {
    data: data || {},
    error,
    loading,
    updateQuery,
    refetch
  }
}
