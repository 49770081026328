import React from 'react'
import { makeStyles } from '@material-ui/styles'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
// import Box from '@material-ui/core/Box'
// import Typography from '@material-ui/core/Typography'
// import LockIcon from '@material-ui/icons/Lock'

import WidthContainer from '../common/WidthContainer'

// import OfferingSpecifications from './OfferingSpecifications'
import OfferingBadgeRecords from './OfferingBadgeRecords'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.lightGreen.main,
      borderBottomColor: 'transparent'
    }
  },
  tab: {
    '& .MuiTab-wrapper': {
      flexFlow: 'row-reverse',
      '& > *:first-child': {
        marginLeft: 4,
        marginBottom: 0
      }
    }
  },
  activeTabIndicator: {
    backgroundColor: 'transparent'
  },
  tabPanels: {
    padding: '32px 0 32px 0',
    backgroundColor: theme.palette.lightGreen.main
  },
  tabPanelsInner: {
    padding: '32px 0',
    backgroundColor: 'white'
  },
  tabTitle: {
    fontSize: 32,
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 32
  }
}))

// function TabPanel(props) {
//   const { children, value, index, ...other } = props

//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       <Box p={3}>{children}</Box>
//     </Typography>
//   )
// }

function OfferingDetailsTabs({
  id: offeringId,
  techSpecName,
  specifications,
  isAuthed,
  ocpCertified,
  badgeRecords = [],
  githubLink
}) {
  const classes = useStyles()
  // const [currentTab, setCurrentTab] = React.useState(0)

  // const secureTabs = [1, 2, 3]

  // function handleChange(event, newValue) {
  //   if (secureTabs.includes(newValue)) {
  //     if (isAuthed) {
  //       setCurrentTab(newValue)
  //     }
  //   } else {
  //     setCurrentTab(newValue)
  //   }
  // }

  return (
    <div className={classes.root}>
      <WidthContainer className={classes.tabsContainer}>
        <OfferingBadgeRecords data={badgeRecords} />
        {/* <Tabs
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          classes={{
            indicator: classes.activeTabIndicator
          }}
        >
          <Tab label="Specifications" className={classes.tab} />
          <Tab label="Reviews" className={classes.tab} icon={!isAuthed ? <LockIcon /> : null} />
          <Tab label="Questions" className={classes.tab} icon={!isAuthed ? <LockIcon /> : null} />
          <Tab label="Articles" className={classes.tab} icon={!isAuthed ? <LockIcon /> : null} />
        </Tabs> */}
      </WidthContainer>
      {/* <div className={classes.tabPanels}>
        <WidthContainer className={classes.tabPanelsInner}>
          <TabPanel value={currentTab} index={0}>
            
            <OfferingSpecifications
              content={specifications}
              techSpecName={techSpecName}
              offeringId={offeringId}
              ocpCertified={ocpCertified}
              githubLink={githubLink}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <h2 className={classes.tabTitle}>Reviews</h2>
            <Typography>Coming Soon</Typography>
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <h2 className={classes.tabTitle}>Questions</h2>
            <Typography>Coming Soon</Typography>
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <h2 className={classes.tabTitle}>Articles</h2>
            <Typography>Coming Soon</Typography>
          </TabPanel>
        </WidthContainer>
      </div> */}
    </div>
  )
}

export default OfferingDetailsTabs
