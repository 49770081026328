import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import WidthContainer from '../common/WidthContainer'
import EmailImg from '../assets/images/paper-plane-email.svg'
import LinkField from '../common/LinkField'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 50
  },
  pageTitle: {
    marginBottom: 36,
    fontSize: 48,
    fontWeight: 300,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  listDescription: {
    marginTop: 14,
    marginBottom: 28
  },
  hrPad: {
    margin: '20px 0',
    border: '1px solid #efefef'
  },
  emailImg: {
    marginTop: 69
  }
}))

const ThankYouPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <WidthContainer>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1" className={classes.pageTitle}>
              Thank you
            </Typography>
            <Typography className={classes.listDescription}>
              Check your inbox for an email from TIP with the materials you requested.
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={4}>
              <img src={EmailImg} className={classes.emailImg} alt="" />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <hr className={classes.hrPad} />
            <LinkField to="/rfi" label="See all of TIP's RFI materials" hideBorder={true} />
          </Grid>
        </Grid>
      </WidthContainer>
    </div>
  )
}

export default ThankYouPage
