import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, Redirect } from 'react-router-dom'

import { useMutation } from '../hooks'
import { useSession } from '../auth/queries'
import { useTemplate } from './queries'
import { SEND_EMAIL } from './mutations'
import RfiAuthModal, { RfiConfirmModal, RfiDeclinedModal, RfiNotFullModal } from './RfiModals'
import { roles, errorMessages } from '../utils'
import Progress from '../common/Progress'
import ErrorSection from '../common/ErrorSection'
import { logAction } from '../utils/googleAnalytics'
import Dot from '@material-ui/icons/FiberManualRecord'
import PdfIcon from '../assets/images/picture_as_pdf_outline_teal_24dp.svg'
import Vimeo from '@u-wave/react-vimeo'
import { useUserOrganization } from '../auth/queries'
import { renderMarkdown } from '../utils/pageContentHelper'

import { GET_ORGANIZATION, GET_MEMBERSHIP_LEVEL } from '../Organization/queries'
import { useQuery } from '../hooks'

const useStyles = makeStyles(theme => ({
  pageTitle: {
    marginBottom: 36,
    fontSize: 48,
    fontWeight: 300,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  listDescription: {
    marginBottom: 28,
    whiteSpace: 'pre-line'
  },
  rfiDetailImg: {
    maxWidth: '98%',
    margin: '10px'
  },
  buttonWide: {
    width: '100%',
    marginTop: 20
  },
  detailArea: {
    position: 'relative'
  },
  backLink: {
    color: '#29818C'
  },
  linkdot: {
    fontSize: 16,
    color: '#29818C'
  },
  linkWrap: {
    marginBottom: 20,
    '& svg': {
      position: 'relative',
      top: 2
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      '& svg': {
        position: 'static',
        marginTop: 3
      },
      '& a': {
        marginLeft: 10
      }
    }
  },
  videoWrap: {
    marginBottom: 20,
    '& iframe': {
      maxWidth: '100%'
    }
  },
  pdfLinkIcon: {
    maxWidth: '100%',
    marginBottom: '-4px !important',
    marginLeft: 4,
    borderBottom: `1px solid #FFFFFF`
  }
}))

const DetailView = ({ tmplt, sections }) => {
  const classes = useStyles()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loginError, setLoginError] = useState()
  const [redirect, setRedirect] = useState(false)
  const [rfiAuthModalOpen, setRfiAuthModalOpen] = useState(false)
  const [rfiConfirmModalOpen, setRfiConfirmModalOpen] = useState(false)
  const [rfiNotFullModalOpen, setRfiNotFullModalOpen] = useState(false)
  const [rfiDeclinedModalOpen, setRfiDeclinedModalOpen] = useState(false)
  const [rfiDeclinedModalCopy, setRfiDeclinedModalCopy] = useState({
    title: '',
    head: '',
    body: ''
  })
  const [errorMessage, setErrorMessage] = useState('')
  const { session, isAuthed, userId } = useSession()

  // TODO: might need to verify session is loaded before allowing user to click download?
  // const loadingSession = useSession().loading
  // console.log('loadingSession',loadingSession);

  const userRoles = session && session.user && session.user.roles ? session.user.roles : null
  const userEmail = session && session.user && session.user.email ? session.user.email : null

  const { loading, error: errorLoadingTemplateDetail, publicTemplateDetail } = useTemplate({
    variables: { slug: tmplt },
    skip: !tmplt
  })

  const { organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { loading: sendEmailLoading, execute: sendEmail, error: errorSendingEmail } = useMutation(
    SEND_EMAIL
  )

  const { membershipStatus } = organizationUser

  const { data: { organization } = {} } = useQuery(GET_ORGANIZATION, {
    variables: { id: organizationUser.orgId },
    skip: !organizationUser.orgId
  })

  const memId =
    organization && organization.membership && organization.membership.membershipLevelId
      ? organization.membership.membershipLevelId
      : null

  const { data: { membershipLevel } = {} } = useQuery(GET_MEMBERSHIP_LEVEL, {
    variables: { id: memId },
    skip: !memId
  })

  useEffect(() => {
    if (errorSendingEmail) {
      console.error(errorSendingEmail.graphQLErrors[0].message)
      setErrorMessage(`Error sending email. ${errorMessages.TIPSupport}`)
    } else if (errorLoadingTemplateDetail) {
      console.error(errorLoadingTemplateDetail.graphQLErrors[0].message)
      setErrorMessage(errorMessages.TIPSupport)
    }
  }, [errorSendingEmail, errorLoadingTemplateDetail])

  const handleEmail = async () => {
    // make api call to send email
    try {
      await sendEmail({
        id: publicTemplateDetail.id,
        values: {}
      })
      logAction(
        'confirm-send-email',
        'get-the-rfi-template-files',
        publicTemplateDetail.title,
        session.user
      )
      setRedirect(true)
    } catch (e) {
      logAction(
        'confirm-send-email-error',
        'get-the-rfi-template-files',
        publicTemplateDetail.title,
        session.user
      )
      console.error('Error sending email.', e)
    }
    setRfiConfirmModalOpen(false)
  }

  // only show post-auth modals once we have user session AND userOrganization info
  useEffect(() => {
    if (loggedIn && membershipStatus && membershipLevel && !loginError) {
      authUser()
    } else if (loginError) {
      authUser(loginError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, loginError, membershipStatus, membershipLevel])

  const authUser = error => {
    // determine which modals to show user
    const userHasOrg = userRoles
      ? userRoles.some(role => [roles.orgMember].includes(role.roleId))
      : null
    if (error) {
      // just show a generic no-auth modal. upgrade this if custom messages are needed
      // TODO: replace this with copy from Sandra later. for now use defaults
      setRfiDeclinedModalCopy({
        titleCopy: null,
        headCopy: null,
        bodyCopy: null
      })
      setRfiDeclinedModalOpen(true)
    } else if (
      isAuthed &&
      userHasOrg &&
      membershipStatus !== 'suspended' &&
      membershipLevel &&
      membershipLevel.canAccessRFI &&
      membershipLevel.canAccessRFI === true
    ) {
      // if user logged in & and part of org, show download confirmation modal
      setRfiConfirmModalOpen(true)
    } else if (membershipLevel && membershipLevel.canAccessRFI === false) {
      setRfiNotFullModalOpen(true)
    } else if (isAuthed) {
      // user does not belong to org, show no-access modal
      setRfiDeclinedModalOpen(true)
    } else {
      // else ask user to log in (or create acct)
      setRfiAuthModalOpen(true)
    }
  }

  return (
    <>
      {redirect ? <Redirect to="/thankyou" /> : null}
      <RfiAuthModal
        isOpen={rfiAuthModalOpen}
        setOpen={setRfiAuthModalOpen}
        callback={error => (error ? setLoginError(error) : setLoggedIn(true))}
        templateName={publicTemplateDetail ? publicTemplateDetail.title : null}
      />
      <RfiConfirmModal
        isOpen={rfiConfirmModalOpen}
        setOpen={setRfiConfirmModalOpen}
        callback={() => handleEmail()}
        userEmail={userEmail}
        sendEmailLoading={sendEmailLoading}
      />
      <RfiDeclinedModal
        isOpen={rfiDeclinedModalOpen}
        setOpen={setRfiDeclinedModalOpen}
        copy={rfiDeclinedModalCopy}
      />
      <RfiNotFullModal isOpen={rfiNotFullModalOpen} setOpen={setRfiNotFullModalOpen} />
      <div className={classes.detailArea}>
        {loading ? (
          <Progress size={30} delay={0} />
        ) : (
          <>
            <Link className={classes.backLink} component={RouterLink} to={`/rfi`}>
              &#8592; Back
            </Link>
            {errorMessage ? <ErrorSection>{errorMessage}</ErrorSection> : null}
            <Typography variant="h1" className={classes.pageTitle}>
              {publicTemplateDetail.title === 'General Use' ? (
                <>General RFI templates</>
              ) : (
                <>RFI template for {publicTemplateDetail.title}</>
              )}
            </Typography>
            <Grid container item spacing={5}>
              <Grid item xs={12} sm={9}>
                <div className={classes.listDescription}>
                  {renderMarkdown(publicTemplateDetail.description)}
                </div>
                {publicTemplateDetail.videos.map(videoItem => {
                  return (
                    <div key={videoItem.url} className={classes.videoWrap}>
                      {videoItem.label ? <p>{videoItem.label}</p> : null}
                      <Vimeo video={videoItem.url} />
                    </div>
                  )
                })}
                <div className={classes.listDescription}>
                  {publicTemplateDetail.links.map(linkItem => {
                    const isPdf = linkItem.url.includes('.pdf')
                    return (
                      <Typography key={linkItem.url} className={classes.linkWrap}>
                        <Dot className={classes.linkdot} />{' '}
                        <Link to="" title={linkItem.title} href={linkItem.url} target="_blank">
                          {linkItem.label}
                        </Link>
                        {isPdf ? (
                          <img
                            className={classes.pdfLinkIcon}
                            src={PdfIcon}
                            alt="pdf open in new tab"
                          />
                        ) : null}
                      </Typography>
                    )
                  })}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <img
                  src={
                    publicTemplateDetail.images && publicTemplateDetail.images[0]
                      ? publicTemplateDetail.images[0].url
                      : '/iso-doc.svg'
                  }
                  alt="Document Icon"
                  className={classes.rfiDetailImg}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonWide}
                  onClick={() => {
                    logAction(
                      'get-the-template-files',
                      'get-the-rfi-template-files',
                      publicTemplateDetail.title
                    )
                    authUser()
                  }}
                >
                  Get the template files
                </Button>
                {sections.detailProviso}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  )
}

export default DetailView
