import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'

import BecomeAMemberButton from '../../common/BecomeAMemberButton'
import WidthContainer from '../../common/WidthContainer'
import BadgeListItem from './BadgeListItem'
import { themeStylesTip, tipCoreTheme } from '../../theme'
import { useBadges } from '../../utils/badgeHelper'
import PageTitleHead from '../../common/PageTitleHead'
import config from '../../config'
import { usePageContent, getSection } from '../../utils/pageContentHelper'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    background: `url(${config.MEDIA_CDN_DOMAIN}/media/Page-Bottom.svg) bottom left no-repeat white`,
    backgroundSize: '100%',
    flexDirection: 'column',
    flexGrow: 1
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingTop: 32
  },
  rootPrevious: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
    paddingTop: 64,
    flexGrow: 1,
    background: '#F6F6F6'
  },
  pageTitle: {
    marginBottom: 24,
    fontSize: 60,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  strong: {
    fontSize: '.8rem',
    fontWeight: 800,
    marginBottom: 15
  },
  hero: {
    marginBottom: 20
  },
  badges: {
    marginBottom: 20
  },
  badgesTitle: {
    marginTop: 32,
    marginBottom: 24,
    fontWeight: 500
  },
  participate: {
    marginTop: 24,
    marginBottom: 16,
    fontWeight: 600,
    textAlign: 'center'
  },
  image: {
    height: 'auto',
    maxHeight: 150,
    display: 'inline-block'
  },
  badgeTitle: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  greentext: {
    color: '#29818C'
  }
}))

const BadgesPage = ({ location }) => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()
  const { badges = [] } = useBadges()

  const { data: pageContent = [], error: pageContentError } = usePageContent('Badges Page')
  const sections = {
    pageTitle: getSection(pageContent, 'Page Title'),
    pageDescription: getSection(pageContent, 'Page Description'),
    upperTitle: getSection(
      pageContent,
      'Upper Title',
      <Typography variant="h4" className={classes.badgesTitle} />
    ),
    lowerTitle: getSection(
      pageContent,
      'Lower Title',
      <Typography variant="h4" className={classes.badgesTitle} />
    )
  }

  if (pageContentError) {
    console.error('Error loading page content Org Page:', pageContentError)
  }

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <div className={clsx(classes.root, themeClasses.pageContentMargin)}>
        <PageTitleHead
          title={sections.pageTitle}
          description={`Badges are awarded that demonstrate the level of maturity against 
             the technical requirements being addressed.`}
        />
        <WidthContainer className={classes.columnWrapper}>
          <Grid container spacing={0} className={classes.badges}>
            <Grid item xs={12}>
              {sections.pageDescription}
              {sections.upperTitle}
              <Grid container item spacing={0}>
                {Object.entries(badges).map(([index, badge]) => {
                  if (!badge.legacy) {
                    return (
                      <BadgeListItem
                        key={index}
                        title={badge.name}
                        body={badge.description}
                        image={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.badgeFile}`}
                        icon={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.iconFile}`}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.badges}>
              <Grid item md={12}>
                <Typography variant="h6" className={classes.participate}>
                  <span className={classes.greentext}>Participate in TIP</span> to list products and
                  solutions on the TIP Exchange Marketplace.
                </Typography>
                <Typography variant="h6" className={classes.participate}>
                  <BecomeAMemberButton />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </WidthContainer>
      </div>
      <div className={classes.rootPrevious}>
        <WidthContainer className={classes.columnWrapper}>
          <Grid container spacing={0} className={classes.badges}>
            <Grid item xs={12}>
              {sections.lowerTitle}
              <Grid container item spacing={0}>
                {Object.entries(badges).map(([index, badge]) => {
                  if (badge.legacy) {
                    return (
                      <BadgeListItem
                        key={index}
                        title={badge.name}
                        body={badge.description}
                        image={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.badgeFile}`}
                        icon={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.iconFile}`}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Grid>
            </Grid>

            <Grid container spacing={0} className={classes.badges}>
              <Grid item md={12}>
                <Typography variant="h6" className={classes.participate}>
                  <span className={classes.greentext}>Participate in TIP</span> to list products and
                  solutions on the TIP Exchange Marketplace.
                </Typography>
                <Typography variant="h6" className={classes.participate}>
                  <BecomeAMemberButton />
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={1} />
          </Grid>
        </WidthContainer>
      </div>
    </MuiThemeProvider>
  )
}

export default BadgesPage
