import React, { useState, useEffect } from 'react'

import { useMutation } from '../../hooks'
import { useSession } from '../../auth/queries'
import { SEND_EMAIL } from './mutations'
import AuthModal, { AuthEmailModal, ConfirmEmailModal, DeclinedModal, NotFullModal } from './Modals'
import { roles, errorMessages } from '../../utils'
import { useUserOrganization } from '../../auth/queries'
import { GET_ORGANIZATION, GET_MEMBERSHIP_LEVEL } from '../../Organization/queries'
import { useQuery } from '../../hooks'

const DownloadAsset = ({
  isOpen,
  setOpen,
  onSuccess,
  authModal = '', // options: default is to require user to log in or create acct. 'captureEmail' - use this for situations where only email capture is required
  confirmModal = 'email',
  confirmModalContent,
  copy = {} // for overriding default copy. this should contain any/all copy that overrides
}) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loginError, setLoginError] = useState()
  const [authModalOpen, setAuthModalOpen] = useState(authModal === '' ? isOpen : false)
  const [authEmailModalOpen, setAuthEmailModalOpen] = useState(
    authModal === 'captureEmail' ? isOpen : false
  )
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] = useState(false)
  const [declinedModalOpen, setDeclinedModalOpen] = useState(false)
  const [notFullModalOpen, setNotFullModalOpen] = useState(false)
  const [declinedModalCopy, setDeclinedModalCopy] = useState({
    title: '',
    head: '',
    body: ''
  }) // TODO: this should be incorporated with the global 'copy' param
  const [errorMessage, setErrorMessage] = useState('')
  const { session, isAuthed, userId } = useSession()

  // TODO: might need to verify session is loaded before allowing user to click download?
  // const loadingSession = useSession().loading
  // console.log('loadingSession',loadingSession);

  const userRoles = session && session.user && session.user.roles ? session.user.roles : null
  const userEmail = session && session.user && session.user.email ? session.user.email : null

  const { organization: organizationUser } = useUserOrganization({
    variables: { id: userId },
    skip: !userId
  })

  const { data: { organization } = {} } = useQuery(GET_ORGANIZATION, {
    variables: { id: organizationUser.orgId },
    skip: !organizationUser.orgId
  })

  const memId =
    organization && organization.membership && organization.membership.membershipLevelId
      ? organization.membership.membershipLevelId
      : null

  const { data: { membershipLevel } = {} } = useQuery(GET_MEMBERSHIP_LEVEL, {
    variables: { id: memId },
    skip: !memId
  })

  const errorLoadingTemplateDetail = false
  const publicTemplateDetail = null

  const { loading: sendEmailLoading, execute: sendEmail, error: errorSendingEmail } = useMutation(
    SEND_EMAIL
  )

  const { membershipStatus } = organizationUser

  useEffect(() => {
    // setAuthModalOpen(isOpen)
    if (isOpen === true) {
      authUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    // if all modals are closed, tell parent
    if (
      authModalOpen === false &&
      confirmEmailModalOpen === false &&
      declinedModalOpen === false &&
      notFullModalOpen === false
    ) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authModalOpen, confirmEmailModalOpen, declinedModalOpen, notFullModalOpen])

  useEffect(() => {
    if (errorSendingEmail) {
      console.error(errorSendingEmail.graphQLErrors[0].message)
      setErrorMessage(`Error sending email. ${errorMessages.TIPSupport}`)
    } else if (errorLoadingTemplateDetail) {
      console.error(errorLoadingTemplateDetail.graphQLErrors[0].message)
      setErrorMessage(errorMessages.TIPSupport)
    }
  }, [errorSendingEmail, errorLoadingTemplateDetail])

  const handleCallback = async () => {
    if (onSuccess) {
      onSuccess()
    } else {
      sendEmail()
    }
    setConfirmEmailModalOpen(false)
  }

  // only show post-auth modals once we have user session AND userOrganization info
  useEffect(() => {
    if (loggedIn && membershipStatus && membershipLevel && !loginError) {
      authUser()
    } else if (loginError) {
      authUser(loginError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, loginError, membershipStatus, membershipLevel])

  const authUser = error => {
    // determine which modals to show user
    const userHasOrg = userRoles
      ? userRoles.some(role => [roles.orgMember].includes(role.roleId))
      : null
    if (error) {
      // just show a generic no-auth modal. upgrade this if custom messages are needed
      // TODO: replace this with copy from Sandra later. for now use defaults
      setDeclinedModalCopy({
        titleCopy: null,
        headCopy: null,
        bodyCopy: null
      })
      setDeclinedModalOpen(true)
    } else if (
      isAuthed &&
      userHasOrg &&
      membershipStatus !== 'suspended' &&
      membershipLevel &&
      membershipLevel.canAccessTSS &&
      membershipLevel.canAccessTSS === true
    ) {
      // if user logged in & and part of org, show download confirmation modal
      if (confirmModal === 'email') {
        setConfirmEmailModalOpen(true)
      }
    } else if (membershipLevel && membershipLevel.canAccessTSS === false) {
      setNotFullModalOpen(true)
    } else if (isAuthed) {
      // user does not belong to org, show no-access modal
      setDeclinedModalOpen(true)
    } else {
      if (authModal === 'captureEmail') {
        // no auth required, only capture email
        setAuthEmailModalOpen(true)
      } else {
        // else ask user to log in (or create acct)
        setAuthModalOpen(true)
      }
    }
  }

  if (errorMessage) {
    console.error(errorMessage)
  }

  return (
    <>
      {/* standard modals */}
      <AuthModal
        isOpen={authModalOpen}
        setOpen={val => {
          setOpen(val)
          setAuthModalOpen(val)
        }}
        callback={error => (error ? setLoginError(error) : setLoggedIn(true))}
        templateName={publicTemplateDetail ? 'publicTemplateDetail.title' : null}
        copy={copy.authModal || {}}
      />
      <ConfirmEmailModal
        isOpen={confirmEmailModalOpen}
        setOpen={val => {
          setOpen(val)
          setConfirmEmailModalOpen(val)
        }}
        callback={() => handleCallback()}
        userEmail={userEmail}
        sendEmailLoading={sendEmailLoading}
        copy={copy.confirmEmailModal || {}}
      />
      <DeclinedModal
        isOpen={declinedModalOpen}
        setOpen={val => {
          setOpen(val)
          setDeclinedModalOpen(val)
        }}
        copy={declinedModalCopy}
      />
      <NotFullModal
        isOpen={notFullModalOpen}
        setOpen={val => {
          setOpen(val)
          setNotFullModalOpen(val)
        }}
      />
      {/* alternative modals */}
      <AuthEmailModal
        isOpen={authEmailModalOpen}
        setOpen={val => {
          setOpen(val)
          setAuthEmailModalOpen(val)
        }}
        callback={() => handleCallback()}
        templateName={publicTemplateDetail ? 'publicTemplateDetail.title' : null}
        copy={copy.authEmailModal || {}}
      />
    </>
  )
}

export default DownloadAsset
