import React, { useEffect, useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

import { VERIFY_TOKEN } from '../SignUp/CreateAccount'
import { useMutation, useFormStyles } from '../hooks'
import { resetStore } from '../middleware/api'

import LoginForm from './LoginForm'
import { themeStyles } from '../theme'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 80
  }
}))

export default function LoginPage({ location = { search: '' }, history }) {
  const formClasses = useFormStyles()
  const classes = useStyles()
  const [defaultEmail, setDefaultEmail] = useState('')
  const [loginSuccess, setLoginSuccess] = useState(false)
  const themeClasses = themeStyles()

  const {
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_TOKEN)

  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  let errorMessage = false

  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  useEffect(() => {
    if (token) {
      handleVerifyTokenCallback({ values: { token } })
    }
  }, [token, handleVerifyTokenCallback])

  useEffect(() => {
    if (verifyTokenResult && verifyTokenResult.verifyToken && !defaultEmail) {
      // if we verifed a token set our form email state
      setDefaultEmail(verifyTokenResult.verifyToken.email)
      // formState.setField('email', verifyTokenResult.verifyToken.email)
    }
  }, [verifyTokenResult, defaultEmail])

  if (verifyTokenError) {
    // if error validating token, show error
    errorMessage = 'Your token is invalid or expired.'
  }

  const acceptingInvite = !!token // if the user is accepting an invite a token will be present.
  let registerUrl = '/get-started'
  if (acceptingInvite) {
    registerUrl = `/create-account?token=${token}`
  }

  if (loginSuccess) {
    return <Redirect to="/" /> // redirect to homepage after succesful login
  }

  return (
    <Grid container justify="center" className={clsx(classes.root, themeClasses.pageContentMargin)}>
      <Grid item xs={12} sm={12} md={7} lg={6} className={formClasses.container}>
        <div className={formClasses.header}>
          <Typography role="heading" variant="h3" className={formClasses.heading}>
            Login
          </Typography>
          {acceptingInvite && (
            <Typography paragraph>You are accepting an organization invite</Typography>
          )}
        </div>
        <LoginForm
          defaultEmail={defaultEmail}
          token={token}
          registerUrl={registerUrl}
          errorMessage={errorMessage}
          onLoginSuccess={() => {
            resetStore()
            setLoginSuccess(true)
          }}
        />
      </Grid>
    </Grid>
  )
}
