import React from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import Interweave from 'interweave'

import OfferingRequestInfoModal from './OfferingRequestInfoModal'
import LinkField from '../common/LinkField'
// import { Link } from 'react-router-dom'
import config from '../config'
import { logRequestInfo } from '../utils/googleAnalytics'
// import GraduatedIcon from '../assets/images/graduated_85x32.png'
import OfferingSpecifications from './OfferingSpecifications'

const useStyles = makeStyles(theme => ({
  root: {
    '& .link': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  h1: {
    fontSize: 34,
    marginBottom: 16
  },
  label: {
    marginRight: 6
  },
  provider: {
    marginBottom: 36,
    fontSize: 14
  },
  primaryAction: {
    marginTop: 32,
    marginBottom: 42
  },
  badges: {
    display: 'flex',
    marginBottom: 28
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    marginBottom: 24
  },
  badgeName: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  badgeIcon: {
    height: 40,
    width: 40,
    marginRight: 6
  },
  projectGroups: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16
  },
  group: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    padding: 6,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 6,
    backgroundColor: theme.palette.gray3.main
  },
  classifications: {
    display: 'flex',
    marginTop: 14,
    marginBottom: 14
  },
  categories: {
    display: 'flex',
    padding: '0 12px 0 0'
  },
  tagLabel: {
    marginRight: 15
  },
  tags: {
    display: 'flex',
    alignItems: 'flex-end'
    // padding: '0 12px 0 12px'
  },
  tagIcon: {
    width: 50,
    height: 'auto'
  },
  tag: {
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center'
  },
  tagName: {
    fontSize: 12,
    fontWeight: 800,
    color: 'black'
  },
  link: {
    textDecoration: 'none'
  },
  expandBoxClose: {
    height: 136,
    overflow: 'hidden'
  },
  expandBoxOpen: {
    height: 'auto',
    overflow: 'auto'
  },
  expander: {
    marginBottom: 36,
    fontSize: 14,
    color: '#29818C',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

// const CheckGraduationIcon = group => {
//   if (group.status.toLowerCase() === 'inactive') {
//     return <img src={GraduatedIcon} alt="" />
//   } else return ''
// }

function OfferingSummary({
  id = '',
  name = '',
  longDescription = '',
  provider = {
    name: ''
  },
  techSpecName,
  specifications,
  // isAuthed,
  ocpCertified,
  githubLink,
  badges = [],
  currentUser = {},
  requestInfoModalOpen = false,
  setRequestInfoModalOpen = () => {}
}) {
  const classes = useStyles()
  const [longDesc, setLongDesc] = React.useState(false)

  // Open Modal / Google Analytics for request info clicks
  const openModal = () => {
    logRequestInfo(name, currentUser)
    setRequestInfoModalOpen(true)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.h1} role="heading" variant="h1">
        {name}
      </Typography>
      <div className={longDesc ? classes.expandBoxOpen : classes.expandBoxClose}>
        <Typography paragraph component="div">
          <Interweave content={longDescription} />
        </Typography>
      </div>
      <Typography className={classes.expander} onClick={() => setLongDesc(!longDesc)}>
        Show {longDesc ? 'less' : 'more'}
      </Typography>
      <Typography className={classes.provider}>
        Provided by <LinkField to={`/solution-providers/${provider.id}`} label={provider.name} />
      </Typography>
      <OfferingSpecifications
        content={specifications}
        techSpecName={techSpecName}
        offeringId={id}
        ocpCertified={ocpCertified}
        githubLink={githubLink}
        hideTitle={true}
      />
      <Button
        color="primary"
        variant="contained"
        className={classes.primaryAction}
        onClick={openModal}
      >
        Request Info
      </Button>
      <OfferingRequestInfoModal
        offeringId={id}
        name={name}
        currentUser={currentUser}
        handleClose={() => setRequestInfoModalOpen(false)}
        open={requestInfoModalOpen}
      />

      <div className={classes.badges}>
        {badges
          .filter(f => f.legacy !== true || f.legacy === 'undefined')
          .map((badge, index) => (
            <div className={classes.badge} key={`${badge.name}_${index}`}>
              <img
                className={classes.badgeIcon}
                src={`${config.MEDIA_CDN_DOMAIN}/media/badges/${badge.iconFile}`}
                alt=""
              />
              <LinkField to={`/marketplace?badges=${badge.name}`} label={badge.name} />
            </div>
          ))}
      </div>

      {/* {!!projectGroups.length && (
        <div className={classes.projectGroups}>
          <Typography className={classes.label} variant="body2">
            Project Group:
          </Typography>
          {projectGroups.map((group, index) => (
            <React.Fragment key={`${group.name}_${index}`}>
              <Link
                className={`${classes.group} link`}
                to={`/marketplace?projectGroups=${group.name}`}
              >
                {group.name}
                {CheckGraduationIcon(group)}
              </Link>
            </React.Fragment>
          ))}
        </div>
      )} */}

      {/* <Divider />
      <div className={classes.classifications}>
        {!!categories.length && (
          <div className={classes.categories}>
            <Typography className={classes.label} variant="body2">
              Category:
            </Typography>
            {categories.map((category, index) => (
              <React.Fragment key={`${category.name}_${index}`}>
                <LinkField to={`/marketplace?categories=${category.name}`} label={category.name} />
                {categories.length > index + 1 ? ',\u00A0' : ''}
              </React.Fragment>
            ))}
          </div>
        )}
      </div> */}
      {/* {tags.length > 0 && (
        <>
          <Divider />
          <div className={classes.classifications}>
            {!!tags.length && (
              <>
                <Typography className={classes.tagLabel} variant="body2">
                  GEO Application:
                </Typography>
                <div className={classes.tags}>
                  {tags.map((tag, index) => (
                    <a className={classes.link} href={`/marketplace?tags=${tag.name}`}>
                      <div className={classes.tag} key={`tag_${index}`}>
                        <img
                          className={classes.tagIcon}
                          src={`${config.MEDIA_CDN_DOMAIN}/media/offering-tags/${tag.iconFile}`}
                          alt={tag.name}
                        />
                        <Typography className={classes.tagName}>{tag.name}</Typography>
                      </div>
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )} */}
    </div>
  )
}

export default OfferingSummary
