import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import { useSession } from '../auth/queries'

export default function NavSpacer() {
  const { loading } = useSession()
  if (loading) {
    return null
  }

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <div
          style={{
            height: 124
          }}
        />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <div
          style={{
            height: 150
          }}
        />
      </Hidden>
    </>
  )
}
