import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useQuery } from '../hooks'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import WidthContainer from '../common/WidthContainer'
import ListPageView from './ListPageView'
import RfiListItem from './RfiListItem'
import DetailView from './DetailView'
import { GET_RFI_TEMPLATES } from './queries'
import { usePageContent, getSection } from '../utils/pageContentHelper'
import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: -14
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 64
  },
  mainLower: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 64,
    background: `url(${config.MEDIA_CDN_DOMAIN}/media/Page-Bottom.svg) bottom left no-repeat white`
  },
  backgroundSize: '100%',
  reWrap: {
    width: 1080,
    display: 'flex',
    flexDirection: 'column'
  },
  pageTitle: {
    marginBottom: 24,
    fontSize: 60,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  pageSubTitle: {
    alignSelf: 'flex-start',
    marginBottom: 22,
    fontSize: 36
  },
  listDescription: {
    marginTop: 32,
    marginBottom: 24,
    fontWeight: 500
  },
  getProviso: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    marginTop: 32
  }
}))

const RfiPage = ({ match }) => {
  const classes = useStyles()

  const { data: pageContent = [], error: pageContentError } = usePageContent('RFI Page')
  const sections = {
    pageTitle: getSection(pageContent, 'Page Title'),
    pageDescription: getSection(pageContent, 'Page Description'),
    detailProviso: getSection(
      pageContent,
      'Detail Proviso',
      <Typography variant="subtitle1" className={classes.getProviso} />
    )
  }

  const [selectedId, setSelectedId] = useState(
    match.params && match.params.templateId ? match.params.templateId : null
  )
  const { data: { publicTemplateInfo = [] } = [] } = useQuery(GET_RFI_TEMPLATES)

  useEffect(() => {
    setSelectedId(match.params && match.params.templateId ? match.params.templateId : null)
  }, [match])

  if (pageContentError) {
    console.error('Error loading page content RFI Page:', pageContentError)
  }

  return (
    <div className={clsx(classes.root)}>
      {selectedId !== null ? (
        <WidthContainer fullWidth className={classes.columnWrapper}>
          <DetailView tmplt={selectedId} sections={sections} />
        </WidthContainer>
      ) : (
        <div className={classes.mainLower}>
          <ListPageView sections={sections} />
          <WidthContainer className={classes.reWrap}>
            {publicTemplateInfo.map(rfiT => (
              <RfiListItem
                key={rfiT.id}
                tId={rfiT.id}
                title={rfiT.title}
                excerpt={rfiT.excerpt}
                image={rfiT.icon}
                slug={rfiT.slug}
                template={selectedId}
              />
            ))}
          </WidthContainer>
        </div>
      )}
    </div>
  )
}

export default RfiPage
