import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { roles } from '../utils/roleHelper'

export const GET_SESSION = gql`
  query {
    session {
      expiresAt
      user {
        id
        roles {
          roleId
          scopes
        }
        signUpStep
        email
        emailValidated
        stayLoggedIn
      }
    }
  }
`

export const GET_USER_ORGANIZATIONS = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      organizations {
        organizationId
        status
        requestResent
      }
    }
  }
`

export const GET_USER_DATA = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      firstname
      lastname
      signUpStep
      roles {
        roleId
      }
      organizations {
        organizationId
      }
    }
  }
`

export const GET_ORGANIZATION_DATA = gql`
  query($id: ID) {
    organization(id: $id) {
      id
      approvalStatus
      name
    }
  }
`

export function getSessionQueries(userId) {
  return [
    {
      query: GET_SESSION
    },
    {
      query: GET_USER_ORGANIZATIONS,
      variables: { id: userId }
    },
    { query: GET_USER_DATA, variables: { id: userId } }
  ]
}

export function useSession(options) {
  const {
    client,
    loading,
    error,
    data,
    networkStatus,
    fetchMore,
    refetch: refetchSession
  } = useQuery(GET_SESSION, options)

  const isAuthed = data && data.session ? !error && !!data.session : false
  const isSystemAdmin =
    data && data.session
      ? isAuthed && data.session.user.roles.some(r => r.roleId === roles.systemAdmin)
      : false

  const isReadOnlySystemAdmin =
    data && data.session
      ? isAuthed && data.session.user.roles.some(r => r.roleId === roles.readOnlySystemAdmin)
      : false

  const isExchangeAdmin =
    data && data.session
      ? isAuthed && data.session.user.roles.some(r => r.roleId === roles.exchangeAdmin)
      : false

  const isOrgAdmin =
    data && data.session
      ? isAuthed && data.session.user.roles.find(r => r.roleId === roles.orgMemberAdmin)
      : false

  return {
    clearStore: client.clearStore, // use resetStore to retrigger active queries
    loading,
    error,
    networkStatus,
    fetchMore,
    refetchSession: refetchSession,
    isSystemAdmin,
    isReadOnlySystemAdmin,
    isExchangeAdmin,
    isAuthed,
    isOrgAdmin,
    session: data && data.session,
    userId: data && data.session && data.session.user ? data.session.user.id : null
  }
}

export function useUserOrganization(parameters) {
  const { loading, error, data } = useQuery(GET_USER_ORGANIZATIONS, parameters)

  let orgId, orgStatusPending, membershipStatus, requestResent

  if (data && data.user && data.user.organizations.length > 0) {
    // for MVP only one org will ever be returned
    orgId = data.user.organizations[0].organizationId
    orgStatusPending = data.user.organizations[0].status === 'requested'
    membershipStatus = data.user.organizations[0].status
    requestResent = data.user.organizations[0].requestResent
  }
  return {
    loading,
    error,
    organization: {
      orgId,
      orgStatusPending,
      membershipStatus,
      requestResent
    }
  }
}

export function useOrganization(parameters) {
  const { loading, error, data } = useQuery(GET_ORGANIZATION_DATA, parameters)
  return {
    loading,
    error,
    organization: data && data.organization
  }
}

export function useUser(parameters) {
  const { loading, error, data } = useQuery(GET_USER_DATA, parameters)
  return {
    loading,
    error,
    user: data && data.user
  }
}
