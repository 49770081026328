import React, { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Cookies from 'universal-cookie'
import { useMutation } from '../hooks'

import gql from 'graphql-tag'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 300,
    textAlign: 'center'
  },
  input: {
    margin: theme.spacing(2)
  }
}))

const AuthorizeEnv = ({ setAuthorized }) => {
  const classes = useStyles()

  const cookies = new Cookies()
  const [password, setPassword] = useState('')

  const { execute: handleAuthorizeEnv } = useMutation(AUTHORIZE_ENV)
  const handleAuthorizeEnvCallback = useCallback(handleAuthorizeEnv, [])

  const submit = e => {
    e.preventDefault()

    handleAuthorizeEnvCallback({ values: { password } }).then(({ data }) => {
      setAuthorized(data.authorizeEnv)

      if (data.authorizeEnv) {
        const date = new Date()

        // 24h duration
        date.setTime(date.getTime() + 86400000)

        cookies.set('exchangeAuthorized', true, {
          path: '/',
          expires: date
        })
      }
    })
  }

  return (
    <>
      <Paper className={classes.root}>
        <h1>Password Required For Access</h1>
        <Divider variant="middle" />
        <form onSubmit={submit}>
          <TextField
            className={classes.input}
            label="Password"
            value={password}
            variant="outlined"
            onChange={e => setPassword(e.target.value)}
          />
          <Button variant="outlined" type="submit">
            Submit
          </Button>
        </form>
      </Paper>
    </>
  )
}

export default AuthorizeEnv

export const AccessDenied = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <h1>Access Denied</h1>
    </Paper>
  )
}

const AUTHORIZE_ENV = gql`
  mutation($input: AuthorizeEnvInput!) {
    authorizeEnv(input: $input)
  }
`
