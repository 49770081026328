import React from 'react'
import Creatable from 'react-select/creatable'
import { emphasize, makeStyles, useTheme, lighten } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import gql from 'graphql-tag'

import { useQuery } from '../hooks'

export const GET_ORGANIZATION_LIST = gql`
  query {
    publicOrganizations {
      id
      name
      shippingAddress {
        address1
        address2
        city
        state
        country
        postalCode
      }
    }
  }
`

export default function OrganizationAutocomplete({ setFormState }) {
  const classes = useStyles()
  const theme = useTheme()
  const [single, setSingle] = React.useState(null)

  const { data: { publicOrganizations } = {}, loading } = useQuery(GET_ORGANIZATION_LIST)

  let suggestions = []

  if (!loading && publicOrganizations) {
    suggestions = publicOrganizations.map(({ name, id, shippingAddress }) => ({
      value: id,
      label: name,
      address: shippingAddress,
    }))

    // order alphabetically
    suggestions.sort(function (a, b) {
      var aLabel = a.label.toLowerCase()
      var bLabel = b.label.toLowerCase()
      // sort alphabetically by name
      if (aLabel < bLabel) {
        return -1
      }
      if (aLabel > bLabel) {
        return 1
      }
      return 0
    })
  }

  function handleChangeSingle(item) {
    if (item) {
      setFormState('organization', item.label)
      if (item.__isNew__) {
        setFormState('organizationId', null)
      } else {
        setFormState('organizationId', item.value)
      }
      setSingle(item)
    } else {
      setFormState('organization', null)
      setFormState('organizationId', null)
      setSingle(null)
    }
  }

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  return (
    <div className={classes.root}>
      <Creatable
        isClearable
        classes={classes}
        styles={selectStyles}
        inputId="OrganizationAutocomplete"
        TextFieldProps={{
          label: 'What organization are you a participant of?',
          InputLabelProps: {
            htmlFor: 'OrganizationAutocomplete',
            shrink: true,
          },
        }}
        isCreatable={true}
        placeholder="Organization"
        options={suggestions}
        components={components}
        value={single}
        onChange={handleChangeSingle}
        formatCreateLabel={(userInput) => `Use: ${userInput}`}
      />
    </div>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

function SingleValue({ selectProps, children }) {
  return <Typography className={selectProps.classes.singleValue}>{children}</Typography>
}
function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <div>
        <div>{props.data.label}</div>
        {props.data.address && (
          <div>
            <Typography className={props.selectProps.classes.subLabel}>
              {props.data.address.address1}, {props.data.address.city}, {props.data.address.state}
              {props.data.address.postalCode}
            </Typography>
          </div>
        )}
      </div>
    </MenuItem>
  )
}

const components = {
  Control,
  Option,
  SingleValue,
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 16,
    marginBottom: 16,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  singleValue: {
    fontSize: 16,
    color: lighten('#000', 0.4),
  },
  placeholder: {
    position: 'absolute',
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  subLabel: {
    fontSize: 13,
    color: theme.palette.gray.main,
  },
}))
