import React, { Fragment } from 'react'

function QuestionsAnswers(props) {
  const { sections } = props

  const questionAnswerContent = (
    <div>
      {sections.bodyTitle}
      {sections.bodyText}
    </div>
  )

  return <Fragment>{questionAnswerContent}</Fragment>
}

export default QuestionsAnswers
