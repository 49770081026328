import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  rfiItem: {
    display: 'flex',
    marginBottom: 30,
    border: '1px solid #efefef',
    boxShadow: '3px 3px 5px 1px rgba(0,0,0,0.1)',
    background: 'white',
    textDecoration: 'none',
    color: '#414141',
    '&:hover': {
      border: '1px solid #42949f',
      boxShadow: '0px 0px 3px 2px #42949f'
    }
  },
  linkItem: {
    color: theme.palette.primary.main,
    marginTop: 10,
    textDecoration: 'underline',
    display: 'block'
  },
  imageWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: 220,
    borderRight: '1px solid #efefef'
  },
  rfiImg: {
    margin: '0px auto 0px',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    maxHeight: 220
  },
  textWrap: {
    paddingLeft: 40,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  }
}))

const RfiListItem = ({ title, excerpt, image, slug }) => {
  const classes = useStyles()
  const imgsrc = image ? image : '/iso-doc.svg'
  return (
    <Grid container className={classes.rfiItem} component={RouterLink} to={`/rfi/${slug}`}>
      <Grid item sm={3} xs={12} className={classes.imageWrap}>
        <img src={imgsrc} alt="Document Icon" className={classes.rfiImg} />
      </Grid>
      <Grid item sm={9} xs={12} className={classes.textWrap}>
        {title === 'General Use' ? (
          <h2>RFI templates for general use</h2>
        ) : (
          <h2>RFI template for {title}</h2>
        )}
        <Typography>{excerpt}</Typography>
        <Link className={classes.linkItem} component={Typography} variant="body">
          {title === 'General Use'
            ? 'Access the RFI templates'
            : `Access the ${title} RFI template`}
        </Link>
      </Grid>
    </Grid>
  )
}

export default RfiListItem
