import { makeStyles, createMuiTheme } from '@material-ui/core/styles'
const muiTheme = createMuiTheme()

const theme = {
  typography: {
    fontFamily: ['Montserrat', 'Roboto']
  },
  palette: {
    text: {
      primary: '#414141'
      // secondary: '#414141'
    },
    background: {
      default: '#FFFFFF'
    },
    gray: {
      main: '#414141'
    },
    gray2: {
      main: '#F2F2F2'
    },
    gray3: {
      main: '#F3F3F3'
    },
    gray4: {
      main: '#B6B6B6'
    },
    gray5: {
      main: '#F7F7F7'
    },
    gray6: {
      main: '#9e9e9e'
    },
    gray7: {
      main: '#e6e6e6'
    },
    gray8: {
      main: '#737373'
    },
    gray9: {
      main: '#939393'
    },
    gray10: {
      main: '#F6F6F6'
    },
    gray11: {
      main: '#D2D2D2'
    },
    red: {
      main: '#F1634E'
    },
    darkGray: {
      main: '#414141'
    },
    navDarkGray: {
      main: '#414141'
    },
    navHoverGray: {
      main: '#505050'
    },
    navHoverLightGray: {
      main: '#b2b2b2'
    },
    navBorderGray: {
      main: '#616161'
    },
    borderGray: {
      main: '#EAEAEA'
    },
    placeholderGray: {
      main: '#a2a2a2'
    },
    lightGreen: {
      main: '#f0f7f6'
    },
    green: {
      main: '#b6d452'
    },
    darkGreen: {
      main: '#5f7b23'
    },
    lightBlue: {
      main: '#f1f8f9'
    },
    orange: {
      main: '#f09580'
    },
    primary: {
      main: '#29818C'
    },
    secondary: {
      main: '#29818C' // intentionally the same as primary
    },
    linkGrey: {
      main: '#999999'
    },
    // status colors
    success: {
      main: '#E6EFC9'
    },
    invited: {
      main: '#F2CEA7'
    },
    pending: {
      main: '#F9E9A3'
    },
    suspended: {
      main: '#FAE1EB'
    },
    declined: {
      main: '#EDEDED'
    },
    nofly: {
      main: '#FFFFFF'
    },
    lead: {
      main: '#DDEAFB'
    }
  },
  breakpoints: {
    values: {
      ...muiTheme.breakpoints.values,
      md: 1080
    }
  },
  overrides: {
    MuiTableCell: {
      body: {
        color: '#414141'
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px !important'
      }
    }
  },
  sizes: {
    desktop: {
      pageWidth: 1080
    }
  },
  styleGuide: {
    // migration planning. eventually this can go away and be integrated above.
    typography: {
      ...muiTheme.typography,
      fontFamily: ['Montserrat', 'Roboto'],
      h1: {
        ...muiTheme.typography.h1,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '72px',
        fontSize: 48
      },
      h2: {
        ...muiTheme.typography.h2,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '54px',
        fontSize: 36
      },
      h3: {
        ...muiTheme.typography.h3,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '39px',
        fontSize: 26
      },
      h4: {
        ...muiTheme.typography.h4,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '32px',
        fontSize: 24
      },
      h5: {
        ...muiTheme.typography.h5,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '24px',
        fontSize: 18
      },
      h6: {
        ...muiTheme.typography.h6,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '24px',
        fontSize: 16,
        fontWeight: 600
      },
      subtitle1: {
        ...muiTheme.typography.subtitle1,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '32px',
        fontSize: 21,
        fontWeight: 600
      },
      subtitle2: {
        ...muiTheme.typography.subtitle2,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '27px',
        fontSize: 18,
        fontWeight: 600
      },
      body1: {
        ...muiTheme.typography.body1,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '29px',
        fontSize: 16,
        fontWeight: 400
      },
      body2: {
        // used for "Intro body" in the style guide
        ...muiTheme.typography.body2,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '36px',
        fontSize: 21,
        fontWeight: 300
      },
      caption: {
        ...muiTheme.typography.caption,
        fontFamily: ['Montserrat', 'san-serif'],
        lineHeight: '18px',
        fontSize: 14,
        fontWeight: 400
      },
      button: {
        ...muiTheme.typography.button,
        fontFamily: ['Montserrat', 'san-serif']
      }
    },
    overrides: {
      MuiTableCell: {
        body: {
          color: '#414141'
        }
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none'
        }
      },
      MuiList: {
        root: {
          listStyle: 'disc',
          marginLeft: 44
        }
      },
      MuiListItem: {
        root: {
          display: 'list-item',
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '24px'
        },
        gutters: {
          paddingLeft: 14
        }
      },
      MuiButton: {
        contained: {
          boxShadow: 'none'
        }
      }
    }
  }
}

const themeStyles = makeStyles(theme => ({
  pageContentMargin: {
    marginTop: 50
  }
}))

// migration planning. Eventually this can go away and be integrated with 'theme'
const tipCoreTheme = theme.styleGuide
const themeStylesTip = makeStyles(tipCoreTheme => ({
  pageContentMargin: {
    marginTop: 50
  },
  standardImg: {
    maxWidth: '100%',
    height: 'auto'
  },
  disclaimerConsent: {
    lineHeight: '15px',
    fontSize: 12,
    fontWeight: 400
  },
  tipCore: {
    // '& img': {
    //   maxWidth: '100%',
    //   height: 'auto'
    // }
  }
}))

export default theme

export { themeStyles, themeStylesTip, tipCoreTheme }
