import React from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { SUPPORT_EMAIL } from './utils/constants'

const styles = theme => ({
  bold: { fontWeight: 700 },
  image: {
    maxWidth: '100%',
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5
    }
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, info: '', error: '' }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, info, error })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);

    // log error page view to google analytics
    if (window.dataLayer && window.dataLayer.push) {
      let obj = {
        event: 'virtualPageview',
        virtualPageURL: '/vpv/error',
        error: true
      }

      window.dataLayer.push(obj)
    }
  }

  render() {
    const { classes } = this.props
    if (this.state.hasError) {
      let errorMessage = this.state.error.message
      // You can render any custom fallback UI

      let body = `Repro Steps:
      \n1. {PLEASE TELL US WHAT YOU DID TO GET HERE}
      \n\n----------------------------------------
      \nThe Error: \n${errorMessage}`
      body = encodeURIComponent(body)
      return (
        <Grid container>
          <Typography gutterBottom variant="h3">
            Sorry, something went wrong :(
          </Typography>
          <Typography gutterBottom variant="h4">
            Please try again or{' '}
            <a
              href={`mailto:${SUPPORT_EMAIL}?cc=tipdevops@launchcg.com&body=${body}&subject=TIP%20-Something%20Went%20Wrong`}
            >
              click here to Contact TIP Support.
            </a>
            <br />
          </Typography>
          <Typography paragraph>
            <span className={classes.bold}>
              *Please add the steps you took to get here to the email, the error will automatically
              be added.
            </span>
          </Typography>
          <img
            className={classes.image}
            alt="error-cat"
            src="https://tipcontent.blob.core.windows.net/media/errorCat.jpg"
          />
        </Grid>
      )
    }
    return this.props.children
  }
}

export default withStyles(styles)(ErrorBoundary)
