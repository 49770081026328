import React, { useEffect, useCallback, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import { Redirect } from 'react-router-dom'
import { useFormState } from 'react-use-form-state'
import { ReCaptcha } from 'react-recaptcha-v3'

import { resetStore } from '../middleware/api'
import InputTextField from '../common/InputTextField'
import CheckboxField from '../common/CheckboxField'
import Progress from '../common/Progress'
import RoundedButton from '../common/RoundedButton'
import LinkField from '../common/LinkField'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'
import { themeStyles } from '../theme'
import config from '../config'

export default function CreateAccount(props) {
  const [verifyCaptcha, setVerifyCaptcha] = useState(false)
  const [formState, { text, password, checkbox }] = useFormState()
  const { loading, execute: handleCreateAccount, data, error } = useMutation(CREATE_ACCOUNT)

  const formClasses = useFormStyles()
  const classes = useStyles()
  const themeClasses = themeStyles()

  const {
    loading: verifyTokenLoading,
    execute: handleVerifyToken,
    data: verifyTokenResult,
    error: verifyTokenError
  } = useMutation(VERIFY_TOKEN)

  let acceptedInvite, organizationId, email, errorMessage

  const params = new URLSearchParams(props.location.search)
  const token = params.get('token')

  const handleVerifyTokenCallback = useCallback(handleVerifyToken, [])

  useEffect(() => {
    handleVerifyTokenCallback({ values: { token } })
  }, [token, handleVerifyTokenCallback])

  useEffect(() => {
    if (!loading && !error && data) {
      resetStore()
    }
  }, [data, loading, error])

  if (data && !loading) {
    return <Redirect to="/" />
  }

  if (!token) {
    errorMessage = 'Missing email verification token.'
  } else {
    if (!verifyTokenLoading && verifyTokenError) {
      errorMessage = verifyTokenError.graphQLErrors[0].message
    }

    if (verifyTokenResult && !errorMessage) {
      email = verifyTokenResult.verifyToken.email
      organizationId = verifyTokenResult.verifyToken.organizationId
    }
  }

  function createAccount(e) {
    e.preventDefault()
    if (acceptedInvite) {
      handleCreateAccount({
        values: {
          ...formState.values,
          email,
          organizationId,
          token: token
        }
      })
    } else {
      handleCreateAccount({
        values: {
          ...formState.values,
          email,
          token: token
        }
      })
    }
  }

  const inputs = {
    firstname: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 6 },
      name: 'firstname',
      label: 'First Name',
      inputType: text,
      autoFocus: true,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    lastname: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 6 },
      name: 'lastname',
      label: 'Last Name',
      autoComplete: 'lastname',
      inputType: text,
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50
      }
    },
    password: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12 },
      name: 'password',
      label: 'Password',
      inputType: password,
      autoComplete: 'new-password',
      validator: {
        required: true,
        regex: inputRegexes.password,
        regexMessage: 'Must be 8 characters long and have at least one letter, number, and symbol.'
      },
      helperText: 'Create a strong password to protect your TIP Account.'
    },
    confirmPassword: {
      InputComponent: InputTextField,
      gridWidth: { xs: 12 },
      name: 'confirmPassword',
      label: 'Confirm Password',
      inputType: password,
      autoComplete: 'new-password',
      validator: {
        required: true,
        mustMatchKey: 'password',
        mustMatchMessage: 'Passwords must match'
      },
      helperText: 'Re-type your password.'
    },
    tipPolicyCheckbox: {
      InputComponent: CheckboxField,
      gridWidth: { xs: 12 },
      name: 'tipPolicyCheckbox',
      testid: 'tipPolicyCheckbox',
      label: <TipCheckboxLabel />,
      inputType: checkbox,
      labelPlacement: 'end',
      validator: {
        required: true
      }
    }
  }

  function verifyCallback(recaptchaToken) {
    setVerifyCaptcha(true)
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  if (!verifyCaptcha) {
    return (
      <div>
        <ReCaptcha
          sitekey={config.RECAPTCHA_PUBKEY}
          action="action_name"
          verifyCallback={verifyCallback}
        />
        <Progress size={30} delay={2} />
      </div>
    )
  } else {
    return (
      <form onSubmit={createAccount} className={themeClasses.pageContentMargin}>
        <Grid container justify="center" data-testid="CreateAccountContainer">
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={6}
            className={clsx(formClasses.container, classes.container)}
          >
            {verifyTokenLoading ? (
              <Progress />
            ) : (
              <>
                <div className={clsx(formClasses.header, classes.header)}>
                  <Typography role="heading" variant="h3" className={formClasses.heading}>
                    Create Account
                  </Typography>
                  {errorMessage ? (
                    <>
                      <Typography role="alert" color="error">
                        {errorMessage}
                      </Typography>

                      <Button
                        color="primary"
                        className={classes.button}
                        href="mailto:support@telecominfraproject.com"
                      >
                        Click here to Contact Support
                      </Button>
                    </>
                  ) : (
                    <Typography className={classes.emailLabel}>Registering as: {email}</Typography>
                  )}
                </div>
                {!errorMessage && (
                  <>
                    <div className={classes.alert}>
                      {error && (
                        <Typography role="alert" color="error">
                          {error.graphQLErrors[0].message}
                        </Typography>
                      )}
                    </div>
                    <Grid className={formClasses.spacingBottom} container spacing={2}>
                      {Object.entries(inputs).map(
                        ([name, { InputComponent, helperText, ...args }]) => (
                          <Grid key={name} item {...args.gridWidth}>
                            <InputComponent
                              key={name}
                              {...args}
                              error={formState.errors[name] ? true : false}
                              helperText={formState.errors[name] || helperText}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                    <div className={formClasses.buttonContainer}>
                      {loading ? (
                        <div className={formClasses.progress}>
                          <Progress size={30} />
                        </div>
                      ) : (
                        <RoundedButton
                          onClick={createAccount}
                          disabled={errorMessage || disableSubmit}
                          data-testid="CreateAccountBtn"
                          fullWidth
                          className={formClasses.roundedFullWidthButton}
                          type="submit"
                        >
                          Create account
                        </RoundedButton>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            <br />
            <Typography className={classes.recaptchaRemark}>
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy" className={classes.recaptchaLink}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms" className={classes.recaptchaLink}>
                Terms of Service
              </a>{' '}
              apply.
            </Typography>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export const CREATE_ACCOUNT = gql`
  mutation($input: AccountCreationInput!) {
    createAccount(input: $input) {
      data
      result
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation($input: TokenVerifyInput!) {
    verifyToken(input: $input) {
      result
      email
    }
  }
`

function TipCheckboxLabel() {
  return (
    <div>
      I understand and agree to the TIP{' '}
      <LinkField
        label="Privacy Policy"
        to="https://telecominfraproject.com/legal-and-privacy-policies/"
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '50px 100px',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px'
    }
  },
  emailLabel: {
    color: theme.palette.primary.main
  },
  recaptchaRemark: {
    fontSize: '12px',
    alignItems: 'center'
  },
  recaptchaLink: {
    color: '#737373'
  }
}))
