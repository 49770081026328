import gql from 'graphql-tag'

export const GET_PUBLIC_ORGANIZATIONS_PREVIEW = gql`
  query vendingOrganizationsPreview {
    vendingOrganizations(includePreviews: true) {
      id
      name
      description
      logo
    }
  }
`

export const GET_PUBLIC_ORGANIZATIONS = gql`
  query vendingOrganizations {
    vendingOrganizations {
      id
      name
      description
      logo
    }
  }
`

export const GET_ORGANIZATION = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      name
      description
      logo
      profile
      offerings {
        id
        name
        media {
          imageName
          defaultImage
        }
        badges {
          id
          name
          description
          iconFile
          badgeFile
        }
      }
      membership {
        membershipLevelId
      }
    }
  }
`

export const GET_MEMBERSHIP_LEVEL = gql`
  query MembershipLevel($id: ID!) {
    membershipLevel(id: $id) {
      id
      name
      price
      atlassianAccountsAllowed
      canAccessRFI
      canAccessTSS
    }
  }
`
