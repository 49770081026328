import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'

import InputTextField from '../common/InputTextField'
import RoundedButton from '../common/RoundedButton'
import Progress from '../common/Progress'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'
import { themeStyles } from '../theme'

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation($input: EmailInput!) {
    sendResetPasswordEmail(input: $input) {
      result
      data
    }
  }
`

export default function ForgotPassword() {
  const [formState, { email }] = useFormState()
  const { loading, execute: handleResetPassword, data, error } = useMutation(
    SEND_RESET_PASSWORD_EMAIL
  )
  const classes = useFormStyles()
  const themeClasses = themeStyles()

  const inputs = {
    email: {
      name: 'email',
      label: 'Email',
      inputType: email,
      autoFocus: true,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      },
      touchOnChange: true,
      variant: 'outlined'
    }
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  function handleSubmit(e) {
    e.preventDefault()
    handleResetPassword({ values: formState.values })
  }

  return (
    <form onSubmit={handleSubmit} className={themeClasses.pageContentMargin}>
      <Grid container justify="center" className={classes.marginBotom}>
        <Grid item xs={12} sm={12} md={7} lg={6} className={classes.container}>
          <div className={classes.header}>
            <Typography role="heading" variant="h3" className={classes.heading}>
              Forgot Password?
            </Typography>
          </div>
          <div className={classes.alert}>
            {data && data.sendResetPasswordEmail.result && (
              <Typography role="alert" classes={classes.success}>
                {data.sendResetPasswordEmail.data}
              </Typography>
            )}
            {error && (
              <Typography role="alert" color="error">
                {error.graphQLErrors[0].message}
              </Typography>
            )}
          </div>
          {Object.entries(inputs).map(([name, args]) => (
            <InputTextField
              key={name}
              {...args}
              error={formState.errors[name] ? true : false}
              helperText={formState.errors[name]}
            />
          ))}
          <div className={classes.buttonRightContainer}>
            {loading ? (
              <Progress size={30} delay={0} />
            ) : (
              <RoundedButton
                disabled={disableSubmit}
                data-testid="send-email" // for tests ONLY
                fullWidth={false}
                type="submit"
              >
                Send Email
              </RoundedButton>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
