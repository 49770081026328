import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { renderMarkdown } from '../../utils/pageContentHelper'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 30
  },
  image: {
    height: 'auto',
    maxHeight: 150,
    display: 'inline-block'
  },
  badgeTitle: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center'
  },
  learnMoreTitle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  learnMoreText: {
    fontSize: 12
  },
  icon: {
    width: 25,
    height: 25,
    display: 'inline-block',
    marginRight: 10
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
  indent: {
    marginLeft: 20,
    whiteSpace: 'pre-wrap'
  }
})

class BadgeListItem extends Component {
  render() {
    const { classes, image, icon, title, body } = this.props

    const typeIcon = <img className={classes.icon} src={icon} alt={title} />

    return (
      <Grid id={title} container spacing={0} className={classes.root}>
        <Grid container item spacing={2}>
          <Grid item sm={2} xs={12} style={{ textAlign: 'center' }}>
            <img className={classes.image} src={image} alt={title} />
          </Grid>
          <Grid item sm={8} xs={12}>
            <div className={classes.badgeTitle}>
              {typeIcon}
              <Typography variant="h6">{title}</Typography>
            </div>
            {renderMarkdown(body)}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(BadgeListItem)
