// non-Launch
import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { MuiThemeProvider } from '@material-ui/core/styles'
import clsx from 'clsx'

// Launch
import { themeStylesTip, tipCoreTheme } from '../../theme'
import MediaGallery from '../../common/MediaGallery'
import WidthContainer from '../../common/WidthContainer'
import SolutionDocuments from './Sections/SolutionDocuments'
import OemPartners from './Sections/OemPartners'
import DiagBG from './images/ultraRural/TSS-Page-HeaderBG.png'
import UltraRuralIcon from './images/ultraRural/Icon-Ultra-Rural.png'
import SiteOptimizationElements from './images/ultraRural/TSS-Ultra-Rural-Site-Optimization-Elements-Infographic.png'
import DownCircle from './images/ultraRural/arrow_circle_down-24px.svg'
import { usePageContent, getSection } from '../../utils/pageContentHelper'
import PageTitleHead from '../../common/PageTitleHead'
import config from '../../config'

// images
import image609 from './images/ultraRural/gallery/image609.png'
import image610 from './images/ultraRural/gallery/teamwork-makes-the-dreamwork.jpg'
import image611 from './images/ultraRural/gallery/plugfest-gear-pics.jpg'
import imaged1 from './images/ultraRural/gallery/dish1.jpg'
import imaged2 from './images/ultraRural/gallery/dish2.jpg'
import imaged3 from './images/ultraRural/gallery/dish3.jpg'
import imaged4 from './images/ultraRural/gallery/dish4.jpg'
import imagedb1 from './images/ultraRural/gallery/dbuild1.jpg'
import imagedb2 from './images/ultraRural/gallery/dbuild2.jpg'
import imagedb3 from './images/ultraRural/gallery/dbuild3.jpg'

const media = [
  {
    original: image609,
    thumbnail: image609,
    type: 'image'
  },
  {
    original: image610,
    thumbnail: image610,
    type: 'image'
  },
  {
    original: image611,
    thumbnail: image611,
    type: 'image'
  },
  {
    original: imaged1,
    thumbnail: imaged1,
    type: 'image'
  },
  {
    original: imaged2,
    thumbnail: imaged2,
    type: 'image'
  },
  {
    original: imaged3,
    thumbnail: imaged3,
    type: 'image'
  },
  {
    original: imaged4,
    thumbnail: imaged4,
    type: 'image'
  },
  {
    original: imagedb1,
    thumbnail: imagedb1,
    type: 'image'
  },
  {
    original: imagedb2,
    thumbnail: imagedb2,
    type: 'image'
  },
  {
    original: imagedb3,
    thumbnail: imagedb3,
    type: 'image'
  }
]

const useStyles = makeStyles(theme => {
  return {
    adjustTop: {},
    videoContainer: {
      border: 'solid 2px black'
    },
    standardLink: {
      color: theme.palette.primary.main,
      textDecorationColor: '#c1c1c1'
    },
    pageTitle: {
      marginBottom: 24,
      fontSize: 60,
      alignSelf: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20
      }
    },
    headerPart: {
      backgroundColor: '#DDEEf1',
      backgroundImage: `url(${DiagBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(50% + 790px) center',
      backgroundSize: '1310px 243px',
      padding: '42px 0 32px 0',
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: 'calc(50% + 890px) center'
      },
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '1350px 263px',
        backgroundPosition: 'calc(50% + 800px) bottom'
      }
    },

    subtitle: {
      display: 'block',
      maxWidth: 700
    },
    bamCard: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      '&:hover $bamCardShell': {
        backgroundColor: '#94C135'
      },
      '&:hover $bamCardBody': {
        backgroundColor: '#D3EDF2'
      },
      '&:hover $dArrow': {
        marginTop: 8,
        marginBottom: 0
      }
    },
    bamCardShell: {
      backgroundColor: '#A5CF4C',
      color: 'white',
      fontSize: '21px',
      textAlign: 'center',
      paddingTop: 10,
      width: '100%',
      transition: '0.3s ease-out all',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    bamCardBody: {
      backgroundColor: '#DDEEEF',
      color: '#29818C',
      fontSize: '12px',
      textAlign: 'center',
      marginTop: 10,
      width: '100%',
      transition: '0.3s ease-out all'
    },
    dArrow: {
      position: 'relative',
      marginTop: 0,
      marginBottom: 8,
      transition: '0.3s ease-out all'
    },
    solutionVideo: {
      width: 1080,
      height: 620,

      [theme.breakpoints.down(800)]: {
        width: 500,
        height: 300
      },
      [theme.breakpoints.down(550)]: {
        width: 300,
        height: 180
      },

      [theme.breakpoints.down(330)]: {
        width: 250,
        height: 140
      }
    },
    ltBluePart: {
      backgroundColor: '#DDEEEF'
    },
    spacedText: {
      lineHeight: '33px !important',
      fontSize: 18
    },
    galleryContainer: {
      marginTop: 56,
      marginBottom: 32,
      width: 1080,
      margin: '0 auto',
      '& img': {
        maxWidth: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'auto'
      }
    },
    invisibleAnchor: {
      marginTop: -120
    },
    greenbox: {
      background: '#2e666e',
      color: 'white',
      padding: '32px 64px 12px'
    },
    mainLower: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 64,
      background: `url(${
        config.MEDIA_CDN_DOMAIN
      }/media/Page-Bottom.svg) bottom left no-repeat white`,
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    }
  }
})

const UltraRural = () => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()
  const scrollRefUltraRural = useRef(null)

  // load page content and sections
  const { data: pageContent = [], error: pageContentError } = usePageContent('TSS Ultra Rural')
  const sections = {
    pageTitle: getSection(pageContent, 'Page Title'),
    pageDescription: getSection(pageContent, 'Page Description'),
    heading1: getSection(pageContent, 'Heading 1', <Typography variant="h2" paragraph mt={7} />),
    paragraph1: getSection(pageContent, 'Paragraph 1'),
    heading2: getSection(pageContent, 'Heading 2', <Typography variant="h2" paragraph mt={7} />),
    paragraph2: getSection(pageContent, 'Paragraph 2'),
    heading3: getSection(pageContent, 'Heading 3', <Typography variant="h2" paragraph />),
    documentsTitle: getSection(
      pageContent,
      'Documents Title',
      <Typography variant="h2" paragraph />
    ),
    documentsDescription: getSection(pageContent, 'Documents Description'),
    benefitsList: getSection(pageContent, 'Benefits List'),
    heading4: getSection(pageContent, 'Heading 4', <Typography variant="h3" paragraph />),
    ruralSiteConfig: getSection(pageContent, 'Rural Site Configuration PlugFest workshop'),
    tssTitle: getSection(pageContent, 'TSS Title', <Typography variant="body2" />),
    useCaseTitle: getSection(pageContent, 'Use Case Title', <Typography variant="h2" paragraph />),
    useCaseDescription: getSection(
      pageContent,
      'Use Case Description',
      <div className={classes.spacedText} />
    ),
    oemPartners: getSection(pageContent, 'OEM Partners'),
    vidUrl: getSection(pageContent, 'The Solution Video URL')
  }

  const executeScrollUltraRural = () =>
    scrollRefUltraRural.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })

  if (pageContentError) {
    console.error('Error loading page content:', pageContentError)
  }

  console.log('OEM', sections.oemPartners)

  const benefitItems =
    sections.benefitsList && sections.benefitsList.items ? sections.benefitsList.items : []

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <Grid container className={clsx(classes.adjustTop, themeClasses.tipCore)}>
        <PageTitleHead title={sections.pageTitle} description={sections.pageDescription} />
        <WidthContainer className={classes.columnWrapper}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box mt={8} mr={{ xs: 1, sm: 3, md: 16 }}>
                {sections.heading1}
                {sections.paragraph1}
              </Box>
            </Grid>
            <Grid item xs={12} md={3} className={classes.bamCard} onClick={executeScrollUltraRural}>
              <Box className={classes.bamCardShell}>
                Ultra rural use case
                <Box className={classes.bamCardBody}>
                  <Box pt={2}>
                    <img src={UltraRuralIcon} alt="Ultra Rural icon" />
                  </Box>
                  <Typography>See more details</Typography>
                  <Box mt={1} pb={2}>
                    <img className={classes.dArrow} src={DownCircle} alt="down circle icon" />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12}>
              <Box mt={5} mb={0}>
                {sections.heading2}
                {sections.paragraph2}
                {sections.vidUrl && typeof sections.vidUrl === 'string' ? (
                  <Grid item xs={12}>
                    <Box mr={2} mb={0}>
                      <iframe
                        title="iframe video tss"
                        className={classes.solutionVideo}
                        src={sections.vidUrl}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                      />
                    </Box>
                  </Grid>
                ) : null}
              </Box>
            </Grid>
          </Grid>

          <Grid container className={classes.greenbox}>
            <Grid item xs={12} sm={12}>
              {sections.heading3}
              <Box pt={1} mb={5}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    {benefitItems.map((bene, bdx) =>
                      bdx < benefitItems.length / 2 ? (
                        bene.type === 'head' ? (
                          bene.values.map((val, vdx) => (
                            <Typography variant="h6" key={`bvhead-${bdx}-${vdx}`}>
                              {val}
                            </Typography>
                          ))
                        ) : (
                          <List key={`bvlist-${bdx}`}>
                            {bene.values.map((val, vdx) => (
                              <ListItem key={`bvitem-${bdx}-${vdx}`}>{val}</ListItem>
                            ))}
                          </List>
                        )
                      ) : null
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {benefitItems.map((bene, bdx) =>
                      bdx >= benefitItems.length / 2 ? (
                        bene.type === 'head' ? (
                          bene.values.map((val, vdx) => (
                            <Typography variant="h6" key={`bvhead-${bdx}-${vdx}`}>
                              {val}
                            </Typography>
                          ))
                        ) : (
                          <List key={`bvlist-${bdx}`}>
                            {bene.values.map((val, vdx) => (
                              <ListItem key={`bvitem-${bdx}-${vdx}`}>{val}</ListItem>
                            ))}
                          </List>
                        )
                      ) : null
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <SolutionDocuments sections={sections} />
            <div className={classes.invisibleAnchor} ref={scrollRefUltraRural}>
              &nbsp;
            </div>
          </Grid>
        </WidthContainer>
        <Grid container className={classes.ltBluePart}>
          <WidthContainer className={classes.columnWrapper}>
            <Box pt={6} pb={2}>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Box mr={3} pb={6}>
                    {sections.tssTitle}
                    {sections.useCaseTitle}
                    {sections.useCaseDescription}
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Box m="auto" textAlign="center" mb={8}>
                    <img
                      className={themeClasses.standardImg}
                      src={SiteOptimizationElements}
                      alt="Site Optimization Elements Diagram"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </WidthContainer>
        </Grid>
        <OemPartners data={sections.oemPartners} />

        <WidthContainer className={classes.mainLower}>
          <Grid container className={classes.galleryContainer}>
            <Grid item sm={12} md={8}>
              <Box pr={4}>
                {sections.heading4}
                {sections.ruralSiteConfig}
              </Box>
            </Grid>
            <Grid item xs={11} sm={10} md={4}>
              <MediaGallery media={media} isShorter />
            </Grid>
          </Grid>
        </WidthContainer>
      </Grid>
    </MuiThemeProvider>
  )
}

export default UltraRural
