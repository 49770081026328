import { useMutation as mutation } from '@apollo/react-hooks'

export default function useMutation(m, options) {
  const [func, { loading, error, data }] = mutation(m, options)

  const execute = ({ id, values } = {}) => {
    if (id && values) {
      return func({
        variables: {
          id: id,
          input: {
            ...values
          }
        }
      })
    } else if (values)
      return func({
        variables: {
          input: {
            ...values
          }
        }
      })
    else return func()
  }

  return {
    loading,
    data,
    error,
    execute
  }
}
