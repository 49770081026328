import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import clsx from 'clsx'

import WidthContainer from '../common/WidthContainer'
//import { themeStylesTip } from '../theme'

const useStyles = makeStyles(theme => {
  return {
    scatWrapper: {
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 800,
        overflowX: 'scroll'
      }
    },
    oemArea: {
      marginTop: -10,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '200%'
      }
    },
    oemWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    oemCategories: {
      background: 'white',
      position: 'sticky',
      top: 200,
      zIndex: 18,
      border: '1px #CDE5E8 solid',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      }
    },
    variantCombos: {
      border: '1px #CDE5E8 solid',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      },
      '&:hover': {
        background: '#FAFCF5'
      }
    },
    subtitle: {
      display: 'block',
      maxWidth: 700
    },
    tempCCC: {
      padding: '16px 24px'
    },
    tempColHead: {
      fontWeight: 500,
      marginTop: 12,
      marginBottom: 8,
      maxWidth: '13%',
      flexBasis: '13%',
      margin: '0 auto',
      textAlign: 'center'
    },
    ltBluePart: {
      backgroundColor: '#DDEEEF'
    },
    spacedText: {
      lineHeight: '33px'
    },
    colorBar: {
      backgroundColor: '#3BA9B6',
      color: 'white',
      width: '100%',
      padding: 0,
      position: 'sticky',
      top: 130,
      zIndex: 20,
      [theme.breakpoints.down('xs')]: {
        width: '150%'
      }
    },
    grid5col: {
      maxWidth: '20%',
      flexBasis: '20%',
      margin: '0 auto',
      textAlign: 'center',
      //border: '1px #CDE5E8 solid',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
        width: 200,
        flexBasis: 200,
        flex: 'none'
      }
    },
    grid5col2nd: {
      backgroundColor: '#F1F8F9',
      opacity: 0.7
    },
    oemIconBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: 150
    },
    oemCategoryIcon: {
      maxWidth: '50%',
      maxHeight: 131,
      height: 'auto'
    },
    scroller: {
      height: 600,
      overflowY: 'scroll'
    },
    partnerBox: {
      border: '1px solid #74C7CE',
      borderRadius: 4,
      height: 120,
      background: '#FAFCF5'
    },
    pName: {
      color: '#29818C',
      textDecoration: 'none',
      textDecorationColor: '#D2D2D2'
    },
    caption12: {
      fontSize: 12
    },
    prName: {
      color: '#29818C',
      textDecoration: 'underline',
      textDecorationColor: '#D2D2D2',
      fontSize: 12
    },
    productArea: {}
  }
})

const Variants = ({ data }) => {
  const classes = useStyles()
  //const themeClasses = themeStylesTip()
  //temp...mockup style ;)

  const variant_headers = [
    {
      img: '',
      label: 'Tested Variant',
      isSecondary: false
    },
    {
      img:
        'https://tipcontent.blob.core.windows.net/media/exchange-pages/marketplace/tss_ultra-rural/Icon-RAN@2x.b31add87.png',
      label: 'RAN',
      isSecondary: false
    },
    {
      img:
        'https://tipcontent.blob.core.windows.net/media/exchange-pages/marketplace/tss_ultra-rural/Icon-VSAT@2x.5a492f52.png',
      label: 'VSAT',
      isSecondary: false
    },
    {
      img:
        'https://tipcontent.blob.core.windows.net/media/exchange-pages/marketplace/tss_ultra-rural/Icon-Power-System@2x.6a7e8ed7 (1).png',
      label: 'Power System',
      isSecondary: false
    },
    {
      img:
        'https://tipcontent.blob.core.windows.net/media/exchange-pages/marketplace/tss_ultra-rural/Icon-Test-Equipment@2x.8910c07d.png',
      label: 'TCP Accelerator',
      isSecondary: false
    }
  ]

  const tested_variants = [
    [
      {
        partnerName: '1',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'AirHarmony 4200 CBSD',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/602ebefc683ac60012247364'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Skyedge II-C Capricorn-4 S2X',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4d46b32425001329f993'
          },
          {
            productFeature: 'Skyedge II-C X-Architecture Hub',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4dfcb32425001329f994'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'integrated in vSAT',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '2',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Nova 246',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/603e765bb75cce00181aef0e'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Skyedge II-C Capricorn-4 S2X',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4d46b32425001329f993'
          },
          {
            productFeature: 'Skyedge II-C X-Architecture Hub',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4dfcb32425001329f994'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'integrated in vSAT',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '3',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'CWS 3050',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5dafcdeb8a8193001193ecd7'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Skyedge II-C Capricorn-4 S2X',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4d46b32425001329f993'
          },
          {
            productFeature: 'Skyedge II-C X-Architecture Hub',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4dfcb32425001329f994'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'integrated in vSAT',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '4',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'VBW W2',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fdbbc7857ef06001137f60e'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Skyedge II-C Capricorn-4 S2X',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4d46b32425001329f993'
          },
          {
            productFeature: 'Skyedge II-C X-Architecture Hub',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f8f4dfcb32425001329f994'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'integrated in vSAT',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '5',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'AirHarmony 4200 CBSD',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/602ebefc683ac60012247364'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Evolution X1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fa9d1797f75d300121b3e19'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Not Used',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '6',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Nova 246',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/603e765bb75cce00181aef0e'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Evolution X1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fa9d1797f75d300121b3e19'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Not Used',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '7',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'CWS 3050',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5dafcdeb8a8193001193ecd7'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Evolution X1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fa9d1797f75d300121b3e19'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Not Used',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '8',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'VBW W2',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fdbbc7857ef06001137f60e'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Evolution X1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fa9d1797f75d300121b3e19'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Not Used',
            productLink: ''
          }
        ]
      }
    ],
    [
      {
        partnerName: '9',
        products: []
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Nova 246',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/603e765bb75cce00181aef0e'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'Evolution X1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fa9d1797f75d300121b3e19'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'TS-MPPT-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd292263f9ee0001130c7af'
          },
          {
            productFeature: 'TS-45',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fd295004896b0001912edf2'
          },
          {
            productFeature: 'EMC-1',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5fcfe3ab4896b0001912ed39'
          }
        ]
      },
      {
        partnerName: '',
        products: [
          {
            productFeature: 'XA-4000C',
            productLink:
              'https://exchange.telecominfraproject.com/marketplace/offerings/5f908745699c370011dbddf8'
          }
        ]
      }
    ]
  ]

  return (
    <div className={classes.scatWrapper}>
      <Grid container className={classes.oemArea}>
        <WidthContainer className={classes.columnWrapper}>
          <Box className={classes.colorBar} px={3} py={2}>
            <Typography variant="subtitle2" className={classes.tempCCC}>
              Each variant shown below represent a set of equipment that have been tested together
              and proven to fulfill the requirements
            </Typography>
          </Box>
          <div className={classes.oemWrapper}>
            <Grid container className={classes.oemCategories}>
              {variant_headers.map((cat, idx) => (
                <Grid
                  key={`oem-${idx}`}
                  item
                  xs={2}
                  className={
                    cat.isSecondary ? clsx(classes.grid5col, classes.grid5col2nd) : classes.grid5col
                  }
                >
                  <Box p={1}>
                    <Box className={classes.oemIconBox} pb={2}>
                      {cat.img ? (
                        <img className={classes.oemCategoryIcon} src={`${cat.img}`} alt="logo" />
                      ) : null}
                    </Box>
                    <Typography variant="h6">{cat.label}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <div className={classes.scroller}>
              {tested_variants.map((tv, tvdx) => (
                <Grid key={`tva-${tvdx}`} container className={classes.variantCombos}>
                  {tv.map((iv, ivdx) => (
                    <Grid key={`tvscrlr-${tvdx}-${ivdx}`} item xs={2} className={classes.grid5col}>
                      {iv.partnerName ? (
                        <Box my={2}>
                          <Typography className={classes.pName} variant="h6" paragraph>
                            {iv.partnerName}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box className={iv.products.length ? classes.productArea : null} p={1}>
                        {iv.products.map((item, zdx) => (
                          <Box key={`pprod-${zdx}`} my={1}>
                            {item.productLink === '' || !item.productLink ? (
                              <Typography variant="caption" className={classes.caption12}>
                                {item.productFeature}
                              </Typography>
                            ) : (
                              <a href={item.productLink} target="_blank" rel="noopener noreferrer">
                                <Typography className={classes.prName} variant="caption">
                                  {item.productFeature}
                                </Typography>
                              </a>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </div>
          </div>
        </WidthContainer>
      </Grid>
    </div>
  )
}

export default Variants
