import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  roundedButton: {
    borderRadius: 5
  }
})

const RoundedButton = ({ children, testid, disabled, onClick, className, ...rest }) => {
  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.roundedButton, className)}
      variant="contained"
      color="primary"
      fullWidth
      {...rest}
    >
      {children}
    </Button>
  )
}

export default RoundedButton
