import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import clsx from 'clsx'

import WidthContainer from '../common/WidthContainer'
import LinkField from '../common/LinkField'
import config from '../config'
//import { themeStylesTip } from '../theme'

const useStyles = makeStyles(theme => {
  return {
    scatWrapper: {
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 800,
        overflowX: 'scroll'
      }
    },
    oemArea: {
      marginTop: -10,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '200%'
      }
    },
    oemWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    oemCategories: {
      background: '#f2f8f7',
      position: 'sticky',
      top: 210,
      zIndex: 18,
      borderLeft: '1px #CDE5E8 solid',
      borderRight: '1px #CDE5E8 solid',
      borderBottom: '1px #CDE5E8 solid',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      }
    },
    preCategories: {
      background: '#f2f8f7',
      position: 'sticky',
      top: 180,
      zIndex: 18,
      borderRight: '1px #CDE5E8 solid',
      borderLeft: '1px #CDE5E8 solid',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      }
    },
    variantCombos: {
      border: '1px #CDE5E8 solid',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      },
      '&:hover': {
        background: '#ecfbf9'
      }
    },
    subtitle: {
      display: 'block',
      maxWidth: 700
    },
    tempCCC: {
      padding: '16px 24px'
    },
    tempColHead: {
      fontWeight: 500,
      marginTop: 12,
      marginBottom: 8,
      maxWidth: '13%',
      flexBasis: '13%',
      margin: '0 auto',
      textAlign: 'center'
    },
    ltBluePart: {
      backgroundColor: '#DDEEEF'
    },
    spacedText: {
      lineHeight: '33px'
    },
    colorBar: {
      backgroundColor: '#3BA9B6',
      color: 'white',
      width: '100%',
      padding: 0,
      position: 'sticky',
      top: 130,
      zIndex: 20,
      [theme.breakpoints.down('xs')]: {
        width: '150%'
      }
    },
    gridBadgeCol: {
      maxWidth: '16%',
      flexBasis: '16%',
      margin: '0 auto',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
        width: 200,
        flexBasis: 200,
        flex: 'none'
      }
    },
    gridBadgeCol2nd: {
      backgroundColor: '#F1F8F9',
      opacity: 0.7
    },
    badgeImage: {
      maxWidth: 100
    },
    oemIconBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: 150
    },
    oemCategoryIcon: {
      maxWidth: '50%',
      maxHeight: 131,
      height: 'auto'
    },
    scroller: {
      height: 896,
      overflowY: 'scroll'
    },
    partnerBox: {
      border: '1px solid #74C7CE',
      borderRadius: 4,
      height: 120,
      background: '#FAFCF5'
    },
    pName: {
      color: '#29818C',
      textDecoration: 'none',
      textDecorationColor: '#D2D2D2'
    },
    caption12: {
      fontSize: 12
    },
    prName: {
      color: '#29818C',
      textDecoration: 'underline',
      textDecorationColor: '#D2D2D2',
      fontSize: 12
    },
    productArea: {}
  }
})

const OfferingBadgeRecords = ({ data }) => {
  const classes = useStyles()

  const variant_headers = [
    {
      label: 'Ribbon/Badge',
      isSecondary: false
    },
    {
      label: 'Date Awarded',
      isSecondary: false
    },
    {
      label: 'Project Group',
      isSecondary: false
    },
    {
      label: 'Requirements Document',
      isSecondary: false
    },
    {
      img: '',
      label: 'Test Plan',
      isSecondary: false
    },
    {
      label: 'Test Report',
      isSecondary: false
    }
  ]

  return (
    <div className={classes.scatWrapper}>
      <Grid container className={classes.oemArea}>
        <WidthContainer className={classes.columnWrapper}>
          <Box className={classes.colorBar} px={3} py={2}>
            <Typography variant="subtitle2" className={classes.tempCCC}>
              Badges &amp; Ribbons Awarded to this Offering:
            </Typography>
          </Box>
          <div className={classes.oemWrapper}>
            <Grid container className={classes.preCategories}>
              <Grid item xs={6} />
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Box p={1}>
                  <Typography variant="body2" style={{ fontWeight: '700' }}>
                    Associated Documents
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.oemCategories}>
              {variant_headers.map((cat, idx) => (
                <Grid
                  key={`oem-${idx}`}
                  item
                  xs={2}
                  className={
                    cat.isSecondary
                      ? clsx(classes.gridBadgeCol, classes.gridBadgeCol2nd)
                      : classes.gridBadgeCol
                  }
                >
                  <Box p={1}>
                    <Typography variant="body2" style={idx < 3 ? { fontWeight: '600' } : null}>
                      {cat.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <div
              className={classes.scroller}
              style={{ height: data.length > 3 ? 896 : 224 * data.length }}
            >
              {data.map((br, brdx) => (
                <Grid key={`bra-${brdx}`} container className={classes.variantCombos}>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.badge.name ? (
                      <Box my={2}>
                        <img
                          src={`${config.MEDIA_CDN_DOMAIN}/media/badges/${br.badge.badgeFile}`}
                          alt="badge/ribbon"
                          className={classes.badgeImage}
                        />
                        <Typography className={classes.pName} variant="body2" paragraph>
                          {br.badge.name}
                        </Typography>
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.dateAwardedMonth || br.dateAwardedYear ? (
                      <Box my={2}>
                        <Typography className={classes.pName} variant="body2" paragraph>
                          {br.dateAwardedMonth} {br.dateAwardedYear}
                        </Typography>
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.pgName ? (
                      <Box my={2}>
                        {br.pgLink ? (
                          <LinkField to={br.pgLink} label={br.pgName} />
                        ) : (
                          <Typography className={classes.pName} variant="body2" paragraph>
                            {br.pgName}
                          </Typography>
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.documents[0] ? (
                      <Box my={2}>
                        {br.documents[0].link ? (
                          <LinkField to={br.documents[0].link} label={br.documents[0].title} />
                        ) : (
                          <Typography className={classes.pName} variant="body2" paragraph>
                            {br.documents[0].title}
                          </Typography>
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.documents[1] ? (
                      <Box my={2}>
                        {br.documents[1].link ? (
                          <LinkField to={br.documents[1].link} label={br.documents[1].title} />
                        ) : (
                          <Typography className={classes.pName} variant="body2" paragraph>
                            {br.documents[1].title}
                          </Typography>
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} className={classes.gridBadgeCol}>
                    {br.documents[2] ? (
                      <Box my={2}>
                        {br.documents[2].link ? (
                          <LinkField to={br.documents[2].link} label={br.documents[2].title} />
                        ) : (
                          <Typography className={classes.pName} variant="body2" paragraph>
                            {br.documents[2].title}
                          </Typography>
                        )}
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        </WidthContainer>
      </Grid>
    </div>
  )
}

export default OfferingBadgeRecords
