import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

import ContactUsForm from './ContactUsForm'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    '& h2': {
      fontSize: 28,
      marginTop: 12,
      textAlign: 'center'
    }
  },
  content: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  requestSentContent: {
    textAlign: 'center',
    marginBottom: 32
  },
  subTitle: {
    marginBottom: 30,
    fontWeight: 500
  },
  button: {
    marginBottom: 30
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

function DialogTitle({ children, onClose }) {
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}

function ContactUsModal({ offeringId, currentUser, open, name, handleClose }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      fullScreen={fullScreen}
      className={classes.root}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
    >
      <DialogTitle className={classes.title} onClose={handleClose}>
        Request Info
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ContactUsForm
          onSubmitSuccess={() => {
            handleClose()
            enqueueSnackbar('Email Sent!', {
              variant: 'success',
              autoHideDuration: 2500
            })
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ContactUsModal
