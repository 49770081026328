import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import DialogActions from '@material-ui/core/DialogActions'
import clsx from 'clsx'

import { LoginForm } from '../auth'
import { resetStore } from '../middleware/api'
import RoundedButton from '../common/RoundedButton'
import Progress from '../common/Progress'
import { logAction } from '../utils/googleAnalytics'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 660,
    paddingBottom: 50,
  },
  header: {
    paddingTop: 60,
    textAlign: 'center',
  },
  headerColor: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    paddingLeft: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      textAlign: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  dialogContent: {
    padding: '0 100px',
    paddingBottom: 3,
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  focusSubject: {
    paddingBottom: 24,
  },
  focusSubject2: {
    paddingBottom: 24,
    paddingTop: 24,
  },
  termsConditions: {
    paddingBottom: 30,
    fontSize: 12,
    lineHeight: '15px',
    color: '#414141',
  },
  loginToAccess: {
    paddingBottom: 24,
  },
  hrSpaced: {
    margin: '58px 0 36px',
    border: '1px solid #efefef',
  },
  dialogContentW: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30,
  },
  dialogContentTextW: {
    display: 'flex',
    paddingRight: 22,
  },
  actions: {
    marginBottom: 0,
    marginRight: 20,
    display: 'flex',
  },
  leftIcon: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3.5rem',
        marginBottom: 10,
      },
    },
  },
  closeButtonW: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  bold: {
    fontWeight: 600,
  },
  mobileCenterAlign: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}))

const RfiAuthModal = ({ isOpen, setOpen, callback, templateName }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const [loginSuccess, setLoginSuccess] = useState()

  function handleClose() {
    setOpen(false)
  }

  useEffect(() => {
    if (loginSuccess) {
      onLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess])

  const onLogin = async (error) => {
    // close this auth modal and trigger parent callback which re-evaluates user for downloading file
    await setOpen(false)
    callback(error)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        <Typography className={classes.headerText}>TIP participation required</Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
          See participation Tiers
        <hr className={classes.hrSpaced} />

        <Typography variant="h6" className={classes.mobileCenterAlign}>
          For TIP participants
        </Typography>
        <Typography className={clsx(classes.loginToAccess, classes.mobileCenterAlign)}>
          Log in to access the materials.
        </Typography>
        <LoginForm
          compact={true}
          onLoginSuccess={async (response) => {
            await resetStore()
            setLoginSuccess(true)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

const RfiConfirmModal = ({ userEmail, isOpen, setOpen, callback, sendEmailLoading }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        Please confirm
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={2} xs={12} className={classes.leftIcon}>
            <MailOutlineIcon className={classes.leftIcon} />
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              The files will be emailed to your TIP account email address:
            </Typography>
            <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
              {userEmail}
            </Typography>
            <Typography className={clsx(classes.termsConditions, classes.mobileCenterAlign)}>
              By downloading, using and/or copying these RFI documents, you (the licensee) agree
              that you have read, understood, and will comply with the terms and conditions of&nbsp;
              <Link
                title="TIP IPR Policy"
                href="https://cdn.brandfolder.io/D8DI15S7/as/q7rnyo-fv487k-efbbqr/IPR_Policy_-_Telecom_Infra_Project.pdf"
                target="_blank"
              >
                TIP's IPR Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('confirm-cancel', 'get-the-rfi-template-files', '')
              handleClose()
            }}
            className={clsx(classes.button, classes.cancelButton)}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            color="primary"
            size="large"
            fullWidth={false}
            variant="contained"
            onClick={() => {
              callback()
            }}
            disabled={sendEmailLoading}
          >
            {sendEmailLoading ? <Progress size={30} delay={0} /> : 'Send Email'}
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const RfiDeclinedModal = ({ isOpen, setOpen, copy }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { titleCopy, headCopy, bodyCopy } = copy

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        {titleCopy || "You don't have access"}
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={1} xs={12} className={classes.leftIcon}>
            <NotInterestedIcon />
          </Grid>
          <Grid item sm={11} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              {headCopy ||
                'You are registered, but your TIP participation has not been approved yet.'}
            </Typography>
            <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
              {bodyCopy || 'Once your membership is approved you can return to this page.'}
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('you-do-not-have-access', 'get-the-rfi-template-files', '')
              handleClose()
            }}
            className={classes.button}
          >
            Close
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const RfiNotFullModal = ({ isOpen, setOpen }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        Restricted content
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={1} xs={12} className={classes.leftIcon}>
            <NotInterestedIcon />
          </Grid>
          <Grid item sm={11} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              Access to this content requires a TIP participation at the Full participation tier
            </Typography>
            <Typography className={clsx(classes.focusSubject2, classes.mobileCenterAlign)}>
              If you want to change your participation tier, please contact TIP Support
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('you-do-not-have-access', 'get-the-rfi-template-files', '')
              handleClose()
            }}
            className={classes.button}
          >
            Close
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default RfiAuthModal

export { RfiConfirmModal, RfiDeclinedModal, RfiNotFullModal }
