import React from 'react'
import { makeStyles } from '@material-ui/styles'
//import { themeStyles } from '../theme'
import Typography from '@material-ui/core/Typography'

import config from '../config'
import WidthContainer from './WidthContainer'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    background: `url(${
      config.MEDIA_CDN_DOMAIN
    }/media/Page-Header.svg) top center no-repeat #F1F8F9`,
    backgroundSize: '2700px',
    color: 'white',
    minHeight: 330,
    alignItems: 'flex-start',
    paddingTop: 60
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  pageTitle: {
    marginBottom: 24,
    fontSize: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      fontSize: 24,
      fontWeight: 600
    }
  },
  description: {
    maxWidth: '60%',
    lineHeight: '32px',
    marginTop: 32,
    fontSize: 20,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      fontSize: 16,
      maxWidth: '80%'
    }
  },
  longDesc: {
    maxWidth: '80%',
    lineHeight: '28px',
    marginTop: 32,
    fontSize: 20,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      fontSize: 16,
      maxWidth: '80%'
    }
  }
}))

const PageTitleHead = ({ title, description, longerDesc }) => {
  const classes = useStyles()
  // const themeClasses = themeStyles()

  return (
    <div className={classes.root}>
      <WidthContainer className={classes.columnWrapper}>
        <Typography variant="h2" className={classes.pageTitle}>
          {title}
        </Typography>
        <Typography variant="body" className={longerDesc ? classes.longDesc : classes.description}>
          {description}
        </Typography>
      </WidthContainer>
    </div>
  )
}

export default PageTitleHead
