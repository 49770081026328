import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useFormState } from 'react-use-form-state'
import { Typography, Grid, Link, IconButton, useMediaQuery } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

import InputTextField from '../../common/InputTextField'
import { LoginForm } from '../../auth'
import { resetStore } from '../../middleware/api'
import RoundedButton from '../../common/RoundedButton'
import Progress from '../../common/Progress'
import { logAction } from '../../utils/googleAnalytics'
import { inputRegexes, isFormSubmitDisabled } from '../../utils'
import { useFormStyles } from '../../hooks'
import CheckboxField from '../../common/CheckboxField'
//import { CheckboxField, InputTextField, Progress, RoundedButton } from '../../common'
import TIPPolicyCheckboxLabel from '../../common/TIPPolicyCheckboxLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 660,
    paddingBottom: 50,
  },
  header: {
    paddingTop: 60,
    textAlign: 'center',
  },
  headerColor: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    paddingLeft: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      textAlign: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  cancelButton: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  dialogContent: {
    padding: '0 100px',
    paddingBottom: 3,
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  focusSubject: {
    paddingBottom: 24,
  },
  focusSubject2: {
    paddingBottom: 24,
    paddingTop: 24,
  },
  termsConditions: {
    paddingBottom: 30,
    fontSize: 12,
    lineHeight: '15px',
    color: '#414141',
  },
  loginToAccess: {
    paddingBottom: 24,
  },
  hrSpaced: {
    margin: '58px 0 36px',
    border: '1px solid #efefef',
  },
  dialogContentW: {
    marginTop: 30,
    margin: 'auto',
    paddingRight: 30,
    paddingLeft: 30,
  },
  dialogContentTextW: {
    display: 'flex',
    paddingRight: 22,
  },
  actions: {
    marginBottom: 0,
    marginRight: 20,
    display: 'flex',
  },
  leftIcon: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3.5rem',
        marginBottom: 10,
      },
    },
  },
  closeButtonW: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  bold: {
    fontWeight: 600,
  },
  mobileCenterAlign: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}))

// STANDARAD MODALS

const AuthModal = ({ isOpen, setOpen, callback, templateName, copy }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [loginSuccess, setLoginSuccess] = useState()

  function handleClose() {
    setOpen(false)
  }

  useEffect(() => {
    if (loginSuccess) {
      onLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess])

  const onLogin = async (error) => {
    // close this auth modal and trigger parent callback which re-evaluates user for downloading file
    await setOpen(false)
    callback(error)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        <Typography className={classes.headerText}>TIP participation required</Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
          A TIP participation is required in order to access these materials. Use this link to join:
        </Typography>
        <hr className={classes.hrSpaced} />
        <Typography variant="h6" className={classes.mobileCenterAlign}>
          For TIP participants
        </Typography>
        <Typography className={clsx(classes.loginToAccess, classes.mobileCenterAlign)}>
          Log in to access the materials.
        </Typography>
        <LoginForm
          compact={true}
          onLoginSuccess={async (response) => {
            await resetStore()
            setLoginSuccess(true)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

const ConfirmEmailModal = ({ userEmail, isOpen, setOpen, callback, sendEmailLoading, copy }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { header, subHeader } = copy

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        Please confirm
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={2} xs={12} className={classes.leftIcon}>
            <MailOutlineIcon className={classes.leftIcon} />
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              {header || 'The files will be emailed to your TIP account email address:'}
            </Typography>
            <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
              {userEmail}
            </Typography>
            {subHeader ? (
              <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
                {subHeader}
              </Typography>
            ) : null}
            <Typography className={clsx(classes.termsConditions, classes.mobileCenterAlign)}>
              By downloading, using and/or copying these documents, you (the licensee) agree that
              you have read, understood, and will comply with the terms and conditions of&nbsp;
              <Link
                title="TIP IPR Policy"
                href="https://cdn.brandfolder.io/D8DI15S7/as/q7rnyo-fv487k-efbbqr/IPR_Policy_-_Telecom_Infra_Project.pdf"
                target="_blank"
              >
                TIP's IPR Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('confirm-cancel', 'get-the-template-files', '')
              handleClose()
            }}
            className={clsx(classes.button, classes.cancelButton)}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            color="primary"
            size="large"
            fullWidth={false}
            variant="contained"
            onClick={() => {
              callback()
            }}
            disabled={sendEmailLoading}
          >
            {sendEmailLoading ? <Progress size={30} delay={0} /> : 'Send Email'}
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const DeclinedModal = ({ isOpen, setOpen, copy }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { titleCopy, headCopy, bodyCopy } = copy

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        {titleCopy || "You don't have access"}
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={1} xs={12} className={classes.leftIcon}>
            <NotInterestedIcon />
          </Grid>
          <Grid item sm={11} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              {headCopy ||
                'You are registered, but your TIP participation has not been approved yet.'}
            </Typography>
            <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
              {bodyCopy || 'Once your membership is approved you can return to this page.'}
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('you-do-not-have-access', 'get-the-template-files', '')
              handleClose()
            }}
            className={classes.button}
          >
            Close
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const NotFullModal = ({ isOpen, setOpen }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  function handleClose() {
    setOpen(false)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerColor} id="responsive-dialog-title">
        Restricted content
        <IconButton aria-label="Close" className={classes.closeButtonW} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContentW}>
        <Grid container>
          <Grid item sm={1} xs={12} className={classes.leftIcon}>
            <NotInterestedIcon />
          </Grid>
          <Grid item sm={11} xs={12}>
            <Typography className={clsx(classes.bold, classes.mobileCenterAlign)}>
              Access to this content requires a TIP participation at the Full participation tier
            </Typography>
            <Typography className={clsx(classes.focusSubject2, classes.mobileCenterAlign)}>
              If you want to change your participation tier, please contact TIP Support
            </Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <RoundedButton
            fullWidth={false}
            size="large"
            variant="outlined"
            onClick={() => {
              logAction('you-do-not-have-membership-level-access', 'get-the-template-files', '')
              handleClose()
            }}
            className={classes.button}
          >
            Close
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

// ALTERNATIVE MODALS

const AuthEmailModal = ({ isOpen, setOpen, callback, templateName }) => {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  // const [formState, { email, text, checkbox }] = useFormState()
  const [formState, inputTypes] = useFormState({
    firstname: '',
    lastname: '',
    email: '',
    tipPolicyCheckbox: '',
  })

  function handleClose() {
    console.log('close')
    setOpen(false)
  }

  function handleSumbit(e) {
    e.preventDefault()
    console.log('todo: validate form')
    console.log({ disableSubmit })
    console.log(formState.validity)
    // validate form
    setOpen(false)
    callback()
  }

  const inputs = {
    firstName: {
      autoFocus: true,
      InputComponent: InputTextField,
      gridWidth: { xs: 12, sm: 6 },
      name: 'firstName',
      label: 'First Name',
      variant: 'outlined',
      inputType: 'text',
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
    },
    lastName: {
      gridWidth: { xs: 12, sm: 6 },
      InputComponent: InputTextField,
      name: 'lastName',
      label: 'Last Name',
      inputType: 'text',
      variant: 'outlined',
      validator: {
        required: true,
        minLength: 2,
        maxLength: 50,
      },
    },
    email: {
      gridWidth: { xs: 12 },
      InputComponent: InputTextField,
      name: 'email',
      label: 'Work Email',
      inputType: 'email',
      variant: 'outlined',
      helperText: 'Your company or organization email',
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email',
      },
    },
    tipPrivacyPolicy: {
      gridWidth: { xs: 12 },
      InputComponent: CheckboxField,
      inputType: 'checkbox',
      label: <TIPPolicyCheckboxLabel />,
      labelPlacement: 'end',
      name: 'tipPolicyCheckbox',
      validator: {
        required: true,
      },
    },
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.header} id="responsive-dialog-title">
        <Typography className={classes.headerText}>Fill out the form below</Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={clsx(classes.focusSubject, classes.mobileCenterAlign)}>
          We will send an email with links to the documents you've selected.
        </Typography>
        <form onSubmit={handleSumbit}>
          <Grid className={formClasses.spacingBottom} container spacing={2}>
            {Object.entries(inputs).map(([name, { InputComponent, inputType, ...args }]) => (
              <Grid key={name} item {...args.gridWidth}>
                <InputComponent
                  {...args}
                  key={name}
                  inputType={inputTypes[inputType]}
                  error={
                    inputType !== 'checkbox'
                      ? formState.errors[name]
                        ? true
                        : false
                      : formState.errors[name]
                      ? 'true'
                      : 'false'
                  }
                  helperText={
                    inputType !== 'checkbox' ? args.helperText || formState.errors[name] : null
                  }
                />
              </Grid>
            ))}
          </Grid>
          <RoundedButton color="primary" variant="contained" size="large" onClick={handleSumbit}>
            {/* TODO: add disabled prop to RoundedButton */}
            Send me this info
          </RoundedButton>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AuthModal

export { AuthEmailModal, ConfirmEmailModal, DeclinedModal, NotFullModal }
