import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { useSession } from '../../../auth/queries'
import Progress from '../../../common/Progress'
import DownloadAsset from '../../../auth/DownloadAssetModals'
import { themeStylesTip } from '../../../theme'
import { useMutation, useQuery } from '../../../hooks'
import { SEND_ASSET_EMAIL } from '../../../utils/emailAssetHelper'
import { GET_ASSET_PREVIEWS } from './queries'
import { logEmailAssetAction } from '../../../utils/googleAnalytics'

import thumb from '../images/ultraRural/Thumbnail-TIP-Total-Site-Solution-Rural-Site-RAN-Test-Plan@2x.png'

const useStyles = makeStyles(theme => ({
  docContainer: {
    border: 'solid 1px #E6E6E6',
    padding: 20,
    marginBottom: 20
  },
  docThumbContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    '& img': {
      maxWidth: '60%'
    }
  },
  selectBox: {
    backgroundColor: '#f6f6f6',
    padding: '4px 24px 4px 4px'
  }
}))

const SolutionDocuments = ({ sections }) => {
  const classes = useStyles()

  const themeClasses = themeStylesTip()
  const { enqueueSnackbar } = useSnackbar()
  const [checkboxDocs, setCheckboxDocs] = useState([])
  const [DownloadAssetAuthModalOpen, setDownloadAssetAuthModalOpen] = useState(false)
  const [documents, setDocuments] = useState([])

  const { session } = useSession()

  const { data: dataDocuments, loading: loadingDocuments, error: documentsError } = useQuery(
    GET_ASSET_PREVIEWS,
    { variables: { tags: 'TSS' } }
  )

  const {
    loading: sendAssetEmailLoading,
    execute: sendAssetEmail,
    error: errorSendingAssetEmail
  } = useMutation(SEND_ASSET_EMAIL)

  useEffect(() => {
    if (dataDocuments.assetPreviewsByTag) {
      setDocuments(dataDocuments.assetPreviewsByTag)
      setCheckboxDocs(Object.assign({}, dataDocuments.assetPreviewsByTag.map(doc => false)))
    }
  }, [dataDocuments])

  const resetForm = () => {
    setCheckboxDocs(Object.assign({}, documents.map(doc => false)))
  }

  const handleDocChange = event => {
    setCheckboxDocs({ ...checkboxDocs, [event.target.name]: event.target.checked })
  }

  const handleGetDocumentsClick = () => {
    setDownloadAssetAuthModalOpen(true)
  }

  const handleEmail = async () => {
    // make api call to send email
    const selectedDocs = Object.keys(checkboxDocs).filter(id => checkboxDocs[id] === true)
    const assetIds = selectedDocs.map(id => documents[parseInt(id)].id)
    const analyticsLabels = selectedDocs
      .map(id => documents[parseInt(id)].analyticsLabel)
      .toString()
    try {
      await sendAssetEmail({
        values: {
          assetIds: assetIds,
          assetsAsAttachments: false,
          subject: 'Here is the information you requested from Telecom Infra Project',
          template: 'tssAssetsEmail',
          azurePrivateBlob: true
          // TODO: will need to add guest vals if an asset without auth req is added. currently all assets are auth req'd, so no need for guest vals
          // guest: {
          //   firstName: '',
          //   lastName: '',
          //   email: ''
          // }
        }
      })
      // google analytics
      logEmailAssetAction({
        actionSuccess: true,
        label: analyticsLabels,
        currentUser: session.user
      })
      resetForm()
      enqueueSnackbar('Email Sent!', {
        variant: 'success',
        autoHideDuration: 2500
      })
    } catch (e) {
      // google analytics
      logEmailAssetAction({
        actionFail: true,
        label: analyticsLabels,
        currentUser: session.user
      })
      console.error('Error sending email.', e)
    }
  }

  const selectedDocsAuthRequired = Object.keys(checkboxDocs).some(
    doc => checkboxDocs[parseInt(doc)] && documents[parseInt(doc)].authRequired === true
  )

  // TODO: could show email processing in a better way?
  if (sendAssetEmailLoading) {
    console.log({ sendAssetEmailLoading })
  }

  if (documentsError) {
    console.error({ documentsError })
  }

  if (errorSendingAssetEmail) {
    console.error(errorSendingAssetEmail)
  }

  if (loadingDocuments) {
    return <Progress size={60} delay={0} />
  }

  return (
    <>
      <DownloadAsset
        isOpen={DownloadAssetAuthModalOpen}
        setOpen={setDownloadAssetAuthModalOpen}
        onSuccess={() => handleEmail()}
        authModal={!selectedDocsAuthRequired ? 'captureEmail' : ''}
        confirmModal="email"
        copy={{
          confirmEmailModal: {
            header: 'A link to the files will be emailed to your TIP account email address:',
            subHeader: 'The emailed links will expire in 36 hours'
          }
        }}
      />
      <Grid container className={classes.root} spacing={0}>
        <Box my={2}>{sections.documentsTitle}</Box>
        <Grid item xs={12} sm={9}>
          <Box pt={0.5} pb={2}>
            {sections.documentsDescription}
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box mb={2} textAlign={{ xs: 'left', sm: 'right', md: 'right', lg: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={
                !Object.keys(checkboxDocs).length >= 1 ||
                !Object.values(checkboxDocs).some(doc => doc === true)
              }
              onClick={() => handleGetDocumentsClick()}
            >
              <Typography variant="button">Get the documents</Typography>
            </Button>
          </Box>
        </Grid>
        {loadingDocuments === true ? (
          <Box>
            <Progress size={60} delay={0} />
          </Box>
        ) : (
          // display all the documents/assets
          documents.map((document, i) => {
            return (
              <Grid key={document.title} container className={classes.docContainer}>
                <Grid item xs={6} sm={2}>
                  <FormControlLabel
                    className={classes.selectBox}
                    control={
                      <Checkbox
                        checked={checkboxDocs[i]}
                        onChange={handleDocChange}
                        name={`${i}`}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body1" className={themeClasses.disclaimerConsent}>
                        Select this
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2} className={classes.docThumbContainer}>
                  <img src={document.thumb || thumb} alt={document.title} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6" paragraph>
                    {document.title}
                  </Typography>
                  <Typography variant="caption">{document.description}</Typography>
                </Grid>
              </Grid>
            )
          })
        )}
      </Grid>
    </>
  )
}

export default SolutionDocuments
