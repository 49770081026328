import React from 'react'
import CookieBanner from 'react-cookie-banner'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

function CustomCookieBanner({ onAccept }) {
  const classes = useStyles()

  return (
    <div id="react-cookie-banner" className={classes.cookieBanner}>
      <div className={classes.cookieMessage}>
        This site uses cookies and other tracking technologies so that we can provide you with the
        best user experience. You can{' '}
        <a
          className={classes.cookieLink}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.telecominfraproject.com/cookie-policy/"
        >
          read more
        </a>{' '}
        about the cookies that we use and their purposes and update your settings at any time.
      </div>
      <Button
        className={classes.cookieButton}
        variant="contained"
        color="primary"
        onClick={onAccept}
      >
        Accept Cookies
      </Button>
    </div>
  )
}

export default () => (
  <CookieBanner dismissOnScroll={false}>
    {onAccept => <CustomCookieBanner onAccept={onAccept} />}
  </CookieBanner>
)

const useStyles = makeStyles(theme => ({
  cookieBanner: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: '#fff',
    zIndex: '1201', // intentionally 1201 to be infront of leftnav + everything
    bottom: 0,
    background: 'rgba(102,102,102, .9)',
    height: 'auto',
    padding: '10px 5px',
    textAlign: 'center'
  },
  cookieMessage: {
    fontWeight: 400,
    lineHeight: 2,
    fontSize: 11,
    wordBreak: 'break-word'
  },
  cookieLink: {
    color: '#fff'
  },
  cookieButton: {
    fontSize: 11,
    background: 'rgba(102,102,102, .9)',
    padding: '3px 5px',
    marginLeft: 5,
    '&:hover': {
      background: 'black'
    }
  }
}))
