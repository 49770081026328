import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useQuery } from '../hooks'
import clsx from 'clsx'

import Progress from '../common/Progress'
import WidthContainer from '../common/WidthContainer'
import MediaGallery from '../common/MediaGallery'
import OfferingSummary from './OfferingSummary'
import OfferingDetailsTabs from './OfferingDetailsTabs'
import LinkField from '../common/LinkField'
// import OfferingRelatedTechnologies from './OfferingRelatedTechnologies'
import { useUser, useSession } from '../auth/queries'
import { GET_OFFERING } from './queries'
import { themeStyles } from '../theme'
import { getImageUrl } from '../utils'
import { getOfferingMedia } from '../utils/storageHelper'
import CompanyImg from '../assets/images/organization-logo-ph.png'
import PlayIcon from '../assets/images/tip-vid-play.svg'
import config from '../config'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundImage: `linear-gradient(#efefef, white 10%)`,
    display: 'flex',
    flexDirection: 'column'
  },
  offeringPanel: {
    backgroundImage: `url(${config.MEDIA_CDN_DOMAIN}/media/Inner-Product-Page.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '450px',
    backgroundPosition: 'left top',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  productBody: {},
  breadcrumb: {
    marginBottom: 16
  },
  productSummary: {
    padding: '30px 0 36px 0'
  },
  summaryContainer: {
    paddingLeft: 32
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '70px'
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '90px'
  }
}))

const OfferingPage = ({ match }) => {
  const offeringId = match.params.productId
  const classes = useStyles()
  const themeClasses = themeStyles()
  const { userId, isAuthed } = useSession()
  const { user: currentUser } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  const parameters = { variables: { id: offeringId, isAuthenticated: !!userId } }
  const { data, loading } = useQuery(GET_OFFERING, parameters)

  const [requestInfoModalOpen, setRequestInfoModalOpen] = useState(false)
  const [authModalOpen, setAuthModalOpen] = useState(false)
  const [mediaVimeo, setMediaVimeo] = useState([])

  useEffect(() => {
    if (data.offering) {
      const videoMedia = (data.offering.videoUrls || []).map(item => ({
        type: item // parse the videoURL for if it is Vimeo, YouTube or something else...
          ? item.search('vimeo') !== -1
            ? 'vimeo'
            : item.search('youtube') !== -1
            ? 'youtube'
            : 'other'
          : null,
        original: item,
        thumbnail: PlayIcon
      }))

      const getVideos = async () => {
        await Promise.all(
          videoMedia.map(async vm => {
            const vimId = vm.original.replace('https://player.vimeo.com/video/', '')
            try {
              const thumbUrl = await fetch(`https://vimeo.com/api/v2/video/${vimId}.json`)
              const TMDB = await thumbUrl.json()
              await setMediaVimeo(mediaVimeo => [
                ...mediaVimeo,
                {
                  type: 'vimeo',
                  original: vm.original,
                  thumbnail: TMDB[0].thumbnail_small
                }
              ])
            } catch (err) {
              console.log('error video image load:', err)
            }
          })
        )
      }
      getVideos()
    }
  }, [data])

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <Progress size={50} delay={0} />
      </div>
    )
  }

  if (!data || !data.offering) {
    return (
      <div className={classes.errorContainer}>
        <Typography variant="body1">Offering Not Found</Typography>
      </div>
    )
  }
  const defaultImage = data.offering.provider.logo
    ? getImageUrl(`hub/${data.offering.provider.logo}`)
    : CompanyImg

  const imageMedia = (data.offering.media || []).map(item => ({
    type: item.type,
    original: item.imageName ? getOfferingMedia(item.imageName) : defaultImage,
    thumbnail: item.thumbName ? getOfferingMedia(item.thumbName) : defaultImage
  }))

  // const videoMedia = (data.offering.videoUrls || []).map(item => ({
  //   type: item // parse the videoURL for if it is Vimeo, YouTube or something else...
  //     ? item.search('vimeo') !== -1
  //       ? 'vimeo'
  //       : item.search('youtube') !== -1
  //       ? 'youtube'
  //       : 'other'
  //     : null,
  //   original: item,
  //   thumbnail: PlayIcon //item.search('https://player.vimeo.com/video/') !== -1 ? item.replace('https://player.vimeo.com/video/','') : PlayIcon
  // }))

  const media = [...imageMedia, ...mediaVimeo]

  return (
    <div className={clsx(classes.root, themeClasses.pageContentMargin)}>
      <div className={classes.offeringPanel}>
        <Grid container className={classes.productBody}>
          <WidthContainer container className={classes.productSummary}>
            <Grid item xs={12}>
              <div className={classes.breadcrumb}>
                <LinkField to={`/marketplace/`} label={`Marketplace`} />
                &nbsp;&gt; {data.offering.name}
              </div>
            </Grid>
            <Grid item md={5} xs={12}>
              <MediaGallery media={media} />
            </Grid>
            <Grid item md={7} xs={12} className={classes.summaryContainer}>
              <OfferingSummary
                {...data.offering}
                currentUser={currentUser}
                requestInfoModalOpen={requestInfoModalOpen}
                authModalOpen={authModalOpen}
                setRequestInfoModalOpen={setRequestInfoModalOpen}
                setAuthModalOpen={setAuthModalOpen}
              />
            </Grid>
          </WidthContainer>
          <Grid container>
            <OfferingDetailsTabs {...data.offering} isAuthed={isAuthed} />
          </Grid>
        </Grid>
      </div>
      {/* {!!relatedOfferings.length && (
        <WidthContainer>
          <OfferingRelatedTechnologies list={relatedOfferings} />
        </WidthContainer>
      )} */}
    </div>
  )
}

export default OfferingPage
