import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Avatar, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { getImageUrl } from '../utils/imageHelper'
import CompanyImg from '../assets/images/organization-logo-ph.png'

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none'
  },
  orgCard: {
    width: 250,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.borderGray.main}`,
    '&:hover a': {
      textDecoration: 'none'
    },
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      width: 280
    },
    [theme.breakpoints.down('xs')]: {
      width: 280,
      marginRight: 20,
      marginLeft: 20
    }
  },
  orgLogoContainer: {
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.borderGray.main}`
  },
  orgIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  orgLogo: {
    width: 250
  },
  orgCardContent: {
    padding: '12px 12px'
  },
  orgName: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 6
  },
  orgDescription: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.darkGray.main
  },
  avatar: {
    borderRadius: 0,
    width: '100%',
    height: 250
  },
  avatarImg: {
    height: 'auto',
    width: '100%'
  }
}))

const OrganizationsCard = ({ organization }) => {
  const classes = useStyles()

  const { logo, name, description } = organization

  const shortDescription = description
    ? description.length > 50
      ? `${description.substring(0, 47)} ...`
      : description
    : ''

  return (
    <Link to={`/solution-providers/${organization.id}`} className={classes.root}>
      <div className={classes.orgCard}>
        {logo ? (
          <div className={classes.orgLogoContainer}>
            <Avatar
              alt="Solution Provider Logo"
              src={getImageUrl(`hub/${logo}`)}
              className={classes.avatar}
              imgProps={{ className: classes.avatarImg }}
            />
          </div>
        ) : (
          <div className={clsx(classes.orgLogoContainer, classes.orgIconContainer)}>
            <Avatar alt="Solution Provider Logo" src={CompanyImg} className={classes.avatar} />
          </div>
        )}

        <div className={classes.orgCardContent}>
          <Typography variant="h3" className={classes.orgName}>
            {name}
          </Typography>
          <Typography className={classes.orgDescription}>{shortDescription}</Typography>
        </div>
      </div>
    </Link>
  )
}

export default OrganizationsCard
