export default {
  API_ROOT: process.env.REACT_APP_API_ROOT,
  VERSION: process.env.REACT_APP_VERSION,
  CLIENT_ENV: process.env.REACT_APP_CLIENT_ENV || 'production',
  MEDIA_CDN_DOMAIN: process.env.REACT_APP_MEDIA_CDN_DOMAIN,
  TIP_CONTENT_CONTAINER: process.env.REACT_APP_TIP_CONTENT_CONTAINER,
  WORKPLACE_URL: process.env.REACT_APP_WORKPLACE_URL,
  WORKPLACE_SSO_URL: process.env.REACT_APP_WORKPLACE_SSO_URL,
  WIKI_URL: process.env.REACT_APP_WIKI_URL,
  HUB_URL: process.env.REACT_APP_HUB_URL,
  LOCK_DATE: process.env.REACT_APP_LOCK_DATE,
  RECAPTCHA_PUBKEY: process.env.REACT_APP_RECAPTCHA_PUBKEY,
  ATLASSIAN_URL: process.env.REACT_APP_ATLASSIAN_URL
}
