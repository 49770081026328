// for material ui table searching
function getFieldValue(rowData, columnDef, lookup = true) {
  let value = rowData[columnDef.field]
  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }

  return value
}

export const searchTable = (tableData, searchText, columnDef) => {
  if (!searchText) {
    return tableData
  }

  const columnsToSearch = columnDef.filter(c =>
    c.searchable === undefined ? !c.hidden : c.searchable
  )
  return tableData.filter(row =>
    columnsToSearch.some(column => {
      if (column.customFilterAndSearch) {
        return !!column.customFilterAndSearch(searchText, row, column)
      } else if (column.field) {
        const value = getFieldValue(row, column)
        if (value) {
          return value
            .toString()
            .toUpperCase()
            .includes(searchText.toUpperCase())
        }
      }
      return false
    })
  )
}
