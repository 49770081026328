import React, { useState, createContext } from 'react'

export const Context = createContext()
export const Consumer = Context.Consumer
export const Provider = ({ children }) => {
  const [criteria, setCriteria] = useState({
    filters: {},
    sort: '',
    search: ''
  })

  return (
    <Context.Provider
      value={{
        criteria,
        setCriteria: values => {
          const nextValues = { ...criteria }
          const filters = values.filters

          if (filters) {
            Object.keys(filters).forEach(filterKey => {
              if (!filters[filterKey].label) {
                nextValues.filters[filterKey].value = filters[filterKey].value
              } else {
                nextValues.filters[filterKey] = filters[filterKey]
              }
            })
          }

          if (typeof values.sort !== 'undefined') {
            nextValues.sort = values.sort
          }

          if (typeof values.search !== 'undefined') {
            nextValues.search = values.search
          }

          setCriteria(nextValues)
        }
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
