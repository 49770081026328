import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { useQuery } from '../hooks'
import { GET_PUBLIC_ORGANIZATIONS, GET_PUBLIC_ORGANIZATIONS_PREVIEW } from './queries'
import OrganizationCard from './OrganizationCard'
import WidthContainer from '../common/WidthContainer'
import Progress from '../common/Progress'
import BecomeAMemberButton from '../common/BecomeAMemberButton'
import PageTitleHead from '../common/PageTitleHead'
import { themeStylesTip, tipCoreTheme } from '../theme'
import { useSession } from '../auth/queries'
import { usePageContent, getSection } from '../utils/pageContentHelper'

const useStyles = makeStyles(theme => ({
  pageHeader: {
    marginBottom: 60
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    paddingTop: 32
  },
  pageTitle: {
    marginBottom: 26,
    fontSize: 58,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  pageSubTitle: {
    alignSelf: 'flex-start',
    marginBottom: 32
  },
  createProfileLabel: {
    fontSize: 13,
    fontWeight: 500
  },
  orgCardsContainer: {
    marginBottom: 60
  },
  loading: {
    textAlign: 'center'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  hero: {
    marginBottom: 50
  }
}))

const OrganizationsPage = ({ match }) => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()
  const { data: pageContent = [], error: pageContentError } = usePageContent('Organizations Page')
  const sections = {
    pageTitle: getSection(pageContent, 'Page Title'),
    pageDescription: getSection(pageContent, 'Page Description')
  }
  const { isSystemAdmin } = useSession()
  const { data, loading } = useQuery(
    isSystemAdmin ? GET_PUBLIC_ORGANIZATIONS_PREVIEW : GET_PUBLIC_ORGANIZATIONS
  )
  const organizations = (data && data.vendingOrganizations) || []
  const sortedOrganizations = organizations.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  })

  if (pageContentError) {
    console.error('Error loading page content Org Page:', pageContentError)
  }

  return (
    <MuiThemeProvider theme={tipCoreTheme}>
      <div className={themeClasses.pageContentMargin}>
        <PageTitleHead
          title={sections.pageTitle}
          description={sections.pageDescription}
          longerDesc
        />

        <WidthContainer className={classes.columnWrapper}>
          <BecomeAMemberButton color="primary" />
          <br />
          <br />
          <Grid container className={classes.orgCardsContainer} spacing={2}>
            {loading ? (
              <Grid item xs={12} className={classes.loading}>
                <Progress size={60} />
              </Grid>
            ) : (
              sortedOrganizations.map(org => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={org.id} className={classes.center}>
                  <OrganizationCard organization={org} />
                </Grid>
              ))
            )}
          </Grid>
        </WidthContainer>
      </div>
    </MuiThemeProvider>
  )
}

export default OrganizationsPage
