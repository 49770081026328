import React, { useEffect, useCallback } from 'react'
import gql from 'graphql-tag'

import Progress from '../common/Progress'
import { useMutation } from '../hooks'
import { clearStore } from '../middleware/api'

export const LOGOUT_USER = gql`
  mutation {
    logout {
      data
      result
    }
  }
`
export default function Logout() {
  const { execute } = useMutation(LOGOUT_USER)
  const callApiLogout = useCallback(execute, [])

  useEffect(() => {
    callApiLogout().then(() => {
      clearStore().then(() => {
        window.location = '/login'
      })
    })
  }, [callApiLogout])

  return <Progress size={30} />
}
