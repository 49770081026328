import React from 'react'
import Typography from '@material-ui/core/Typography'
import gql from 'graphql-tag'
import { useFormState } from 'react-use-form-state'
import Grid from '@material-ui/core/Grid'
import { Redirect } from 'react-router-dom'

import InputTextField from '../common/InputTextField'
import RoundedButton from '../common/RoundedButton'
import Progress from '../common/Progress'
import { useMutation, useFormStyles } from '../hooks'
import { isFormSubmitDisabled, inputRegexes } from '../utils'
import { themeStyles } from '../theme'

export const RESET_PASSWORD = gql`
  mutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      result
      data
    }
  }
`

export default function ResetPassword(props) {
  const [formState, { email, password }] = useFormState()
  const { loading, execute: handleResetPassword, data, error } = useMutation(RESET_PASSWORD)
  const classes = useFormStyles()
  const themeClasses = themeStyles()

  function handleSubmit(e) {
    e.preventDefault()
    handleResetPassword({ values: formState.values })
  }

  if (!formState.values.token) {
    const params = new URLSearchParams(props.location.search)
    const token = params.get('token')
    if (!token) {
      return <Redirect to="/" /> // redirect to homepage if they should not be here
    } else {
      formState.setField('token', token)
    }
  }

  const inputs = {
    email: {
      gridWidth: { xs: 12 },
      name: 'email',
      label: 'Email',
      inputType: email,
      autoFocus: true,
      validator: {
        required: true,
        regex: inputRegexes.email,
        regexMessage: 'Must be a valid email'
      },
      variant: 'outlined'
    },
    password: {
      gridWidth: { xs: 12 },
      name: 'password',
      label: 'New Password',
      inputType: password,
      validator: {
        required: true,
        regex: inputRegexes.password,
        regexMessage: 'Must be 8 characters long and have at least one letter, number, and symbol.'
      },
      touchOnChange: true,
      variant: 'outlined'
    },
    confirmPassword: {
      gridWidth: { xs: 12 },
      name: 'confirmPassword',
      label: 'Confirm Password',
      inputType: password,
      autoComplete: 'new-password',
      validator: {
        required: true,
        mustMatchKey: 'password',
        mustMatchMessage: 'Passwords must match'
      },
      helperText: 'Re-type your password.',
      variant: 'outlined'
    }
  }

  let errorMessage
  if (error) {
    errorMessage = error.graphQLErrors[0].message
  }

  if (data && data.resetPassword && data.resetPassword.result) {
    return <Redirect to="/login" /> // redirect to login after succesful reset
  }

  const disableSubmit = isFormSubmitDisabled(inputs, formState)

  return (
    <form
      data-testid="ResetPasswordContainer"
      onSubmit={handleSubmit}
      className={themeClasses.pageContentMargin}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={7} lg={6} className={classes.container}>
          <div className={classes.header}>
            <Typography role="heading" variant="h3" className={classes.heading}>
              Reset Password
            </Typography>
          </div>
          <div className={classes.alert}>
            {data && data.resetPassword.result && (
              <Typography role="alert" classes={classes.success}>
                {data.resetPassword.data}
              </Typography>
            )}
            {error && (
              <Typography role="alert" color="error">
                {errorMessage}
              </Typography>
            )}
          </div>
          <Grid container spacing={2}>
            {Object.entries(inputs).map(([name, args]) => (
              <Grid key={name} item {...args.gridWidth}>
                <InputTextField
                  key={name}
                  {...args}
                  error={formState.errors[name] ? true : false}
                  helperText={formState.errors[name]}
                />
              </Grid>
            ))}
          </Grid>
          <div className={classes.buttonRightContainer}>
            {loading ? (
              <Progress size={30} delay={0} />
            ) : (
              <RoundedButton
                onClick={handleSubmit}
                disabled={disableSubmit}
                data-testid="SubmitBtn"
                fullWidth={false}
              >
                Change Password
              </RoundedButton>
            )}
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
