import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'

import { themeStyles } from '../theme'
import { UltraRural } from './Solutions/'
import Progress from '../common/Progress'

const solutions = {
  tss: <UltraRural />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
}))

const TSSPage = ({ match }) => {
  const classes = useStyles()
  const themeClasses = themeStyles()
  const [solution, setSolution] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const id = match.params && match.params.id ? match.params.id : null
    setSolution(solutions[id])
    setLoading(false)
  }, [match])

  return (
    <Grid container className={clsx(classes.root, themeClasses.pageContentMargin)}>
      {solution ? (
        <solution.type {...solution.props} />
      ) : loading ? (
        <Progress />
      ) : (
        'Solution not found'
      )}
    </Grid>
  )
}

export default TSSPage
