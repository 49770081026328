import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    '& h2': {
      fontSize: 28,
      marginTop: 12,
      textAlign: 'center'
    }
  },
  content: {
    padding: 16,
    paddingTop: 0
  },
  requestSentContent: {
    textAlign: 'center',
    marginBottom: 32
  },
  subTitle: {
    marginBottom: 30,
    fontWeight: 500
  },
  paragraph: {
    marginBottom: 44
  },
  button: {
    marginBottom: 30
  },
  imageContainer: {
    display: 'flex'
  },
  image: {
    width: 'auto',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    }
  },
  video: {
    width: '100%'
  },
  header: {
    height: 304
  },
  closeButton: {
    position: 'absolute',
    right: -5,
    top: -5,
    color: theme.palette.grey[500]
  }
}))

function DialogTitle({ children, onClose }) {
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}

function MediaViewerModal({ open, handleClose, ...media }) {
  const classes = useStyles()

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle onClose={() => handleClose(false)}>{media.title || ''}</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.imageContainer}>
          {media.type === 'image' ? (
            <img
              className={classes.image}
              src={media.original}
              alt={media.description || 'Image'}
            />
          ) : (
            <video controls className={classes.video}>
              <source src={media.original} type="video/mp4" />
            </video>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default MediaViewerModal
