import config from '../config'

export const getImageUrl = logo => {
  return `${config.MEDIA_CDN_DOMAIN}/${logo}`
}

// Adapted from https://www.w3schools.com/howto/howto_js_image_zoom.asp
export const imageZoom = (img, viewer) => {
  let lens, cx, cy
  /* Create lens: */
  lens = document.createElement('div')
  lens.setAttribute('class', 'img-zoom-lens')
  /* Insert lens: */
  img.parentElement.insertBefore(lens, img)
  /* Calculate the ratio between viewer DIV and lens: */
  cx = viewer.offsetWidth / lens.offsetWidth
  cy = viewer.offsetHeight / lens.offsetHeight
  /* Set background properties for the viewer DIV */
  viewer.style.backgroundImage = 'url("' + img.src + '")'
  viewer.style.backgroundSize = img.width * cx + 'px ' + img.height * cy + 'px'
  /* Execute a function when someone moves the cursor over the image, or the lens: */
  lens.addEventListener('mousemove', moveLens)
  img.addEventListener('mousemove', moveLens)
  /* And also for touch screens: */
  lens.addEventListener('touchmove', moveLens)
  img.addEventListener('touchmove', moveLens)
  function moveLens(e) {
    let pos, x, y
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault()
    /* Get the cursor's x and y positions: */
    pos = getCursorPos(e)
    /* Calculate the position of the lens: */
    x = pos.x - lens.offsetWidth / 2
    y = pos.y - lens.offsetHeight / 2
    /* Prevent the lens from being positioned outside the image: */
    if (x > img.width - lens.offsetWidth) {
      x = img.width - lens.offsetWidth
    }
    if (x < 0) {
      x = 0
    }
    if (y > img.height - lens.offsetHeight) {
      y = img.height - lens.offsetHeight
    }
    if (y < 0) {
      y = 0
    }
    /* Set the position of the lens: */
    lens.style.left = x + 'px'
    lens.style.top = y + 'px'
    /* Display what the lens "sees": */
    viewer.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px'
  }
  function getCursorPos(e) {
    let a,
      x = 0,
      y = 0
    e = e || window.event
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect()
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left
    y = e.pageY - a.top
    /* Consider any page scrolling: */
    x = x - window.pageXOffset
    y = y - window.pageYOffset
    return { x: x, y: y }
  }

  return {
    destroy: () => {
      lens.remove()
      lens.removeEventListener('mousemove', moveLens)
      img.removeEventListener('mousemove', moveLens)
      lens.removeEventListener('touchmove', moveLens)
      img.removeEventListener('touchmove', moveLens)
    }
  }
}
