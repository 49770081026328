import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Route, Switch, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { loadReCaptcha } from 'react-recaptcha-v3'

import { NavSpacer } from './Navigation'
import Header from './Navigation/Header'
import Home from './Home'
import MaintenancePage from './Home/MaintenancePage'
import OfferingPage from './Offering'
import OrganizationsPage from './Organization'
import BlueprintsPage from './Blueprints'
import BlueprintsChildMock from './BlueprintsChildMock'
import BlueprintsChildMock2 from './BlueprintsChildMock2'
import OrganizationProfilePage from './Organization/OrganizationProfilePage'
import MarketplacePage from './Marketplace'
import BadgesPage from './About/Badges'
import RfiPage from './Rfi'
import TSSPage from './TSS'
import ThankYouPage from './Rfi/ThankYouPage'
import StorybookMock from './StorybookMock'
import Footer from './Footer'
import { useScrollRestoration, useGoogleAnalytics } from './utils'
import CookieBanner from './CookieBanner'
import { SignUp, CreateAccount } from './SignUp'
import { AnonymousRoute, LoginPage, Logout, ForgotPassword, ResetPassword } from './auth'
import { useUser, useSession } from './auth/queries'
import { Provider as MarketplaceCriteriaProvider } from './contexts/MarketplaceCriteriaContext'
import AuthorizeEnv, { AccessDenied } from './DevPasswordAuth/AuthorizeEnv'
import Cookies from 'universal-cookie'
import config from './config'
import { useQuery } from './hooks'
import './captcha.css'

export const GET_MAINTENANCE_STATUS = gql`
  query {
    maintenanceStatus {
      maintenanceModeOn
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column'
  },
  accessDenied: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 300,
    textAlign: 'center'
  }
}))

const App = () => {
  loadReCaptcha(config.RECAPTCHA_PUBKEY)
  useScrollRestoration()

  // Pass current user to analytics
  const { userId } = useSession()
  const { user: currentUser } = useUser({
    variables: { id: userId },
    skip: !userId
  })

  useGoogleAnalytics(currentUser)

  const cookies = new Cookies()

  const classes = useStyles()

  const {
    data: { maintenanceStatus = {} }
  } = useQuery(GET_MAINTENANCE_STATUS)

  const [authorized, setAuthorized] = useState(
    ['dev', 'demo', 'production'].includes(config.CLIENT_ENV) || cookies.get('exchangeAuthorized')
  )

  // Password protection lock date
  const lockDate = config.LOCK_DATE
  const todaysDate = new Date().toISOString()
  let locked = true

  // Check if Lock Date is passed
  if (Date.parse(lockDate) < Date.parse(todaysDate)) {
    locked = false
  }

  // Password protection authorization
  if (typeof authorized === 'undefined' && locked) {
    return <AuthorizeEnv setAuthorized={setAuthorized} />
  }

  if (!authorized) {
    return <AccessDenied />
  }

  if (maintenanceStatus.maintenanceModeOn) {
    return <MaintenancePage />
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <main className={classes.content}>
          <NavSpacer />
          <MarketplaceCriteriaProvider>
            <Switch>
              <AnonymousRoute path="/login" component={LoginPage} />
              <AnonymousRoute path="/forgot-password" component={ForgotPassword} />
              <AnonymousRoute path="/get-started" component={SignUp} />
              <Route path="/create-account" component={CreateAccount} />
              <Route path="/logout" component={Logout} />
              <Route path="/about-exchange/badges" component={BadgesPage} />
              <Route path="/blueprints" component={BlueprintsPage} />
              <Route path="/blueprintchild" component={BlueprintsChildMock} />
              <Route path="/blueprintchild2" component={BlueprintsChildMock2} />
              <Route path="/password-reset" component={ResetPassword} />
              <Route path="/marketplace/" exact component={MarketplacePage} />
              <Route path="/marketplace/:orgslug/:productId" exact component={OfferingPage} />
              <Route path="/solution-providers" exact component={OrganizationsPage} />
              <Route path="/rfi/:templateId" exact component={RfiPage} />
              <Route path="/rfi/" exact component={RfiPage} />
              <Route path="/thankyou/" exact component={ThankYouPage} />
              <Route exact path="/solutions/tss/ultra-rural">
                <Redirect to="/solutions/tss" />
              </Route>
              <Route path="/solutions/:id" component={TSSPage} />
              <Route path="/storybook/" exact component={StorybookMock} />
              <Route
                path="/solution-providers/:organizationId"
                exact
                component={OrganizationProfilePage}
              />
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
          </MarketplaceCriteriaProvider>
        </main>
        <Footer />
      </div>
      <CookieBanner />
    </>
  )
}

export default App
