import gql from 'graphql-tag'

export const LOGIN_USER = gql`
  mutation($input: UserLoginInput!) {
    login(input: $input) {
      data
      result
    }
  }
`

export const REFRESH_SESSION = gql`
  mutation {
    refreshSession {
      data
      result
    }
  }
`

export const EXCHANGE_FAKE_STAY_LOGGED_IN = gql`
  mutation {
    exchangeFakeStayLoggedIn {
      result
    }
  }
`
