import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import clsx from 'clsx'

import WidthContainer from '../../../common/WidthContainer'
import { themeStylesTip } from '../../../theme'

const useStyles = makeStyles(theme => {
  return {
    scatWrapper: {
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 800,
        overflowX: 'scroll'
      }
    },
    oemArea: {
      marginTop: -60,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '200%'
      }
    },
    oemWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    oemCategories: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        flexWrap: 'nowrap'
      }
    },
    subtitle: {
      display: 'block',
      maxWidth: 700
    },
    ltBluePart: {
      backgroundColor: '#DDEEEF'
    },
    spacedText: {
      lineHeight: '33px'
    },
    colorBar: {
      backgroundColor: '#3BA9B6',
      color: 'white',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '150%'
      }
    },
    grid5col: {
      maxWidth: '20%',
      flexBasis: '20%',
      margin: '0 auto',
      textAlign: 'center',
      border: '1px #CDE5E8 solid',
      //   [theme.breakpoints.down('md')]: {
      //     maxWidth: 250,
      //     width: 250,
      //     flexBasis: 250,
      //     flex: 'none'
      //   },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
        width: 200,
        flexBasis: 200,
        flex: 'none'
      }
    },
    grid5col2nd: {
      backgroundColor: '#F1F8F9',
      opacity: 0.7
    },
    oemIconBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: 200
    },
    oemCategoryIcon: {
      maxWidth: '50%',
      height: 'auto'
    },
    partnerBox: {
      border: '1px solid #74C7CE',
      borderRadius: 4
    },
    pName: {
      color: '#29818C',
      textDecoration: 'underline',
      textDecorationColor: '#D2D2D2'
    },
    prName: {
      color: '#29818C',
      textDecoration: 'underline',
      textDecorationColor: '#D2D2D2',
      fontSize: 12
    },
    productArea: {
      background: '#FAFCF5'
    }
  }
})

const OemPartners = ({ data }) => {
  const classes = useStyles()
  const themeClasses = themeStylesTip()
  const categories = data && data.categories ? data.categories : []

  return (
    <div className={classes.scatWrapper}>
      <Grid container className={classes.oemArea}>
        <WidthContainer className={classes.columnWrapper}>
          <Box className={classes.colorBar} px={3} py={2}>
            <Typography variant="subtitle2">
              OEM partners that have collaborated on the Ultra Rural Use Case
            </Typography>
          </Box>
          <div className={classes.oemWrapper}>
            <Grid container className={classes.oemCategories}>
              {categories.map((cat, idx) => (
                <Grid
                  key={`oem=${idx}`}
                  item
                  xs={2}
                  className={
                    cat.isSecondary ? clsx(classes.grid5col, classes.grid5col2nd) : classes.grid5col
                  }
                >
                  <Box p={1}>
                    <Box className={classes.oemIconBox} pb={2}>
                      <img className={classes.oemCategoryIcon} src={`${cat.img}`} alt="logo" />
                    </Box>
                    <Typography variant="h6" paragraph>
                      {cat.label}
                    </Typography>
                    {cat.partners.map((partner, pdx) => (
                      <Box key={`pbox-${pdx}`} className={classes.partnerBox} mb={1}>
                        {partner.logo ? (
                          <img
                            className={themeClasses.standardImg}
                            src={`${partner.logo}`}
                            alt="partner logo"
                          />
                        ) : null}
                        <a href={partner.partnerLink} target="_blank" rel="noopener noreferrer">
                          <Box my={2}>
                            <Typography className={classes.pName} variant="h6" paragraph>
                              {partner.partnerName}
                            </Typography>
                          </Box>
                        </a>
                        <Box className={partner.products.length ? classes.productArea : null} p={1}>
                          {partner.products.map((item, zdx) => (
                            <Box key={`pprod-${zdx}`} my={2}>
                              <a href={item.productLink} target="_blank" rel="noopener noreferrer">
                                <Typography className={classes.prName} variant="caption">
                                  {item.productFeature}
                                </Typography>
                              </a>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </WidthContainer>
      </Grid>
    </div>
  )
}

export default OemPartners
